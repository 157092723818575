/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import { useSelector } from "react-redux"

import { ROUTES_CONFIG } from "../../config/routes.config"
import { embedCalculatorSelector } from "../../selectors/embedCalculator.selector"
import { GENERAL_CONFIG } from "../../config/general.config"

export const NirvanaBackgroundComponent: React.FunctionComponent = () => {
    const location = useLocation()
    const [searchParams] = useSearchParams()

    const embedCalculatorState = useSelector(embedCalculatorSelector)

    // Remove extra space, so it looks better with embeds where whitelabel is needed
    const [isWhiteLabeled] = useState<boolean>(
        !!searchParams.get(GENERAL_CONFIG.urlSearchParamsKeys.whiteLabeled),
    )

    const backgroundStyles: string = useMemo((): string => {
        const locationPathname = location.pathname
            .replace(embedCalculatorState.embedKey || ":embedKey", ":embedKey")
            .replace(
                embedCalculatorState.managementKey || ":managementKey",
                ":managementKey",
            )

        switch (locationPathname) {
            case ROUTES_CONFIG.sessionRatesUrl:
                return `${isWhiteLabeled ? "h-250px" : "h-320px"} md:h-0px`

            case ROUTES_CONFIG.embedCalculatorManagementLoginUrl:
            case ROUTES_CONFIG.embedCalculatorManagementUrl:
                return "h-screen"

            default:
                return `${isWhiteLabeled ? "h-170px" : "h-240px"} md:h-0px`
        }
    }, [
        location.pathname,
        embedCalculatorState.embedKey,
        embedCalculatorState.managementKey,
    ])

    return (
        <div
            className={`
                fixed left-0 top-0 -z-20
                bg-brand-offWhite w-screen
                ${backgroundStyles}
            `}
        />
    )
}
