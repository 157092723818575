/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import {
    AvailableLookupConsumer,
    useCptCodes,
    useScrollFix,
} from "nirvana-react-elements"

import { CalculatorDataCollectionComponent } from "./calculatorDataCollection.component"
import { embedCalculatorActionCreators } from "../../actions/embedCalculator.actions"

export const CalculatorComponent: React.FunctionComponent = () => {
    useScrollFix()

    const dispatch = useDispatch()

    const { availableCptCodesStyled } = useCptCodes(
        AvailableLookupConsumer.calculator,
    )

    // Reset embed key just in case in state if we've ended up here...
    useEffect(() => {
        dispatch(embedCalculatorActionCreators.setEmbedKey(undefined))
    }, [])

    return availableCptCodesStyled.length ? (
        <CalculatorDataCollectionComponent cptCodes={availableCptCodesStyled} />
    ) : null
}
