export enum RuntimeActions {
    APP_LOADING_START = "APP_LOADING_START",
    APP_LOADING_STOP = "APP_LOADING_STOP",
    APP_SET_IS_EMBED = "APP_SET_IS_EMBED",
    APP_SET_IS_PDF_GENERATING = "APP_SET_IS_PDF_GENERATING",
}

export const runtimeActionCreators = {
    startLoading: (payload: string): ISagaAction => {
        return {
            type: RuntimeActions.APP_LOADING_START,
            payload,
        }
    },

    stopLoading: (payload: string): ISagaAction => {
        return {
            type: RuntimeActions.APP_LOADING_STOP,
            payload,
        }
    },

    setIsEmbed: (payload: boolean): ISagaAction => {
        return {
            type: RuntimeActions.APP_SET_IS_EMBED,
            payload,
        }
    },

    setIsPDFGenerating: (payload: boolean): ISagaAction => {
        return {
            type: RuntimeActions.APP_SET_IS_PDF_GENERATING,
            payload,
        }
    },
}
