import { IFileToUpload } from "nirvana-react-elements"

import { AnalyticsHelper } from "../helpers/analytics.helper"
import { AnalyticsEvent } from "../config/analytics.config"
import { CalculatorResultType } from "../config/general.config"

export enum CalculatorActions {
    CALCULATOR_PROCESSED_SET = "CALCULATOR_PROCESSED_SET",
    CALCULATION_INPUT_DATA_SET = "CALCULATION_INPUT_DATA_SET",
    CALCULATION_DATA_SET = "CALCULATION_DATA_SET",
    CALCULATION_DATA_RESET = "CALCULATION_DATA_RESET",
    CALCULATOR_MANUAL_ELIGIBILITY_DATA_SET = "CALCULATOR_MANUAL_ELIGIBILITY_DATA_SET",
    CALCULATOR_INVITE_THERAPIST = "CALCULATOR_INVITE_THERAPIST",
    CALCULATOR_EMAIL_RESULTS = "CALCULATOR_EMAIL_RESULTS",
}

export const calculatorActionCreators = {
    setInputData: (
        inputData: ICalculatorInputData,
        onSuccess: (resultType: CalculatorResultType) => void,
        embedKey?: string,
    ): ISagaAction => {
        // Record event to analytics that user submitted data
        AnalyticsHelper.event(AnalyticsEvent.calculatorFormSubmitted)

        return {
            type: CalculatorActions.CALCULATION_INPUT_DATA_SET,
            payload: {
                inputData,
                embedKey,
            },
            onSuccess,
        }
    },

    setProcessed: (isProcessed: boolean): ISagaAction => {
        return {
            type: CalculatorActions.CALCULATOR_PROCESSED_SET,
            payload: isProcessed,
        }
    },

    setCalculatorData: (data: ICalculationsResult): ISagaAction => {
        return {
            type: CalculatorActions.CALCULATION_DATA_SET,
            payload: data,
        }
    },

    resetCalculatorData: (): ISagaAction => {
        return {
            type: CalculatorActions.CALCULATION_DATA_RESET,
        }
    },

    setManualEligibilityData: (data: IManualEligibilityData): ISagaAction => {
        return {
            type: CalculatorActions.CALCULATOR_MANUAL_ELIGIBILITY_DATA_SET,
            payload: data,
        }
    },

    inviteTherapist: (email: string, onSuccess: () => void): ISagaAction => {
        return {
            type: CalculatorActions.CALCULATOR_INVITE_THERAPIST,
            payload: {
                email,
            },
            onSuccess,
        }
    },

    emailResults: (
        email: string,
        resultingPdf: IFileToUpload,
        onSuccess?: () => void,
        onError?: () => void,
    ): ISagaAction => {
        return {
            type: CalculatorActions.CALCULATOR_EMAIL_RESULTS,
            payload: {
                email,
                resultingPdf,
            },
            onSuccess,
            onError,
        }
    },
}
