import React from "react"
import {
    IStepperStepProps,
    ISelectRenderedOption,
    PrimaryText,
} from "nirvana-react-elements"

import { INTAKE_DEMO_CONFIG } from "../../../config/intakeDemo.config"

export const ReasonStep: React.FunctionComponent<IStepperStepProps> = props => {
    const onReasonSelected = (item: ISelectRenderedOption) => {
        props.onStepSubmit({
            reason: item.value,
        } as IGetCoverageReasonStepData)
    }

    return (
        <div className="relative">
            <PrimaryText typography="h3">
                What are you looking <br /> for today?
            </PrimaryText>

            {INTAKE_DEMO_CONFIG.availableReasons.map((item, index) => (
                <div
                    key={index}
                    className="
                        mt-24px flex items-center cursor-pointer px-16px py-24px
                        rounded-16px overflow-hidden
                        border border-solid border-brand-primary
                    "
                    onClick={onReasonSelected.bind({}, item)}
                >
                    {item.prefixIcon && (
                        <div className="mr-16px">
                            {typeof item.prefixIcon === "string" ? (
                                <img
                                    src={item.prefixIcon}
                                    alt={item.displayValue}
                                />
                            ) : (
                                item.prefixIcon
                            )}
                        </div>
                    )}

                    <PrimaryText
                        className="flex-1"
                        typography="subtitleSemibold"
                    >
                        {item.displayValue}
                    </PrimaryText>
                </div>
            ))}
        </div>
    )
}
