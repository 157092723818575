import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import {
    PageHelmetElement,
    PrimaryText,
    useScrollFix,
} from "nirvana-react-elements"

import {
    ActiveCoverageNoticeType,
    AvailablePlanStatus,
    CalculatorResultType,
    GENERAL_CONFIG,
} from "../../../config/general.config"
import { calculatorSelector } from "../../../selectors/calculator.selector"
import { CoverageBreakdownComponent } from "../coverageBreakdown/coverageBreakdown.component"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { InviteTherapistPopupComponent } from "../../popups/inviteTherapistPopup.component"
import { runtimeSelector } from "../../../selectors/runtime.selector"
import { AnalyticsHelper } from "../../../helpers/analytics.helper"
import { AnalyticsEvent } from "../../../config/analytics.config"

import successImg from "../../../assets/images/shapes/success.svg"
import exclamationImg from "../../../assets/images/icons/exclamation-dark.svg"

export const CalculatorSuccessComponent: React.FunctionComponent = () => {
    useScrollFix()

    const navigate = useNavigate()

    const { embedKey } = useParams()

    const calculatorState = useSelector(calculatorSelector)
    const runtimeState = useSelector(runtimeSelector)

    const [isTherapistInviteActive, setIsTherapistInviteActive] =
        useState<boolean>(false)

    const activeCoverageNotices = useMemo<ActiveCoverageNoticeType[]>(() => {
        const result = [] as ActiveCoverageNoticeType[]

        if (!calculatorState.data) {
            return result
        }

        // NOTICE for 206
        if (
            calculatorState.data.resultType ===
            CalculatorResultType.activePartialCoverage
        ) {
            result.push(ActiveCoverageNoticeType.partialCoverage)
        }

        // NOTICE for plan status ACTIVE AT RISK
        if (
            calculatorState.data.planStatus === AvailablePlanStatus.activeAtRisk
        ) {
            result.push(ActiveCoverageNoticeType.planActiveAtRisk)
        }

        return result
    }, [calculatorState.data])

    useEffect(() => {
        if (!calculatorState.data) {
            navigate(
                embedKey
                    ? ROUTES_CONFIG.embedCalculatorUrl.replace(
                          ":embedKey",
                          embedKey,
                      )
                    : ROUTES_CONFIG.calculatorUrl,
            )
        } else {
            AnalyticsHelper.event(AnalyticsEvent.calculatorResultEligible)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (
            !calculatorState.data ||
            !calculatorState.isProcessed ||
            runtimeState.isEmbed
        ) {
            return
        }

        setIsTherapistInviteActive(!calculatorState.data.isTherapistNirvanaUser)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calculatorState.data])

    const getRenderedCoverageNotice = (
        noticeType: ActiveCoverageNoticeType,
    ): JSX.Element => {
        let badge: string | undefined = undefined
        let badgeColors = "bg-brand-vibrantPurple text-white"
        let message: string | JSX.Element = ""
        let isBoldMessage = true

        switch (noticeType) {
            case ActiveCoverageNoticeType.partialCoverage:
                isBoldMessage = false
                message = (
                    <>
                        This plan is Active, however Nirvana does not yet
                        support cost-estimates on this plan.
                    </>
                )

                break

            case ActiveCoverageNoticeType.planActiveAtRisk:
                isBoldMessage = false
                badge = "Active - At Risk"
                message = (
                    <>
                        This plan is Active, but is flagged by the Insurer as
                        either delinquent or under investigation and there is a
                        risk the Insurer will not provide coverage for services
                        rendered. You should reach out to your insurance company
                        and resolve any outstanding issues.
                    </>
                )

                break
        }

        return (
            <div
                className={`
                    mt-10px flex gap-16px p-16px rounded-8px
                    bg-brand-offWhite border border-solid border-brand-warmShadow
                    md:block md:mt-0px
                    ${badge ? "items-start" : "items-center"}
                `}
            >
                {badge ? (
                    <div
                        className={`
                            px-10px py-5px rounded-6px whitespace-nowrap
                            ${badgeColors}
                            md:text-center
                        `}
                    >
                        {badge}
                    </div>
                ) : (
                    <img
                        src={exclamationImg}
                        className="md:hidden"
                        alt="Attention"
                    />
                )}

                <PrimaryText
                    className={`
                        ${badge ? "md:mt-24px" : ""}
                        md:text-center
                    `}
                    typography={isBoldMessage ? "textBold" : "text"}
                >
                    {message}
                </PrimaryText>
            </div>
        )
    }

    return (
        <div className="w-full">
            <div className="primary-container">
                <PageHelmetElement
                    title="Success"
                    defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
                />

                <div className="mt-16px flex items-center sm:block">
                    <div className="mr-24px sm:hidden">
                        <img src={successImg} alt="Success" />
                    </div>

                    <div className="flex-1 sm:mt-16px">
                        <PrimaryText typography="h1">Congrats!</PrimaryText>

                        <PrimaryText className="mt-8px">
                            Great news{" "}
                            <span className="mouseflow-ignore">
                                {calculatorState.inputData?.firstName}
                            </span>
                            ! You are eligible for mental health benefits under
                            your current health plan.
                        </PrimaryText>
                    </div>
                </div>

                {/*Coverage notices*/}
                <div className="mt-60px md:mt-24px">
                    {activeCoverageNotices.length
                        ? activeCoverageNotices.map((item, index) => (
                              <div key={index}>
                                  {getRenderedCoverageNotice(item)}
                              </div>
                          ))
                        : null}
                </div>

                <CoverageBreakdownComponent className="mt-40px" />

                {/*<FAQComponent />*/}

                <InviteTherapistPopupComponent
                    isActive={isTherapistInviteActive && !runtimeState.isEmbed}
                    onDone={() => setIsTherapistInviteActive(false)}
                    closeOnOutsideClick
                    increasedZIndex
                />
            </div>
        </div>
    )
}
