import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import ReduxToastr from "react-redux-toastr"
import TagManager from "react-gtm-module"
import { BrowserRouter } from "react-router-dom"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { ReactQueryProvider } from "nirvana-react-elements"
import { datadogRum } from "@datadog/browser-rum"

// POLYFILLS
import "unfetch/polyfill"

// BOOTSTRAP
import { RouterContainer } from "./containers/router.container"
import * as serviceWorker from "./serviceWorker"

// STYLES
import "nirvana-react-elements/dist/esm/antd.css"
// TODO uncomment if there's a need in code highlighting like in IDE in CodeCopyBlockElement
// import "nirvana-react-elements/dist/esm/prism-code-formatter.css"
import "./assets/styles/tailwind.css"
import "react-redux-toastr/lib/css/react-redux-toastr.min.css"

// PROJECT
import { appStore } from "./store"
import "./config/fontAwesome.config"
import { GENERAL_CONFIG } from "./config/general.config"

// INIT STRIPE
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN as string)

// INIT GOOGLE TAG MANAGER
if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
    TagManager.initialize({
        gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
        dataLayer: {},
    })
}

// INIT DATADOG
if (
    process.env.REACT_APP_DD_APP_ID &&
    process.env.REACT_APP_DD_CLIENT_TOKEN &&
    process.env.REACT_APP_DD_SERVICE
) {
    datadogRum.init({
        applicationId: process.env.REACT_APP_DD_APP_ID,
        clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: "datadoghq.com",
        service: process.env.REACT_APP_DD_SERVICE,
        env: process.env.REACT_APP_ENV,
        version: process.env.REACT_APP_RELEASE_VERSION,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask",
    })
}

ReactDOM.render(
    <Provider store={appStore}>
        <BrowserRouter>
            <Elements stripe={stripePromise}>
                <ReactQueryProvider>
                    <RouterContainer />
                </ReactQueryProvider>
            </Elements>
        </BrowserRouter>

        <ReduxToastr
            timeOut={4000}
            newestOnTop
            preventDuplicates
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
        />
    </Provider>,
    document.getElementById(GENERAL_CONFIG.rootElementId),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
