import TagManager from "react-gtm-module"

import { AnalyticsEvent } from "../config/analytics.config"

/**
 * Class for working with analytics that we have behind our application
 * At the moment we are using: gTagManager -> GoogleAnalytics
 */
export class AnalyticsHelper {
    /**
     * Fire some event to our analytics provider
     * Make sure these events are properly configured (trigger + GA4 Event tag) in tag manager
     */
    static event(event: AnalyticsEvent, data: IIndexable = {}) {
        if (!process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
            return
        }

        try {
            TagManager.dataLayer({
                dataLayer: {
                    event,
                    ...data,
                },
            })
        } catch (e) {}
    }
}
