// https://support.google.com/analytics/answer/9267735?hl=en
export enum AnalyticsEvent {
    // EMBED KEY
    embedKeyChecked = "embed_key_checked",

    // CHECKOUT
    checkoutPopupOpened = "checkout_popup_opened",
    checkoutPopupClosed = "checkout_popup_closed",
    checkoutStripeInitiated = "checkout_stripe_initiated",
    checkoutSuccess = "checkout_success",
    checkoutFailed = "checkout_failed",

    // GENERAL CALCULATOR
    calculatorFormShown = "calculator_form_shown",
    calculatorFormSubmitted = "calculator_form_submitted",
    calculatorResultEligible = "calculator_result_eligible",
    calculatorResultNotEligible = "calculator_result_not_eligible",
    calculatorResultError = "calculator_result_error",
}

export const ANALYTICS_CONFIG = {
    googleAnalytics: {
        customDimensions: {
            embedKey: "calc_embed_key",
        },
    },
}
