import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import { IFileToUpload } from "nirvana-react-elements"

import { GENERAL_CONFIG } from "../config/general.config"

export class PdfHelper {
    /**
     * Save element by id to PDF
     */
    static async savePageAsPDF(
        elementIds: string[] = [GENERAL_CONFIG.rootElementId],
        resultingFileName = "nirvana-calculator-result",
    ) {
        const pdf = await PdfHelper.getPagePdf(elementIds)

        pdf.save(`${resultingFileName}.pdf`)
    }

    /**
     * Create PDF based on element IDs provided
     */
    static async getPagePdf(
        elementIds: string[] = [GENERAL_CONFIG.rootElementId],
    ): Promise<jsPDF> {
        const pdf = new jsPDF("p", "mm", "a4")

        await PdfHelper.addElementsToPDF(pdf, elementIds)

        return pdf
    }

    /**
     * Convert jsPDF object to file that we are going to upload to nirvana backend
     */
    static jsPdfToFileToUpload(
        pdf: jsPDF,
        fileName = "nirvana",
    ): IFileToUpload {
        fileName = `${fileName}.pdf`

        return {
            fileName,
            data: pdf.output("datauristring", {
                filename: fileName,
            }),
            mime: "application/pdf",
            extension: "pdf",
        }
    }

    /**
     * Add multiple images to PDF based on passed element IDs
     */
    private static async addElementsToPDF(
        pdf: jsPDF,
        elementIds: string[],
        imagesYMargin = 10,
    ) {
        let yOffset = 0

        for (const elementId of elementIds) {
            const element = document.getElementById(elementId)

            if (!element) {
                continue
            }

            const canvas = await html2canvas(element, {
                ignoreElements: element => {
                    try {
                        return element.className.includes(
                            GENERAL_CONFIG.pdfGenerationIgnoreClassName,
                        )
                    } catch (e) {
                        return false
                    }
                },
            })
            const imgData = canvas.toDataURL("image/png")

            let imgWidth: number
            let imgHeight: number

            // This handles the case when canvas's width is more than height
            // So basically for desktop
            if (canvas.height <= canvas.width) {
                imgWidth = pdf.internal.pageSize.getWidth()
                imgHeight = (canvas.height * imgWidth) / canvas.width
            } else {
                // This handles the case when height more than width
                // Usually this is the case for mobiles
                imgHeight = pdf.internal.pageSize.getHeight()
                imgWidth = (canvas.width * imgHeight) / canvas.height
            }

            pdf.addImage(imgData, "JPEG", 0, yOffset, imgWidth, imgHeight)

            yOffset = yOffset + imagesYMargin + imgHeight
        }
    }
}
