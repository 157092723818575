import React from "react"
import { Link } from "react-router-dom"
import {
    PageHelmetElement,
    PrimaryText,
    ButtonElement,
} from "nirvana-react-elements"

import { ROUTES_CONFIG } from "../config/routes.config"
import { GENERAL_CONFIG } from "../config/general.config"

export const NotFoundComponent: React.FunctionComponent<{
    onGoHomeBtnClick?: () => void
}> = props => {
    return (
        <div className="primary-container mb-20px">
            <PageHelmetElement
                title="Not found"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            <PrimaryText typography="h1" centered>
                Oops...
            </PrimaryText>

            <PrimaryText typography="text" centered className="mt-24px">
                The page you are looking for does not exist.
            </PrimaryText>

            <div className="mt-32px">
                <Link to={ROUTES_CONFIG.calculatorUrl} className="text-center">
                    <ButtonElement
                        label="Go to main page"
                        type="primary"
                        htmlType="button"
                        size="large"
                        onClick={props.onGoHomeBtnClick}
                    />
                </Link>
            </div>
        </div>
    )
}
