/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import {
    BrowserStorageHelper,
    BrowserStorageType,
    PageHelmetElement,
    StepperElement,
    useScrollFix,
} from "nirvana-react-elements"

import { IntakeDemoHeaderComponent } from "./intakeDemoHeader.component"
import { intakeDemoSelector } from "../../selectors/intakeDemo.selector"
import { IntakeDemoAuthComponent } from "./intakeDemoAuth.component"
import { GENERAL_CONFIG } from "../../config/general.config"
import { STEPPER_CONFIG } from "../../config/stepper.config"
import { IntakeDemoFlow } from "../../config/intakeDemo.config"
import { IntakeDemoFailureComponent } from "./intakeDemoFailure.component"
import { IntakeDemoSuccessComponent } from "./intakeDemoSuccess.component"
import { intakeDemoActionCreators } from "../../actions/intakeDemo.actions"

export const IntakeDemoComponent: React.FunctionComponent = () => {
    useScrollFix()

    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()

    const intakeDemoState = useSelector(intakeDemoSelector)

    // Checkout session id that was successful
    const [demoLogo] = useState<string | null>(
        searchParams.get(GENERAL_CONFIG.urlSearchParamsKeys.logo),
    )

    const [intakeDemoInitialData, setIntakeDemoInitialData] =
        useState<IGetCoverageInitialData>()

    // On mount check if already authenticated
    // In this case validate that authentication is valid
    useEffect(() => {
        if (!intakeDemoState.isAuthenticated) {
            return
        }

        dispatch(
            intakeDemoActionCreators.authStart(
                BrowserStorageHelper.get(
                    GENERAL_CONFIG.browserStorageKeys.tokenKey,
                    undefined,
                    BrowserStorageType.sessionStorage,
                ),
            ),
        )
    }, [])

    const getSpecialStepBottomContent = (
        currentStepIndex: number,
    ): JSX.Element | null => {
        return currentStepIndex === 0 ? (
            <div className="fixed bottom-24px z-10 horizontal-center">
                <img
                    src={`https://nirvana-assets.s3.amazonaws.com/intake-demo-logos/${
                        demoLogo || "default.png"
                    }`}
                    width={250}
                    alt="Demo logo"
                />
            </div>
        ) : null
    }

    const onInitialStepperSubmit = (data: IGetCoverageInitialData) => {
        setIntakeDemoInitialData(data)

        dispatch(intakeDemoActionCreators.getCoverage(data))
    }

    const onPayerStepperSubmit = (data: IGetCoveragePayerData) => {
        if (!intakeDemoInitialData) {
            dispatch(
                intakeDemoActionCreators.setCurrentFlow(
                    IntakeDemoFlow.initialData,
                ),
            )

            return
        }

        dispatch(
            intakeDemoActionCreators.getCoverage(intakeDemoInitialData, data),
        )
    }

    const currentFlowContent = useMemo<JSX.Element>(() => {
        switch (intakeDemoState.currentFlow) {
            case IntakeDemoFlow.failure:
                return <IntakeDemoFailureComponent />

            case IntakeDemoFlow.success:
                return <IntakeDemoSuccessComponent />

            case IntakeDemoFlow.payerData:
                return (
                    <StepperElement
                        key="intakeDemoPayer"
                        steps={STEPPER_CONFIG.intakeDemoPayer}
                        onStepperSubmit={onPayerStepperSubmit}
                    />
                )

            case IntakeDemoFlow.initialData:
            default:
                return (
                    <StepperElement
                        key="intakeDemoInitial"
                        steps={STEPPER_CONFIG.intakeDemoInitial}
                        onStepperSubmit={onInitialStepperSubmit}
                        getSpecialStepBottomContent={
                            getSpecialStepBottomContent
                        }
                    />
                )
        }
    }, [intakeDemoState.currentFlow])

    return (
        <div>
            <PageHelmetElement
                title="Intake Demo"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            <IntakeDemoHeaderComponent />

            <div className="px-24px">
                {!intakeDemoState.isAuthenticated ? (
                    <IntakeDemoAuthComponent />
                ) : (
                    currentFlowContent
                )}
            </div>
        </div>
    )
}
