import React from "react"

import { IRadioItem } from "nirvana-react-elements"

import simplePracticeImg from "../assets/images/ehr/simple-practice.svg"
import therapyNotesImg from "../assets/images/ehr/therapy-notes.svg"
// import theraNestImg from "../assets/images/ehr/thera-nest.svg"

export enum AvailableTaxIdType {
    SSN = "SY",
    EIN = "EI",
}

// Underscores in values for backward compatibility with Sensible configurations
export enum PdfParsingSupportedEHR {
    therapyNotes = "therapy_notes",
    simplePractice = "simple_practice",
    // TODO uncomment when we'll support TheraNest for pre-configured parsing
    // theraNest = "thera_nest",
}

export const CALCULATOR_CHECKOUT_CONFIG = {
    defaultEmbedCalculatorPrice: 10 * 100, // 10$ in cents

    // we'll be billing maximum for 2 positions (meaning 2 and 5 therapists will cost same)
    embedCalculatorBillingQuantityCap: 2,

    defaultHealthProviderRate: 200, // in $
    defaultCptCode: "90834",

    inputDataTherapistsKey: "healthProviders",
    inputDataRatesKey: "rates",

    minHealthProvidersCount: 1,
    maxHealthProvidersCount: 40,

    graduatedPricingRules: [] as IStripeGraduatedPricingRule[],

    // Check successful checkout session id maximum for X times with delays
    // Then just ignore it
    successCheckoutSessionMaxChecksTries: 20,

    availablePracticeNpiTypes: [
        {
            className: "mt-12px!",
            label: "Group NPI",
            value: "group",
        },
        {
            className: "mt-25px!",
            label: "Individual NPI",
            value: "individual",
        },
    ] as IRadioItem[],

    availableTaxIdTypes: [
        {
            className: "mt-12px!",
            label: (
                <span>
                    Tax ID (EIN)
                    <span className="italic ml-10px">*recommended</span>
                </span>
            ),
            value: AvailableTaxIdType.EIN,
        },
        {
            className: "mt-25px!",
            label: "Social Security Number",
            value: AvailableTaxIdType.SSN,
        },
    ] as IRadioItem[],

    availableEhrSystems: [
        {
            type: PdfParsingSupportedEHR.therapyNotes,
            img: therapyNotesImg,
        },
        {
            type: PdfParsingSupportedEHR.simplePractice,
            img: simplePracticeImg,
        },
        // TODO uncomment when we'll support TheraNest for pre-configured parsing
        // {
        //     type: PdfParsingSupportedEHRSystem.theraNest,
        //     img: theraNestImg,
        // },
    ] as IPdfParsingAvailableEhrSystem[],
}

// 0-2 -> 10$ per unit
// 3+ -> 0$ per unit
CALCULATOR_CHECKOUT_CONFIG.graduatedPricingRules = [
    {
        countRangeMin: 0,
        countRangeMax:
            CALCULATOR_CHECKOUT_CONFIG.embedCalculatorBillingQuantityCap,
        unitPrice: CALCULATOR_CHECKOUT_CONFIG.defaultEmbedCalculatorPrice,
    },
    {
        countRangeMin:
            CALCULATOR_CHECKOUT_CONFIG.embedCalculatorBillingQuantityCap + 1,
        countRangeMax: Number.MAX_SAFE_INTEGER, // infinity basically
        unitPrice: 0,
    },
]
