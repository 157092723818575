import { IStepperStep } from "nirvana-react-elements"

import { ReasonStep } from "../components/intakeDemo/steps/reason.step"
import { ClientDataStep } from "../components/intakeDemo/steps/clientData.step"
import { InsuranceDataStep } from "../components/intakeDemo/steps/insuranceData.step"

export const STEPPER_CONFIG = {
    intakeDemoInitial: [
        {
            key: "reasonData",
            stepComponent: ReasonStep,
        },
        {
            key: "clientData",
            stepComponent: ClientDataStep,
        },
    ] as IStepperStep[],

    intakeDemoPayer: [
        {
            key: "insuranceData",
            stepComponent: InsuranceDataStep,
        },
    ] as IStepperStep[],
}
