/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import {
    AutoCompleteElement,
    ButtonElement,
    INPUT_MASK_VALIDATION_FUNCTIONS,
    INPUT_MASKS_CONFIG,
    InputElement,
    ISelectRawOption,
    ISelectRenderedOption,
    LookupHelper,
    NIRVANA_THEME,
    PageHelmetElement,
    PrimaryText,
    REGEX_CONFIG,
    SelectElement,
    SpinnerElement,
    useIsMobile,
    useStateCallback,
    VALIDATION_CONFIG,
    NirvanaBreathingLoaderElement,
    CollapseElement,
} from "nirvana-react-elements"

import {
    CalculatorResultType,
    GENERAL_CONFIG,
} from "../../config/general.config"
import { LookupService } from "../../services/lookup.service"
import { calculatorActionCreators } from "../../actions/calculator.actions"
import { calculatorSelector } from "../../selectors/calculator.selector"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { InviteTherapistPopupComponent } from "../popups/inviteTherapistPopup.component"
import { TOOLTIPS_CONFIG } from "../../config/tooltips.config"
import { runtimeSelector } from "../../selectors/runtime.selector"
import { NotFoundComponent } from "../notFound.component"
import { embedCalculatorActionCreators } from "../../actions/embedCalculator.actions"
import { embedCalculatorSelector } from "../../selectors/embedCalculator.selector"
import { AnalyticsHelper } from "../../helpers/analytics.helper"
import { AnalyticsEvent } from "../../config/analytics.config"
import { DEMO_CONFIG } from "../../config/demo.config"
import { CalculatorErrorCardElement } from "../elements/calculatorErrorCard.element"
import { RuntimeHelper } from "../../helpers/runtime.helper"

import privacyImage from "../../assets/images/icons/privacy.svg"
import calculatorImage from "../../assets/images/icons/calculator.svg"
import arrowDownImage from "../../assets/images/icons/arrow-down-dark.svg"
import calendarImage from "../../assets/images/icons/calendar-dark.svg"

export const CalculatorDataCollectionComponent: React.FunctionComponent<{
    cptCodes: ISelectRawOption[]
}> = props => {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const isMobile = useIsMobile()
    const isTablet = useIsMobile(NIRVANA_THEME.mediaQueries.md)

    const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: { errors },
        watch,
    } = useForm()

    const cptCodeWatcher = watch("cptCode")

    const runtimeState = useSelector(runtimeSelector)
    const calculatorState = useSelector(calculatorSelector)
    const embedCalculatorState = useSelector(embedCalculatorSelector)

    // For calculator embeds make space more condensed so it looks better on customer's websites
    const isCondensedSpacing = useMemo<boolean>(() => {
        return runtimeState.isEmbed && (isMobile || isTablet)
    }, [isMobile, isTablet, runtimeState.isEmbed])

    // By default we consider it checked if it doesn't exist
    const [embedKeyChecked, setEmbedKeyChecked] = useState<boolean>(
        !embedCalculatorState.embedKey,
    )
    const [isBadEmbedKey, setIsBadEmbedKey] = useState<boolean>(false)

    // const [isEmbedCheckoutActive, setIsEmbedCheckoutActive] =
    //     useState<boolean>(false)

    const [
        embedKeySelectedTherapistCptCodes,
        setEmbedKeySelectedTherapistCptCodes,
    ] = useStateCallback<IHealthProviderCptCodeRate[]>([])

    // On mount if there is data in state from previous submissions -> set it as predefined locally
    const [predefinedData] = useState<ICalculatorInputData | null>(
        calculatorState.inputData && !calculatorState.inputData.isDemoData
            ? calculatorState.inputData
            : null,
    )

    const [demoData, setDemoData] = useState<ICalculatorInputData | null>(null)

    const [practices, setPractices] = useState([] as ISimplifiedPractice[])
    const [
        predefinedExternalHealthProvider,
        setPredefinedExternalHealthProvider,
    ] = useState<IHealthProvider>()

    const [isTherapistInviteActive, setIsTherapistInviteActive] =
        useState<boolean>(false)

    // Indicates if coverage check is running now
    const isCalculationLoading: boolean = useMemo(
        () => RuntimeHelper.isCalculatorGetCoverageLoading(),
        [runtimeState.isLoading],
    )

    const practiceOptions = useMemo<ISelectRawOption[]>(
        () => practices.map(LookupHelper.getRenderedRawSimplifiedPractice),
        [practices],
    )

    const defaultPayer = useMemo<string | undefined>(() => {
        return predefinedData?.payer
            ? LookupHelper.getRenderedPayer(predefinedData?.payer).displayValue
            : undefined
    }, [predefinedData])

    // Record event to analytics that we have shown form to user
    // Set not processed when entering the component
    useEffect(() => {
        AnalyticsHelper.event(AnalyticsEvent.calculatorFormShown)

        dispatch(calculatorActionCreators.setProcessed(false))
    }, [])

    // Set therapist and payer from our predefined data to hook form
    useEffect(() => {
        if (
            !predefinedData?.healthProvider &&
            !predefinedData?.payer &&
            !predefinedData?.practice
        ) {
            return
        }

        if (predefinedData?.healthProvider) {
            const embedKeyHealthProviderRate =
                embedCalculatorState.checkedEmbedKey?.healthProviderRates.find(
                    item =>
                        item.healthProvider.npi ===
                        predefinedData.healthProvider.npi,
                )

            if (embedKeyHealthProviderRate) {
                onEmbedKeyTherapistSelected(embedKeyHealthProviderRate, false)
            } else {
                onTherapistSelected(
                    predefinedData.healthProvider,
                    undefined,
                    !predefinedData.practice,
                )

                !predefinedData.isDemoData &&
                    setPredefinedExternalHealthProvider(
                        predefinedData.healthProvider,
                    )
            }
        }

        predefinedData?.payer && setValue("payer", predefinedData?.payer)

        predefinedData?.practice && onPracticeSelected(predefinedData.practice)
    }, [
        predefinedData?.healthProvider,
        predefinedData?.payer,
        predefinedData?.practice,
    ])

    // Watch for embed key in state
    // Then send request to check validity of it and receive embed key related data
    useEffect(() => {
        if (!embedCalculatorState.embedKey) {
            setEmbedKeyChecked(true) // just in case

            return
        }

        // Send this with timeout so for example if navigating to new url
        // And old key is still in session it won't send request with old key (until state is updated with new key)
        // Send request to server and check this key and get related to it data
        const timeoutId = setTimeout(() => {
            embedCalculatorState.embedKey &&
                dispatch(
                    embedCalculatorActionCreators.checkEmbedKey(
                        embedCalculatorState.embedKey,
                        () => {
                            setEmbedKeyChecked(true)
                        },
                        () => {
                            setIsBadEmbedKey(true)
                        },
                    ),
                )
        }, 1000)

        return () => {
            try {
                clearTimeout(timeoutId)
            } catch (e) {}
        }
    }, [embedCalculatorState.embedKey])

    // Once we've received checked embed key
    // Select first health provider and select it
    // Also set it in our local state for external health provider so it appears in autocomplete
    // Ignore this if data was inputed previously -> it will be handled there
    useEffect(() => {
        if (
            !embedCalculatorState.checkedEmbedKey?.healthProviderRates.length ||
            predefinedData
        ) {
            return
        }

        // Select first health provider
        const neededHealthProviderRate =
            embedCalculatorState.checkedEmbedKey.healthProviderRates[0]

        onEmbedKeyTherapistSelected(neededHealthProviderRate)

        setPredefinedExternalHealthProvider(
            neededHealthProviderRate.healthProvider,
        )
    }, [embedCalculatorState.checkedEmbedKey])

    // Watch for new url parameters
    // Either npi can be provided or sessionCharge can be provided
    // Ignore this if this calculator was embedded through bought embed key -> in this case we'll get data from backend
    // Also it might include parameter to activate purchase popup automatically
    useEffect(() => {
        // Don't process url params for embed calculator, it will all come by embed key checking
        if (embedCalculatorState.embedKey) {
            return
        }

        const providedNpi = searchParams.get(
            GENERAL_CONFIG.urlSearchParamsKeys.npi,
        )

        const providedSessionCharge = searchParams.get(
            GENERAL_CONFIG.urlSearchParamsKeys.sessionCharge,
        )

        providedNpi && processPredefinedNpiFromUrl(providedNpi)

        providedSessionCharge &&
            processPredefinedSessionChargeFromUrl(providedSessionCharge)

        // setIsEmbedCheckoutActive(
        //     !!searchParams.get(GENERAL_CONFIG.urlSearchParamsKeys.purchase),
        // )
    }, [])

    const processPredefinedNpiFromUrl = async (npi: string) => {
        // Check if NPI is valid
        // Also don't process this for embed calculator
        if (embedCalculatorState.embedKey || !REGEX_CONFIG.npi.test(npi)) {
            return
        }

        const neededHealthProviders =
            await LookupService.lookupHealthProvidersWithPractices(npi)

        if (!neededHealthProviders.length) {
            return
        }

        onTherapistSelected(neededHealthProviders[0], false)

        setPredefinedExternalHealthProvider(neededHealthProviders[0])
    }

    const processPredefinedSessionChargeFromUrl = (sessionCharge: string) => {
        // Don't process for embed calculator
        if (embedCalculatorState.embedKey) {
            return
        }

        // Should come in cents from url
        const parsed = parseInt(sessionCharge)

        if (!parsed) {
            return
        }

        setValue("sessionCharge", parsed)
    }

    //////////////////////////////////////////////////////////////////// THERAPIST / PRACTICE HANDLERS

    const onEmbedKeyTherapistSelected = (
        item: IHealthProviderRate,
        resetCptCodeRate = true,
    ) => {
        onTherapistSelected(item.healthProvider, false)

        setEmbedKeySelectedTherapistCptCodes(item.rates || [], newValues => {
            if (resetCptCodeRate && newValues.length) {
                onEmbedKeyCptCodeRateSelected(newValues[0])
            }
        })
    }

    const onEmbedKeyCptCodeRateSelected = (
        item: IHealthProviderCptCodeRate,
    ) => {
        setValue("sessionCharge", item.sessionRate)
        setValue("cptCode", item.cptCode.value)
    }

    const onTherapistSelected = (
        item: IHealthProvider,
        resetExternalHealthProvider = true,
        autoSelectPractice = true,
    ) => {
        setValue("healthProvider", item, { shouldValidate: true })

        // Reset what was provided from url or from embed key data
        if (resetExternalHealthProvider) {
            setPredefinedExternalHealthProvider(undefined)
        }

        // IF this is not embed calculator through embedKey
        // Allow to set practices
        if (!embedCalculatorState.checkedEmbedKey) {
            setPractices(item.practices || [])

            autoSelectPractice &&
                onPracticeSelected(
                    item.practices && item.practices.length
                        ? item.practices[0]
                        : null,
                )
        }
    }

    // Ignore practice selection on usage with embed, since we are going just hide this input there 100%
    const onPracticeSelected = (item: ISimplifiedPractice | null) => {
        // Don't process for embed calculator
        if (embedCalculatorState.embedKey) {
            return
        }

        setValue("practice", item?.id, {
            shouldValidate: !!item,
        })
    }

    const getRenderedHealthProvider = (
        item: IHealthProvider,
        dropdownDisplayValueAdditionalPart?: string,
        ignoreAddress = false,
    ): ISelectRenderedOption => {
        return LookupHelper.getRenderedHealthProvider(
            item,
            true,
            ignoreAddress,
            true,
            dropdownDisplayValueAdditionalPart,
        )
    }

    // Watch for health providers related to embed key and prepare them rendered state
    const getRenderedEmbedKeyHealthProviders = (): ISelectRenderedOption[] => {
        if (!embedCalculatorState.checkedEmbedKey) {
            return [] as ISelectRenderedOption[]
        }

        return embedCalculatorState.checkedEmbedKey.healthProviderRates.map(
            item => ({
                ...getRenderedHealthProvider(
                    item.healthProvider,
                    undefined,
                    true,
                ),

                value: item,
            }),
        )
    }

    const activateDemoClient = (
        demoData = DEMO_CONFIG.clients.successResult,
    ) => {
        setDemoData(demoData)
    }

    const cancelDemoClient = () => {
        setDemoData(null)
    }

    // Once we have results -> redirect to needed results page
    // Target redirect url also preserves embed key if it's embed calculator right now
    const redirectBasedOnResultType = (resultType: CalculatorResultType) => {
        // check for embedCalculatorState.embedKey here -> redirect to different routes based on embed key
        // By default don't redirect anywhere, but show error on the same screen
        let routeToNavigate: string | undefined

        switch (resultType) {
            case CalculatorResultType.activeCoverage:
            case CalculatorResultType.activePartialCoverage:
                routeToNavigate = embedCalculatorState.embedKey
                    ? ROUTES_CONFIG.embedCalculatorSuccessUrl
                    : ROUTES_CONFIG.calculatorSuccessUrl

                break

            case CalculatorResultType.noCoverage:
                routeToNavigate = embedCalculatorState.embedKey
                    ? ROUTES_CONFIG.embedCalculatorIneligibleUrl
                    : ROUTES_CONFIG.calculatorIneligibleUrl

                break

            case CalculatorResultType.generalError:
                routeToNavigate = embedCalculatorState.embedKey
                    ? ROUTES_CONFIG.embedCalculatorInconclusiveUrl
                    : ROUTES_CONFIG.calculatorInconclusiveUrl

                break

            default:
                routeToNavigate = undefined
        }

        routeToNavigate &&
            navigate(
                routeToNavigate.replace(
                    ":embedKey",
                    embedCalculatorState.embedKey || "",
                ),
            )
    }

    const performCalculation = (data: any) => {
        const inputData = data as ICalculatorInputData

        // Since our dropdown returns number here
        // Also if it's for embed calculator -> reset practice here
        inputData.practice = embedCalculatorState.embedKey
            ? undefined
            : practices.find(
                  practice => practice.id === (inputData.practice as any),
              )

        inputData.dob = INPUT_MASK_VALIDATION_FUNCTIONS.date.parse(
            inputData.dob,
        )

        dispatch(
            calculatorActionCreators.setInputData(
                inputData,
                redirectBasedOnResultType,
                embedCalculatorState.embedKey || undefined,
            ),
        )
    }

    return !isBadEmbedKey ? (
        <div className="w-full">
            <div className="primary-container">
                <PageHelmetElement
                    title="Nirvana Health"
                    defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
                />

                <div>
                    <PrimaryText
                        typography={
                            isCondensedSpacing || isMobile
                                ? "h3"
                                : isTablet
                                ? "h2"
                                : "h1"
                        }
                        ignoreMobileSizeMultiplier={isCondensedSpacing}
                    >
                        {runtimeState.isEmbed
                            ? "Reimbursement Calculator"
                            : "Out-of-Network Reimbursement Calculator"}
                    </PrimaryText>

                    {calculatorState.isProcessed ? (
                        <CalculatorErrorCardElement className="mt-34px" />
                    ) : (
                        <>
                            <PrimaryText className="mt-8px">
                                {runtimeState.isEmbed
                                    ? "Get an estimate of your out-of-network insurance reimbursement for therapy."
                                    : "Get an estimate of your insurance reimbursement for therapy."}
                            </PrimaryText>

                            <PrimaryText
                                className={`
                                    md:mt-8px
                                    ${
                                        embedCalculatorState.embedKey
                                            ? "hidden"
                                            : ""
                                    }
                                `}
                            >
                                Understanding out-of-network benefits is hard!
                                That's why we built our Reimbursement
                                Calculator.
                            </PrimaryText>
                        </>
                    )}
                </div>

                {embedKeyChecked ? (
                    <form
                        onSubmit={
                            !demoData
                                ? handleSubmit(performCalculation)
                                : e => {
                                      e.preventDefault()

                                      performCalculation(demoData)
                                  }
                        }
                        className="pb-120px"
                    >
                        <div
                            className={`
                                relative p-16px rounded-8px bg-brand-offWhite min-h-130px
                                ${
                                    isCondensedSpacing
                                        ? "mt-24px"
                                        : "mt-50px md:mt-40px"
                                }
                            `}
                        >
                            <PrimaryText typography="h5">
                                Your Information
                            </PrimaryText>

                            {/*DEMO CLIENT BUTTONS*/}
                            {!embedCalculatorState.embedKey && (
                                <div className="absolute top-10px right-16px">
                                    {demoData ? (
                                        <ButtonElement
                                            label="Reset Demo"
                                            type="default"
                                            htmlType="button"
                                            size="middle"
                                            onClick={cancelDemoClient}
                                        />
                                    ) : (
                                        <ButtonElement
                                            label="Demo Client"
                                            type="default"
                                            htmlType="button"
                                            size="middle"
                                            onClick={() => activateDemoClient()}
                                        />
                                    )}
                                </div>
                            )}

                            <div className="flex items-start mt-44px md:block">
                                <InputElement
                                    className="
                                        mr-20px flex-1
                                        md:mr-0px md:mt-44px
                                    "
                                    label="First Name"
                                    name="firstName"
                                    tooltip={
                                        TOOLTIPS_CONFIG.calculator.firstName
                                    }
                                    reactHookControl={
                                        !demoData ? control : undefined
                                    }
                                    reactHookValidations={{
                                        required: VALIDATION_CONFIG.required,
                                        maxLength: VALIDATION_CONFIG.maxLength,
                                    }}
                                    reactHookErrors={errors}
                                    defaultValue={
                                        predefinedData?.firstName ||
                                        demoData?.firstName
                                    }
                                    disabled={!!demoData}
                                />

                                <InputElement
                                    className="
                                        mr-20px flex-1
                                        md:mr-0px md:mt-44px
                                    "
                                    label="Last Name"
                                    name="lastName"
                                    tooltip={
                                        TOOLTIPS_CONFIG.calculator.lastName
                                    }
                                    reactHookControl={
                                        !demoData ? control : undefined
                                    }
                                    reactHookValidations={{
                                        required: VALIDATION_CONFIG.required,
                                        maxLength: VALIDATION_CONFIG.maxLength,
                                    }}
                                    reactHookErrors={errors}
                                    defaultValue={
                                        predefinedData?.lastName ||
                                        demoData?.lastName
                                    }
                                    disabled={!!demoData}
                                />

                                <InputElement
                                    className="flex-1 md:mt-44px"
                                    label="Date of Birth"
                                    name="dob"
                                    reactHookControl={
                                        !demoData ? control : undefined
                                    }
                                    reactHookValidations={{
                                        required: VALIDATION_CONFIG.required,
                                        validate: VALIDATION_CONFIG.date,
                                    }}
                                    inputSuffix={
                                        <img src={calendarImage} alt="Date" />
                                    }
                                    maskOptions={INPUT_MASKS_CONFIG.dob}
                                    placeholder="mm/dd/yyyy"
                                    mask={Date}
                                    reactHookErrors={errors}
                                    defaultValue={
                                        predefinedData?.dob || demoData?.dob
                                            ? INPUT_MASK_VALIDATION_FUNCTIONS.date.format(
                                                  predefinedData?.dob ||
                                                      demoData?.dob,
                                              )
                                            : undefined
                                    }
                                    disabled={!!demoData}
                                />
                            </div>
                        </div>

                        <div className="relative p-16px rounded-8px mt-32px bg-brand-offWhite min-h-130px">
                            <PrimaryText typography="h5">
                                Your Therapist
                            </PrimaryText>

                            <div
                                className="
                                    flex items-start mt-44px
                                    md:block md:mt-0px
                                "
                            >
                                {/*for embed key select only from allowed therapists*/}
                                {/*IF there's only one item based on embedKey -> select and disable*/}
                                {/*FOR demo client just show disabled input in place*/}
                                {/*Otherwise use auto complete*/}
                                {!demoData ? (
                                    <AutoCompleteElement
                                        className="
                                            mr-20px flex-1
                                            md:mr-0px md:mt-44px
                                        "
                                        name="healthProvider"
                                        label={
                                            !embedCalculatorState.checkedEmbedKey
                                                ? "Therapist Name or NPI"
                                                : "Therapist Name"
                                        }
                                        disallowClear={
                                            !!embedCalculatorState.checkedEmbedKey
                                        }
                                        placeholder=""
                                        inputSuffix={
                                            embedCalculatorState.checkedEmbedKey
                                                ? arrowDownImage
                                                : undefined
                                        }
                                        dataFetcher={
                                            !embedCalculatorState.checkedEmbedKey
                                                ? LookupService.lookupHealthProvidersWithPractices
                                                : undefined
                                        }
                                        itemRenderer={getRenderedHealthProvider}
                                        renderedOptions={
                                            embedCalculatorState.checkedEmbedKey
                                                ? getRenderedEmbedKeyHealthProviders()
                                                : undefined
                                        }
                                        defaultValue={
                                            predefinedData?.healthProvider
                                                ? getRenderedHealthProvider(
                                                      predefinedData.healthProvider,
                                                  ).displayValue
                                                : undefined
                                        }
                                        externalValue={
                                            predefinedExternalHealthProvider
                                                ? getRenderedHealthProvider(
                                                      predefinedExternalHealthProvider,
                                                  ).displayValue
                                                : undefined
                                        }
                                        onSelected={
                                            embedCalculatorState.checkedEmbedKey
                                                ? (
                                                      item: IHealthProviderRate,
                                                  ) => {
                                                      onEmbedKeyTherapistSelected(
                                                          item,
                                                      )
                                                  }
                                                : (item: IHealthProvider) => {
                                                      onTherapistSelected(item)
                                                  }
                                        }
                                        debounceMilliseconds={250}
                                        reactHookFormRegister={register}
                                        reactHookFormErrors={errors}
                                        validations={{
                                            required:
                                                VALIDATION_CONFIG.required,
                                        }}
                                        disabled={
                                            embedCalculatorState.checkedEmbedKey
                                                ?.healthProviderRates.length ===
                                            1
                                        }
                                        notFoundTitle={
                                            !embedCalculatorState.checkedEmbedKey
                                                ? "Don’t see your therapist?"
                                                : undefined
                                        }
                                        notFoundSubtitle={
                                            !embedCalculatorState.checkedEmbedKey
                                                ? "Invite your therapist to Nirvana Health today"
                                                : undefined
                                        }
                                        notFoundAction={
                                            !embedCalculatorState.checkedEmbedKey
                                                ? () => (
                                                      <ButtonElement
                                                          label="Invite your therapist"
                                                          type="primary"
                                                          htmlType="button"
                                                          size="large"
                                                          onClick={() =>
                                                              !embedCalculatorState.embedKey &&
                                                              setIsTherapistInviteActive(
                                                                  true,
                                                              )
                                                          }
                                                      />
                                                  )
                                                : undefined
                                        }
                                    />
                                ) : (
                                    <InputElement
                                        className="
                                            mr-20px flex-1
                                            md:mr-0px md:mt-44px
                                        "
                                        name="healthProvider"
                                        label={
                                            !embedCalculatorState.checkedEmbedKey
                                                ? "Therapist Name or NPI"
                                                : "Therapist Name"
                                        }
                                        defaultValue={
                                            demoData.healthProvider
                                                ? getRenderedHealthProvider(
                                                      demoData.healthProvider,
                                                  ).displayValue
                                                : undefined
                                        }
                                        disabled
                                    />
                                )}

                                {/*HIDE FOR EMBED KEY*/}
                                {!embedCalculatorState.embedKey ? (
                                    <SelectElement
                                        className="
                                            mr-20px flex-1
                                            md:mr-0px md:mt-44px
                                        "
                                        label="Practice Name (optional)"
                                        name="practice"
                                        reactHookFormRegister={register}
                                        reactHookFormSet={setValue}
                                        reactHookFormErrors={errors}
                                        reactHookFormControl={
                                            !demoData ? control : undefined
                                        }
                                        placeholder="Please choose"
                                        disabled={
                                            !!demoData || !practices.length
                                        }
                                        options={practiceOptions}
                                        defaultValue={
                                            predefinedData?.practice ||
                                            demoData?.practice
                                                ? LookupHelper.getRenderedSimplifiedPractice(
                                                      // @ts-ignore
                                                      predefinedData?.practice ||
                                                          demoData?.practice,
                                                  ).value
                                                : undefined
                                        }
                                    />
                                ) : null}

                                <SelectElement
                                    className="
                                        flex-1
                                        md:mt-44px
                                    "
                                    label="Session Type"
                                    name="cptCode"
                                    reactHookFormRegister={register}
                                    reactHookFormSet={setValue}
                                    reactHookFormErrors={errors}
                                    reactHookFormControl={control}
                                    defaultValue={
                                        cptCodeWatcher ||
                                        predefinedData?.cptCode ||
                                        props.cptCodes[0]?.value
                                    }
                                    disabled={
                                        !embedCalculatorState.checkedEmbedKey ||
                                        embedKeySelectedTherapistCptCodes.length <
                                            2
                                    }
                                    onSelected={
                                        embedCalculatorState.checkedEmbedKey
                                            ? selectedValue => {
                                                  const neededCptCodeRate =
                                                      embedKeySelectedTherapistCptCodes.find(
                                                          rate =>
                                                              rate.cptCode
                                                                  .value ===
                                                              selectedValue,
                                                      )

                                                  neededCptCodeRate &&
                                                      onEmbedKeyCptCodeRateSelected(
                                                          neededCptCodeRate,
                                                      )
                                              }
                                            : undefined
                                    }
                                    options={
                                        embedCalculatorState.checkedEmbedKey &&
                                        embedKeySelectedTherapistCptCodes.length >
                                            1
                                            ? props.cptCodes.filter(cptCode =>
                                                  embedKeySelectedTherapistCptCodes
                                                      .map(
                                                          item =>
                                                              item.cptCode
                                                                  .value,
                                                      )
                                                      .includes(cptCode.value),
                                              )
                                            : props.cptCodes
                                    }
                                />
                            </div>
                        </div>

                        <div className="relative p-16px rounded-8px mt-32px bg-brand-offWhite min-h-130px">
                            <div className="flex items-center md:block">
                                <PrimaryText
                                    typography="h5"
                                    className="mr-40px md:mr-0px"
                                >
                                    Your Insurance
                                </PrimaryText>

                                <PrimaryText typography="caption">
                                    Please note, only PPO plans can typically
                                    expect reimbursement
                                </PrimaryText>
                            </div>

                            <div
                                className="
                                    flex items-start mt-44px
                                    md:block md:mt-0px
                                "
                            >
                                <div
                                    className="
                                        mr-20px flex-1
                                        md:mr-0px md:mt-44px
                                    "
                                >
                                    {!demoData ? (
                                        <AutoCompleteElement
                                            name="payer"
                                            label="Insurance Provider"
                                            tooltip={
                                                TOOLTIPS_CONFIG.calculator
                                                    .insuranceProvider
                                            }
                                            placeholder=""
                                            dataFetcher={(search?: string) =>
                                                LookupService.lookupPayers(
                                                    search,
                                                    true,
                                                )
                                            }
                                            itemRenderer={
                                                LookupHelper.getRenderedPayer
                                            }
                                            reactHookFormErrors={errors}
                                            reactHookFormRegister={register}
                                            reactHookFormSet={setValue}
                                            debounceMilliseconds={250}
                                            defaultValue={defaultPayer}
                                            validations={{
                                                required:
                                                    VALIDATION_CONFIG.required,
                                            }}
                                            shouldValidate
                                            notFoundTitle="Don’t see your insurance provider?"
                                            notFoundSubtitle={`
                                                    <a
                                                        href="mailto:${GENERAL_CONFIG.supportEmail}?subject=Insurance provider not found"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Contact Support
                                                    </a>
                                                `}
                                            notFoundAction={() => (
                                                <a
                                                    href={
                                                        GENERAL_CONFIG.supportedInsurersUrl
                                                    }
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <ButtonElement
                                                        label="View Supported Payers"
                                                        type="primary"
                                                        htmlType="button"
                                                        size="large"
                                                    />
                                                </a>
                                            )}
                                            disabled={!!demoData}
                                        />
                                    ) : (
                                        <InputElement
                                            name="payer"
                                            label="Insurance Provider"
                                            tooltip={
                                                TOOLTIPS_CONFIG.calculator
                                                    .insuranceProvider
                                            }
                                            defaultValue={
                                                demoData.payer
                                                    ? LookupHelper.getRenderedPayer(
                                                          demoData.payer,
                                                      ).displayValue
                                                    : undefined
                                            }
                                            disabled
                                        />
                                    )}

                                    <a
                                        href={
                                            GENERAL_CONFIG.supportedInsurersUrl
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                        className="no-underline!"
                                    >
                                        <PrimaryText
                                            className="mt-8px"
                                            typography="captionSemibold"
                                            underline
                                        >
                                            View all supported insurance
                                            providers
                                        </PrimaryText>
                                    </a>
                                </div>

                                <InputElement
                                    className="
                                        flex-1
                                        md:mt-44px
                                    "
                                    label="Member ID"
                                    name="memberId"
                                    reactHookErrors={errors}
                                    reactHookControl={
                                        !demoData ? control : undefined
                                    }
                                    reactHookValidations={{
                                        required: VALIDATION_CONFIG.required,
                                    }}
                                    defaultValue={
                                        predefinedData?.memberId ||
                                        demoData?.memberId
                                    }
                                    disabled={!!demoData}
                                />

                                <InputElement
                                    name="sessionCharge"
                                    reactHookControl={control}
                                    defaultValue={predefinedData?.sessionCharge}
                                    className="hidden"
                                />
                            </div>
                        </div>

                        <footer
                            className="
                                flex items-start mt-36px
                                md:block
                            "
                        >
                            <div className="flex-1">
                                <CollapseElement
                                    className="max-w-570px md:max-w-none"
                                    titles={[
                                        {
                                            icon: privacyImage,
                                            text: "Our Privacy Promise",
                                        },
                                    ]}
                                    contents={[
                                        <>
                                            Nirvana Health takes your data
                                            security and privacy seriously. We
                                            only use your information to
                                            communicate directly with your
                                            insurance company (checking
                                            benefits, filing claims, etc.). We
                                            are fully HIPAA compliant and your
                                            data will never be shared without
                                            your consent for anything other than
                                            what is necessary to process your
                                            claims. For more, please read our
                                            full{" "}
                                            <a
                                                href={`${GENERAL_CONFIG.mainWebsiteUrl}/legal/privacy-policy`}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="text-brand-primary!"
                                            >
                                                Privacy Policy
                                            </a>
                                            .
                                        </>,
                                    ]}
                                />
                            </div>

                            <div className="md:mt-35px">
                                <ButtonElement
                                    label="Calculate"
                                    type="primary"
                                    size="large"
                                    icon={calculatorImage}
                                    htmlType="submit"
                                    buttonClassName="md:w-full"
                                />
                            </div>
                        </footer>
                    </form>
                ) : (
                    <SpinnerElement containerClassName="mt-90px text-center" />
                )}
            </div>

            {/*DON'T show for embed key*/}
            <InviteTherapistPopupComponent
                isActive={
                    isTherapistInviteActive && !embedCalculatorState.embedKey
                }
                onDone={() => setIsTherapistInviteActive(false)}
                closeOnOutsideClick
                increasedZIndex
            />

            {/*DON'T show for embedded already*/}
            {/*{isEmbedCheckoutActive && !embedCalculatorState.embedKey ? (*/}
            {/*    <CalculatorCheckoutPopupComponent*/}
            {/*        isActive={true}*/}
            {/*        onDone={() => setIsEmbedCheckoutActive(false)}*/}
            {/*        onInviteTherapist={() => setIsTherapistInviteActive(true)}*/}
            {/*    />*/}
            {/*) : null}*/}

            {/*{!runtimeState.isEmbed && !calculatorState.isProcessed ? (*/}
            {/*    <PromoPopupWrapperElement*/}
            {/*        name="calculatorSell"*/}
            {/*        collapsedTitle="Are you a therapist?"*/}
            {/*        content={*/}
            {/*            <div>*/}
            {/*                <PrimaryText typography="h4">*/}
            {/*                    Are you a therapist?*/}
            {/*                </PrimaryText>*/}

            {/*                <PrimaryText className="mt-12px">*/}
            {/*                    Get a personalized, embedded version of this*/}
            {/*                    calculator for your website.*/}
            {/*                </PrimaryText>*/}

            {/*                <ButtonElement*/}
            {/*                    className="mt-16px"*/}
            {/*                    buttonClassName="w-full"*/}
            {/*                    label="Buy now"*/}
            {/*                    onClick={() => {*/}
            {/*                        setIsEmbedCheckoutActive(true)*/}
            {/*                    }}*/}
            {/*                    size="large"*/}
            {/*                    type="primary"*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*        }*/}
            {/*    />*/}
            {/*) : null}*/}

            <NirvanaBreathingLoaderElement isActive={isCalculationLoading} />
        </div>
    ) : (
        <NotFoundComponent
            onGoHomeBtnClick={() =>
                embedCalculatorState.embedKey &&
                dispatch(embedCalculatorActionCreators.setEmbedKey(undefined))
            }
        />
    )
}
