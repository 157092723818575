export enum CalculatorCheckoutActions {
    CALCULATOR_CREATE_CHECKOUT_SESSION = "CALCULATOR_CREATE_CHECKOUT_SESSION",
    CALCULATOR_CHECKOUT_SESSION_GET_MANAGEMENT_KEY = "CALCULATOR_CHECKOUT_SESSION_GET_MANAGEMENT_KEY",
}

export const calculatorCheckoutActionCreators = {
    createCheckoutSession: (
        email: string,
        therapistsData: ICalculatorCheckoutSingleTherapistData[],
        onSuccess: (checkoutSessionId: string) => Promise<void>,
        practiceCreationAllowed?: boolean,
    ): ISagaAction => {
        return {
            type: CalculatorCheckoutActions.CALCULATOR_CREATE_CHECKOUT_SESSION,
            payload: {
                email,
                therapistsData,
                practiceCreationAllowed,
            },
            onSuccess,
        }
    },

    getSuccessfulSessionManagementKey: (
        checkoutSessionId: string,
        onError: (data: {
            checkoutSessionId: string
            currentTriesCount: number
        }) => void,
        currentTriesCount = 0,
    ): ISagaAction => {
        return {
            type: CalculatorCheckoutActions.CALCULATOR_CHECKOUT_SESSION_GET_MANAGEMENT_KEY,
            payload: {
                checkoutSessionId,
                currentTriesCount,
            },
            onError,
        }
    },
}
