import {
    BrowserStorageType,
    BrowserStorageHelper,
} from "nirvana-react-elements"

import { GENERAL_CONFIG } from "../config/general.config"
import { EmbedCalculatorActions } from "../actions/embedCalculator.actions"
import { EmbedCalculatorStateClass } from "../classes/embedCalculatorState.class"

export const embedCalculatorReducer = (
    state = new EmbedCalculatorStateClass(),
    action: ISagaAction,
): IEmbedCalculatorState => {
    switch (action.type) {
        case EmbedCalculatorActions.EMBED_CALCULATOR_SET_KEY:
            // Put to session storage as well so it survives page reload
            action.payload
                ? BrowserStorageHelper.set(
                      GENERAL_CONFIG.browserStorageKeys.embedKey,
                      action.payload,
                      BrowserStorageType.sessionStorage,
                  )
                : BrowserStorageHelper.remove(
                      GENERAL_CONFIG.browserStorageKeys.embedKey,
                      BrowserStorageType.sessionStorage,
                  )

            return {
                ...state,
                embedKey: action.payload,
            }

        case EmbedCalculatorActions.EMBED_CALCULATOR_SET_MANAGEMENT_KEY:
            return {
                ...state,
                managementKey: action.payload,
            }

        case EmbedCalculatorActions.EMBED_CALCULATOR_SET_CHECKED_KEY:
            return {
                ...state,
                checkedEmbedKey: action.payload,
            }

        default:
            return state
    }
}
