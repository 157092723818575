/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react"
import { useSelector } from "react-redux"
import {
    PrimaryText,
    ButtonElement,
    PromoPopupWrapperElement,
} from "nirvana-react-elements"

import { embedCalculatorSelector } from "../../selectors/embedCalculator.selector"
import { EmbedCalculatorSetupPracticePopupComponent } from "../popups/embedCalculatorSetupPracticePopup.component"

export const PracticeSetupPromoComponent: React.FunctionComponent = () => {
    const embedCalculatorState = useSelector(embedCalculatorSelector)

    const [practiceSetupPopupEnabled, setPracticeSetupPopupEnabled] =
        useState<boolean>(false)

    // Wait until embed key and management key is set in state
    return embedCalculatorState.checkedEmbedKey?.practiceCreationAllowed &&
        !embedCalculatorState.checkedEmbedKey.practice &&
        embedCalculatorState.managementKey ? (
        <>
            {/*DON'T show for embedded already*/}
            <EmbedCalculatorSetupPracticePopupComponent
                isActive={practiceSetupPopupEnabled}
                onDone={() => setPracticeSetupPopupEnabled(false)}
            />

            <PromoPopupWrapperElement
                name="practiceCreation"
                collapsedTitle="Your clients can file claims through Nirvana"
                height={260}
                content={
                    <div>
                        <PrimaryText typography="h4">
                            Your clients can file claims through Nirvana
                        </PrimaryText>

                        <PrimaryText className="mt-12px">
                            Simply set up your practice information and your
                            clients will be able to start filing claims directly
                            through us.
                        </PrimaryText>

                        <ButtonElement
                            className="mt-16px"
                            label="Learn more"
                            onClick={() => {
                                setPracticeSetupPopupEnabled(true)
                            }}
                            size="large"
                            type="primary"
                        />
                    </div>
                }
            />
        </>
    ) : null
}
