import { put, all, fork, takeLatest } from "redux-saga/effects"
import { call } from "typed-redux-saga"

import { runtimeActionCreators } from "../actions/runtime.actions"
import { CalculatorCheckoutService } from "../services/calculatorCheckout.service"
import { CalculatorCheckoutActions } from "../actions/calculatorCheckout.actions"
import { AnalyticsHelper } from "../helpers/analytics.helper"
import { AnalyticsEvent } from "../config/analytics.config"
import { embedCalculatorActionCreators } from "../actions/embedCalculator.actions"

////////////////////////////////////////////////////////////////////////////////////////// CREATE EMBED CHECKOUT SESSION
function* createEmbedCalculatorCheckoutSessionSaga() {
    yield takeLatest(
        CalculatorCheckoutActions.CALCULATOR_CREATE_CHECKOUT_SESSION,
        createEmbedCalculatorCheckoutSessionSagaHandler,
    )
}

function* createEmbedCalculatorCheckoutSessionSagaHandler<
    T extends ISagaAction & {
        email: string
        therapistsData: ICalculatorCheckoutSingleTherapistData[]
        practiceCreationAllowed?: boolean
    },
>(action: T) {
    yield put(
        runtimeActionCreators.startLoading(
            "createEmbedCalculatorCheckoutSessionSagaHandler",
        ),
    )

    try {
        const result = yield* call(
            CalculatorCheckoutService.createEmbedCalculatorCheckoutSession,
            action.payload.email,
            action.payload.therapistsData,
            action.payload.practiceCreationAllowed,
        )

        if (result) {
            AnalyticsHelper.event(AnalyticsEvent.checkoutStripeInitiated)

            action.onSuccess && action.onSuccess(result.checkoutSessionId)
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
    } finally {
        yield put(
            runtimeActionCreators.stopLoading(
                "createEmbedCalculatorCheckoutSessionSagaHandler",
            ),
        )
    }
}
////////////////////////////////////////////////////////////////////////////////////////// CREATE EMBED CHECKOUT SESSION

////////////////////////////////////////////////////////////////////////////////////////// GET SUCCESSFUL CHECKOUT SESSION MANAGEMENT KEY
function* getSuccessfulSessionManagementKeySaga() {
    yield takeLatest(
        CalculatorCheckoutActions.CALCULATOR_CHECKOUT_SESSION_GET_MANAGEMENT_KEY,
        getSuccessfulSessionManagementKeySagaHandler,
    )
}

function* getSuccessfulSessionManagementKeySagaHandler<
    T extends ISagaAction & {
        checkoutSessionId: string
        currentTriesCount: number
    },
>(action: T) {
    yield put(
        runtimeActionCreators.startLoading(
            "getSuccessfulSessionManagementKeySagaHandler",
        ),
    )

    try {
        const result = yield* call(
            CalculatorCheckoutService.getCheckoutSuccessfulSessionManagementKey,
            action.payload.checkoutSessionId,
        )

        if (result) {
            yield put(
                embedCalculatorActionCreators.checkManagementKey(
                    result.managementKey,
                ),
            )

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(action.payload)
        }
    } catch (e) {
    } finally {
        yield put(
            runtimeActionCreators.stopLoading(
                "getSuccessfulSessionManagementKeySagaHandler",
            ),
        )
    }
}
////////////////////////////////////////////////////////////////////////////////////////// GET SUCCESSFUL CHECKOUT SESSION MANAGEMENT KEY

export default function* rootSaga() {
    yield all([
        fork(createEmbedCalculatorCheckoutSessionSaga),
        fork(getSuccessfulSessionManagementKeySaga),
    ])
}
