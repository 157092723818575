/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import {
    useScrollFix,
    PageHelmetElement,
    PrimaryText,
    VALIDATION_CONFIG,
    ButtonElement,
    InputElement,
} from "nirvana-react-elements"

import { embedCalculatorActionCreators } from "../../actions/embedCalculator.actions"
import { runtimeSelector } from "../../selectors/runtime.selector"
import { GENERAL_CONFIG } from "../../config/general.config"

import namasteImg from "../../assets/images/shapes/login.svg"
import sendEmailSuccessImg from "../../assets/images/shapes/email-sent.svg"
import mailIcon from "../../assets/images/icons/mail-dark.svg"
import sendWhiteIcon from "../../assets/images/icons/send-white.svg"

export const EmbedCalculatorManagementLoginComponent: React.FunctionComponent =
    () => {
        useScrollFix()

        const dispatch = useDispatch()

        const {
            handleSubmit,
            control,
            formState: { errors },
        } = useForm()

        const runtimeState = useSelector(runtimeSelector)

        const [loginLinkSent, setLoginLinkSent] = useState<boolean>(false)

        const performLogin = (data: { email: string } | any) => {
            dispatch(
                embedCalculatorActionCreators.loginManagementPortal(
                    data.email,
                    () => setLoginLinkSent(true),
                ),
            )
        }

        return (
            <div className="screen-centered-container">
                <PageHelmetElement
                    title="Log In"
                    defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
                />

                {!loginLinkSent ? (
                    <>
                        <div className="mt-48px w-full text-center">
                            <img
                                src={namasteImg}
                                alt="Nirvana Health"
                                title="Nirvana Health"
                                className="margin-x-center sm:w-150px"
                            />
                        </div>

                        <div className="text-center mt-32px md:px-16px">
                            <PrimaryText typography="h1">
                                Welcome back to <br /> Nirvana Health
                            </PrimaryText>

                            <PrimaryText
                                typography="subtitle"
                                className="mt-32px"
                            >
                                For security purposes, please confirm your email
                                address. You will receive a link to login.
                            </PrimaryText>
                        </div>

                        <form
                            onSubmit={handleSubmit(performLogin)}
                            noValidate={true}
                            className="md:px-16px"
                        >
                            <InputElement
                                className="mt-48px"
                                name="email"
                                label="Email address"
                                reactHookControl={control}
                                reactHookErrors={errors}
                                reactHookValidations={{
                                    required: VALIDATION_CONFIG.required,
                                    pattern: VALIDATION_CONFIG.email,
                                    maxLength: VALIDATION_CONFIG.maxLength,
                                }}
                                inputSuffix={
                                    <img
                                        src={mailIcon}
                                        alt="Email address"
                                        title="Email address"
                                        className="ml-8px"
                                    />
                                }
                            />

                            <div className="flex mt-32px">
                                <div className="flex-1" />

                                <ButtonElement
                                    label="Send Login Link"
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    icon={sendWhiteIcon}
                                    isLoading={!!runtimeState.isLoading.length}
                                />
                            </div>
                        </form>
                    </>
                ) : (
                    <>
                        <div className="mt-48px w-full text-center">
                            <img
                                src={sendEmailSuccessImg}
                                className="margin-x-center sm:w-150px"
                                alt="Login success"
                                title="Login success"
                            />
                        </div>

                        <PrimaryText
                            className="mt-32px"
                            typography="h1"
                            centered
                        >
                            Almost there!
                        </PrimaryText>

                        <PrimaryText
                            typography="text"
                            centered
                            className="mt-32px"
                        >
                            Please{" "}
                            <span className="text-bold">check your email</span>{" "}
                            for a login link. <br /> If you have any questions,
                            please contact{" "}
                            <a
                                href={`mailto:${GENERAL_CONFIG.supportEmail}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {GENERAL_CONFIG.supportEmail}
                            </a>
                        </PrimaryText>

                        <div className="mt-32px text-center">
                            <ButtonElement
                                label="Return to Login Page"
                                type="primary"
                                htmlType="button"
                                onClick={() => setLoginLinkSent(false)}
                            />
                        </div>
                    </>
                )}
            </div>
        )
    }
