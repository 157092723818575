import { NIRVANA_GENERAL_CONFIG } from "nirvana-react-elements"

export enum CalculatorResultType {
    unknownPlanStatus = "unknownPlanStatus",
    unknownDemographics = "unknownDemographics",
    activeCoverage = "activeCoverage",
    activePartialCoverage = "activePartialCoverage",
    activePlanCoverageUnknown = "activePlanCoverageUnknown",
    noCoverage = "noCoverage",
    generalError = "generalError",
    memberNotFound = "memberNotFound",
    connectionIssue = "connectionIssue",
    payerNotSupported = "payerNotSupported",
    providerNotRecognized = "providerNotRecognized",
    errorRetrievingCoverage = "errorRetrievingCoverage",
    memberIdPayerIdInvalid = "memberIdPayerIdInvalid",
    planTypeNotSupported = "planTypeNotSupported",
    invalidInput = "invalidInput",
    unexpectedError = "unexpectedError",
    tooManyRequests = "tooManyRequests",
    payerFailure = "payerFailure",
}

export enum AvailablePlanStatus {
    unknown = "UNKNOWN",
    active = "ACTIVE",
    activeAtRisk = "ACTIVE_AT_RISK",
    inactive = "INACTIVE",
}

export enum ActiveCoverageNoticeType {
    planActiveAtRisk = "planActiveAtRisk",
    partialCoverage = "partialCoverage",
}

export const GENERAL_CONFIG = {
    // Common values from our elements library that can be overridden here if needed
    ...NIRVANA_GENERAL_CONFIG,

    defaultPageTitle: "Out-of-Network Reimbursement Calculator",

    pdfGenerationIgnoreClassName: "nirvana-pdf-ignore",

    urlSearchParamsKeys: {
        ...NIRVANA_GENERAL_CONFIG.urlSearchParamsKeys,

        checkoutSessionId: "checkoutSessionId",
        whiteLabeled: "whitelabeled",
        withPracticeCreation: "withPracticeCreation",
        logo: "logo",
    },
}
