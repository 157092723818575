import { appStore } from "../store"

export class RuntimeHelper {
    ////////////////////////////////////////////////////////////////////////////////// CALCULATOR

    /**
     * Check if get coverage is loading now in main calc
     */
    static isCalculatorGetCoverageLoading(): boolean {
        return RuntimeHelper.isLoading("getCalculatorDataSagaHandler")
    }
    ////////////////////////////////////////////////////////////////////////////////// CALCULATOR

    ////////////////////////////////////////////////////////////////////////////////// INTAKE DEMO

    /**
     * Check if get coverage is loading now in intake demo
     */
    static isIntakeDemoGetCoverageLoading(): boolean {
        return RuntimeHelper.isLoading("intakeDemoGetCoverageSagaHandler")
    }

    ////////////////////////////////////////////////////////////////////////////////// INTAKE DEMO

    /**
     * Get current state of loading array
     */
    static isLoading(key: string): boolean {
        const currentlyLoading = appStore.getState().runtime.isLoading

        return !!~currentlyLoading.indexOf(key)
    }
}
