/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react"
import {
    Route,
    Navigate,
    Routes,
    useSearchParams,
    useLocation,
} from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
    NirvanaGlobalFonts,
    useIsOnline,
    useCurrentRoute,
    OfflineElement,
} from "nirvana-react-elements"

import { NotFoundComponent } from "../components/notFound.component"
import { HeaderComponent } from "../components/header.component"
import { NirvanaBackgroundComponent } from "../components/elements/nirvanaBackground.element"
import { runtimeSelector } from "../selectors/runtime.selector"
import { FULLY_HEADLESS_ROUTES, ROUTES_CONFIG } from "../config/routes.config"
import { GENERAL_CONFIG } from "../config/general.config"
import { runtimeActionCreators } from "../actions/runtime.actions"
import { embedCalculatorSelector } from "../selectors/embedCalculator.selector"
import { ANALYTICS_CONFIG, AnalyticsEvent } from "../config/analytics.config"
import { AnalyticsHelper } from "../helpers/analytics.helper"

// ROUTES
// import { DowntimeComponent } from "../components/downtime.component"
// CALCULATOR
import { CalculatorComponent } from "../components/calculator/calculator.component"
import { CalculatorSuccessComponent } from "../components/calculator/results/calculatorSuccess.component"
import { CalculatorInconclusiveComponent } from "../components/calculator/results/calculatorInconclusive.component"
import { CalculatorIneligibleComponent } from "../components/calculator/results/calculatorIneligible.component"
// SUPPORTED PAYERS LIST
import { SupportedPayersListsComponent } from "../components/supportedPayersList/supportedPayersList.component"
// SESSION RATES
import { SessionRatesComponent } from "../components/sessionRates/sessionRates.component"
// EMBED CALCULATOR CHECKOUT
import { CalculatorCheckoutFailedComponent } from "../components/calculatorCheckout/calculatorCheckoutFailed.component"
import { CalculatorCheckoutSuccessComponent } from "../components/calculatorCheckout/calculatorCheckoutSuccess.component"
// EMBED CALCULATOR
import { EmbedCalculatorComponent } from "../components/embedCalculator/embedCalculator.component"
import { EmbedCalculatorEnrollmentComponent } from "../components/embedCalculatorEnrollment/embedCalculatorEnrollment.component"
// EMBED CALCULATOR MANAGEMENT
import { EmbedCalculatorManagementLoginComponent } from "../components/embedCalculator/embedCalculatorManagementLogin.component"
import { EmbedCalculatorManagementComponent } from "../components/embedCalculator/embedCalculatorManagement.component"
// INTAKE DEMO
import { IntakeDemoComponent } from "../components/intakeDemo/intakeDemo.component"

const ROUTES_PATHS = Object.values(ROUTES_CONFIG)

export const RouterContainer = () => {
    const isOnline = useIsOnline()

    const dispatch = useDispatch()
    const runtime = useSelector(runtimeSelector)
    const embedCalculatorState = useSelector(embedCalculatorSelector)

    const location = useLocation()
    const [searchParams] = useSearchParams()

    const currentRoute = useCurrentRoute(ROUTES_PATHS, location)

    // Without header and nirvana-background
    const isFullyHeadless = useMemo<boolean>(() => {
        if (!currentRoute?.path) {
            return false
        }

        return FULLY_HEADLESS_ROUTES.includes(currentRoute.path)
    }, [currentRoute?.path])

    // Ignore this if we've already identified that it's embedded through our search param
    useEffect(() => {
        const isEmbedParam = searchParams.get(
            GENERAL_CONFIG.urlSearchParamsKeys.embed,
        )

        if (runtime.isEmbed || !isEmbedParam) {
            return
        }

        dispatch(runtimeActionCreators.setIsEmbed(!!isEmbedParam))
    }, [searchParams])

    // Set calc embed key in user's data layer for Google Analytics
    // So users can be filtered by it
    // Only set if it's valid (checked with backend)
    useEffect(() => {
        if (!embedCalculatorState.checkedEmbedKey) {
            return
        }

        AnalyticsHelper.event(AnalyticsEvent.embedKeyChecked, {
            [ANALYTICS_CONFIG.googleAnalytics.customDimensions.embedKey]:
                embedCalculatorState.checkedEmbedKey.embedKey,
        })
    }, [embedCalculatorState.checkedEmbedKey])

    return (
        <div>
            <NirvanaGlobalFonts />

            {!isFullyHeadless && (
                <>
                    <HeaderComponent />

                    <NirvanaBackgroundComponent />
                </>
            )}

            {/* <DowntimeComponent /> */}

            {isOnline ? (
                <>
                    <Routes>
                        {/*SUPPORTED PAYERS LIST*/}
                        <Route
                            path={ROUTES_CONFIG.supportedPayersListUrl}
                            element={<SupportedPayersListsComponent />}
                        />

                        {/*SESSION RATES CALCULATOR*/}
                        <Route
                            path={ROUTES_CONFIG.sessionRatesUrl}
                            element={<SessionRatesComponent />}
                        />

                        {/*DEFAULT CALCULATOR*/}
                        <Route
                            path={ROUTES_CONFIG.calculatorUrl}
                            element={<CalculatorComponent />}
                        />

                        <Route
                            path={ROUTES_CONFIG.calculatorSuccessUrl}
                            element={<CalculatorSuccessComponent />}
                        />

                        <Route
                            path={ROUTES_CONFIG.calculatorInconclusiveUrl}
                            element={<CalculatorInconclusiveComponent />}
                        />

                        <Route
                            path={ROUTES_CONFIG.calculatorIneligibleUrl}
                            element={<CalculatorIneligibleComponent />}
                        />

                        {/*EMBED SELF-SERVE CALCULATOR*/}
                        <Route
                            path={ROUTES_CONFIG.embedCalculatorUrl}
                            element={<EmbedCalculatorComponent />}
                        />
                        <Route
                            path={ROUTES_CONFIG.embedCalculatorEnrollmentUrl}
                            element={<EmbedCalculatorEnrollmentComponent />}
                        />

                        <Route
                            path={ROUTES_CONFIG.embedCalculatorSuccessUrl}
                            element={<CalculatorSuccessComponent />}
                        />

                        <Route
                            path={ROUTES_CONFIG.embedCalculatorInconclusiveUrl}
                            element={<CalculatorInconclusiveComponent />}
                        />

                        <Route
                            path={ROUTES_CONFIG.embedCalculatorIneligibleUrl}
                            element={<CalculatorIneligibleComponent />}
                        />

                        {/*CALCULATOR CHECKOUT*/}
                        <Route
                            path={ROUTES_CONFIG.calculatorCheckoutFailedUrl}
                            element={<CalculatorCheckoutFailedComponent />}
                        />

                        <Route
                            path={ROUTES_CONFIG.calculatorCheckoutSuccessUrl}
                            element={<CalculatorCheckoutSuccessComponent />}
                        />

                        {/*EMBED SELF-SERVE CALCULATOR MANAGEMENT*/}
                        <Route
                            path={
                                ROUTES_CONFIG.embedCalculatorManagementDefaultUrl
                            }
                            element={
                                <Navigate
                                    replace
                                    to={
                                        ROUTES_CONFIG.embedCalculatorManagementLoginUrl
                                    }
                                />
                            }
                        />

                        <Route
                            path={
                                ROUTES_CONFIG.embedCalculatorManagementLoginUrl
                            }
                            element={
                                <EmbedCalculatorManagementLoginComponent />
                            }
                        />

                        <Route
                            path={ROUTES_CONFIG.embedCalculatorManagementUrl}
                            element={<EmbedCalculatorManagementComponent />}
                        />

                        {/*INTAKE DEMO*/}
                        <Route
                            path={ROUTES_CONFIG.intakeDemo}
                            element={<IntakeDemoComponent />}
                        />

                        <Route path="*" element={<NotFoundComponent />} />
                    </Routes>
                </>
            ) : (
                <OfflineElement />
            )}
        </div>
    )
}
