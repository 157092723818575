import { UtilHelper } from "nirvana-react-elements"

import { HttpHelper } from "../helpers/http.helper"
import { API_ROUTES } from "../config/routes.config"

export class CalculatorCheckoutService {
    /**
     * Create checkout session in Stripe
     */
    static async createEmbedCalculatorCheckoutSession(
        email: string,
        therapistData: ICalculatorCheckoutSingleTherapistData[],
        practiceCreationAllowed?: boolean,
    ): Promise<{ checkoutSessionId: string } | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.embedCalculatorCheckoutUrl,
            {
                email,
                practiceCreationAllowed,

                therapistsData:
                    CalculatorCheckoutService.mapCheckoutTherapistsData(
                        therapistData,
                    ),
            },
            "POST",
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Exchange successful session id for management key that was created in db
     */
    static async getCheckoutSuccessfulSessionManagementKey(
        checkoutSessionId: string,
    ): Promise<{ managementKey: string } | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.embedCalculatorCheckoutGetManagementKeyUrl,
            {
                checkoutSessionId,
            },
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            [404],
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Map checkout input data prepared by front to the format that backend is expecting
     */
    static mapCheckoutTherapistsData(
        therapistsData: ICalculatorCheckoutSingleTherapistData[],
    ): IEmbedCalculatorCheckoutSingleTherapistRequestData[] {
        return therapistsData.map(item => ({
            healthProviderId: item.healthProvider.id,
            healthProviderPreferredName:
                item.healthProviderPreferredName || undefined,

            // We need to remove empty values from array
            rates: item.rates
                .filter(item => !!item)
                .map(rate => ({
                    sessionRate: parseInt(rate.sessionRate) * 100,
                    cptCode: rate.cptCode,
                })),
        }))
    }
}
