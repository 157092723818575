import { NavigateFunction } from "react-router-dom"
import {
    UtilHelper,
    HttpHelper as NirvanaHttpHelper,
    AvailableHttpMethod,
} from "nirvana-react-elements"

import { ToastrHelper } from "./toastr.helper"
import { ROUTES_CONFIG } from "../config/routes.config"
import { GENERAL_CONFIG } from "../config/general.config"

export class HttpHelper {
    /**
     * Send request to server
     */
    static async sendRequest(
        url: string,
        data: IIndexable = {},
        method: AvailableHttpMethod = "GET",
        headers = {},
        navigate?: NavigateFunction,
        authorizationHeader?: string,
        unauthorizedRedirectUrl = ROUTES_CONFIG.embedCalculatorManagementLoginUrl,
        ignoreToastErrorCodes: number[] = [],
    ): Promise<any> {
        return await NirvanaHttpHelper.sendRequest(
            url,
            data,
            method,
            process.env.REACT_APP_API_URL,
            headers,
            authorizationHeader,
            undefined,
            ignoreToastErrorCodes,
            ToastrHelper.error,
            ToastrHelper.warning,
            () => UtilHelper.redirectTo(unauthorizedRedirectUrl, navigate),
            authorizationHeader
                ? GENERAL_CONFIG.headers.managementKey
                : undefined,
        )
    }
}
