import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import {
    TooltipElement,
    useIsMobile,
    PrimaryText,
    UtilHelper,
    ProgressElement,
} from "nirvana-react-elements"

import { CoverageBreakdownCardComponent } from "./coverageBreakdownCard.component"
import { calculatorSelector } from "../../../selectors/calculator.selector"
import { TOOLTIPS_CONFIG } from "../../../config/tooltips.config"
import { embedCalculatorSelector } from "../../../selectors/embedCalculator.selector"

export const CoverageBreakdownComponent: React.FunctionComponent<{
    className?: string
}> = props => {
    const isMobile = useIsMobile()

    const calculatorState = useSelector(calculatorSelector)
    const embedCalculatorState = useSelector(embedCalculatorSelector)

    const expenses: {
        amount?: number
        percentage?: number
        isPreDeductibleStage?: boolean
        isDeductibleMetStage?: boolean
        isOopMaxMetStage?: boolean
        text?: JSX.Element
    } = useMemo(() => {
        if (!calculatorState.data || calculatorState.data.deductible === null) {
            return {}
        }

        const { deductible, remainingDeductible, oopMax, remainingOopMax } =
            calculatorState.data

        // Need to calculate total spent amount over the current year
        const spentAmount =
            remainingDeductible && deductible
                ? deductible - remainingDeductible
                : remainingOopMax && oopMax
                ? oopMax - remainingOopMax
                : null

        const totalSum =
            calculatorState.data.oopMax ||
            calculatorState.data.deductible ||
            null

        if (spentAmount === null || totalSum === null) {
            return {}
        }

        return {
            amount: spentAmount,
            percentage: (spentAmount / totalSum) * 100,
            isPreDeductibleStage:
                typeof deductible === "number" && spentAmount < deductible,
            isDeductibleMetStage:
                typeof deductible === "number" &&
                typeof oopMax === "number" &&
                spentAmount >= deductible &&
                spentAmount < oopMax,
            isOopMaxMetStage:
                typeof oopMax === "number" && spentAmount >= oopMax,
            text: (
                <>
                    You've spent at least{" "}
                    <span className="text-semibold">
                        {UtilHelper.getFormattedMoney(spentAmount, true)}
                    </span>{" "}
                    so far this year
                </>
            ),
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calculatorState.data])

    const reimbursementCalculations: {
        deductible: number | null
        oopMax: number | null
        deductibleRangeStart: number | null
        deductibleRangeEnd: number | null
        oopMaxRangeStart: number | null
        oopMaxRangeEnd: number | null
    } | null = useMemo(() => {
        if (!calculatorState.data) {
            return null
        }

        const {
            currentSessionRate,
            deductible,
            oopMax,
            postDeductiblePayerObligation,
            postOopMaxPayerObligation,
        } = calculatorState.data

        const deductibleRangeStart =
            postDeductiblePayerObligation !== null
                ? postDeductiblePayerObligation
                : null

        const oopMaxRangeStart =
            postOopMaxPayerObligation !== null
                ? postOopMaxPayerObligation
                : null

        // Max is just min increased by 15%
        return {
            deductible,
            oopMax,

            deductibleRangeStart,
            deductibleRangeEnd:
                deductibleRangeStart !== null
                    ? deductibleRangeStart * 1.15
                    : null,

            oopMaxRangeStart,
            oopMaxRangeEnd:
                oopMaxRangeStart !== null
                    ? currentSessionRate
                        ? Math.min(currentSessionRate, oopMaxRangeStart * 1.15)
                        : oopMaxRangeStart * 1.15
                    : null,
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calculatorState.data])

    const calculationMessage: JSX.Element | null = useMemo(() => {
        if (!calculatorState.data) {
            return null
        }

        const coachingCptCodesWarning = (
            <span>
                Coaching sessions may require prior authorization from your
                health plan.{" "}
            </span>
        )

        const messageParts = [] as JSX.Element[]

        if (
            calculatorState.data.deductible &&
            calculatorState.data.remainingDeductible
        ) {
            const { remainingSessionsBeforeDeductible, deductible } =
                calculatorState.data

            messageParts.push(
                <>
                    <span>
                        We've crunched the numbers and found that your mental
                        health benefits will kick in once you’ve reached your{" "}
                        <span className="text-semibold">
                            {UtilHelper.getFormattedMoney(deductible, true)}{" "}
                            deductible
                        </span>
                        , which should be after about{" "}
                        <span className="text-semibold">
                            {remainingSessionsBeforeDeductible || 0} session
                            {(remainingSessionsBeforeDeductible || 0) === 1
                                ? ""
                                : "s"}
                        </span>{" "}
                        with a therapist. Please note that with out-of-network
                        benefits, insurers can take up to 90 days to issue
                        reimbursements.{" "}
                    </span>
                </>,
            )

            // Warning about coaching CPT codes will appear only in embed calc for now
            // Add this warning always once CPT codes in main calc are expanded
            if (embedCalculatorState.embedKey) {
                messageParts.push(coachingCptCodesWarning)
            }

            messageParts.push(
                <TooltipElement
                    text={TOOLTIPS_CONFIG.coverageBreakdown.howGotCalculations}
                    isInlined
                    inlineLabel="How did we get this number?"
                />,
            )
        } else if (
            calculatorState.data.remainingOopMax &&
            calculatorState.data.oopMax
        ) {
            const { deductible } = calculatorState.data

            messageParts.push(
                <span>
                    We've crunched the numbers and found that your mental health
                    benefits have already kicked in, since you've reached your{" "}
                    {deductible ? (
                        <>
                            <span className="text-semibold">
                                {UtilHelper.getFormattedMoney(deductible, true)}
                            </span>{" "}
                        </>
                    ) : (
                        ""
                    )}
                    deductible.{" "}
                </span>,
            )

            // Warning about coaching CPT codes will appear only in embed calc for now
            // Add this warning always once CPT codes in main calc are expanded
            if (embedCalculatorState.embedKey) {
                messageParts.push(coachingCptCodesWarning)
            }
        }

        return (
            <>
                {messageParts.map((item, index) => (
                    <React.Fragment key={index}>{item}</React.Fragment>
                ))}
            </>
        )

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calculatorState.data, embedCalculatorState.embedKey])

    return calculatorState.data ? (
        <div
            className={`
                relative
                bg-brand-white pb-100px
                ${props.className}
            `}
        >
            <PrimaryText typography="h3">
                Your Mental Health Coverage Calculator
            </PrimaryText>

            <PrimaryText className="mt-12px">
                We took the information you graciously provided and analyzed
                your health plan. We found that your plan has{" "}
                <span className="text-semibold">3 phases</span> of mental health
                coverage.
                <br />
                <br />
                Each phase is determined by how much you’ve spent on healthcare
                during the plan year. Basically, once you hit certain spending
                levels, you can expect more money back per session.
                {isMobile ? (
                    <>
                        <br />
                        <br />
                    </>
                ) : (
                    " "
                )}
                {calculationMessage}
            </PrimaryText>

            {calculatorState.data?.requestId && (
                <PrimaryText
                    className="absolute right-0px mt-16px md:mt-28px"
                    size={10}
                    lineHeight={13}
                >
                    Request ID: {calculatorState.data?.requestId}
                </PrimaryText>
            )}

            {reimbursementCalculations ? (
                <div
                    className="
                        mt-36px flex
                        md:block md:mt-0px
                    "
                >
                    <CoverageBreakdownCardComponent
                        className="
                            mr-36px flex-1
                            md:mr-0px md:mt-48px
                        "
                        title="Phase 1"
                        subtitle="Start of the plan year"
                        reimbursementPerSessionMin={0}
                        bottomTitle={`$0-${
                            reimbursementCalculations.deductible !== null
                                ? UtilHelper.getFormattedMoney(
                                      reimbursementCalculations.deductible,
                                      true,
                                  )
                                : "Unknown"
                        } spent`}
                        highlighted={expenses.isPreDeductibleStage}
                    />

                    <CoverageBreakdownCardComponent
                        className="
                            mr-36px flex-1
                            md:mr-0px md:mt-24px
                        "
                        title="Phase 2"
                        subtitle="Deductible met"
                        reimbursementPerSessionMin={
                            reimbursementCalculations.deductibleRangeStart !==
                            null
                                ? reimbursementCalculations.deductibleRangeStart
                                : "Unknown"
                        }
                        reimbursementPerSessionMax={
                            reimbursementCalculations.deductibleRangeEnd !==
                            null
                                ? reimbursementCalculations.deductibleRangeEnd
                                : "Unknown"
                        }
                        bottomTitle={`After ${
                            reimbursementCalculations.deductible !== null
                                ? UtilHelper.getFormattedMoney(
                                      reimbursementCalculations.deductible,
                                      true,
                                  )
                                : "Unknown"
                        } spent`}
                        sessionsCount={
                            calculatorState.data
                                .remainingSessionsBeforeDeductible || 0
                        }
                        highlighted={expenses.isDeductibleMetStage}
                    />

                    <CoverageBreakdownCardComponent
                        className="
                            flex-1
                            md:mt-24px
                        "
                        title="Phase 3"
                        subtitle="Out-of-pocket max met"
                        reimbursementPerSessionMin={
                            reimbursementCalculations.oopMaxRangeStart !== null
                                ? reimbursementCalculations.oopMaxRangeStart
                                : "Unknown"
                        }
                        reimbursementPerSessionMax={
                            reimbursementCalculations.oopMaxRangeEnd !== null
                                ? reimbursementCalculations.oopMaxRangeEnd
                                : "Unknown"
                        }
                        bottomTitle={`After ${
                            reimbursementCalculations.oopMax !== null
                                ? UtilHelper.getFormattedMoney(
                                      reimbursementCalculations.oopMax,
                                      true,
                                  )
                                : "Unknown"
                        } spent`}
                        sessionsCount={
                            calculatorState.data.remainingOopMax
                                ? Math.ceil(
                                      (calculatorState.data.oopMax || 0) /
                                          ((calculatorState.data
                                              .remainingOopMax || 1) /
                                              (calculatorState.data
                                                  .remainingSessionsBeforeOopMax ||
                                                  1)),
                                  )
                                : 0
                        }
                        highlighted={expenses.isOopMaxMetStage}
                    />
                </div>
            ) : null}

            {/*SPENT INDICATOR*/}
            {typeof expenses.percentage !== "undefined" && (
                <div className="mt-36px relative">
                    <ProgressElement
                        showInfo={false}
                        percent={expenses.percentage}
                    />

                    <div className="flex items-center absolute bottom--35px left-0">
                        <PrimaryText
                            typography="text"
                            className="whitespace-nowrap"
                        >
                            {expenses.text}
                        </PrimaryText>

                        <TooltipElement
                            text={TOOLTIPS_CONFIG.coverageBreakdown.spentAmount}
                            className="ml-7px"
                        />
                    </div>
                </div>
            )}

            <PrimaryText className="mt-55px" typography="caption">
                A quote of benefits does not guarantee payment. Payment of
                benefits are subject to all terms, conditions, limitations, and
                exclusions of the member’s contract at the time of service.
            </PrimaryText>
        </div>
    ) : null
}
