export const TOOLTIPS_CONFIG = {
    coverageBreakdown: {
        howGotCalculations:
            "Your insurance company may have an <i>allowed amount</i> that they are willing to pay for each session. This number is based on prior claims we've filed with them. " +
            `Read more <a target="_blank" rel="noreferrer" href="https://medium.com/@nirvanahealth/small-allowance-why-your-insurer-paid-so-little-for-your-therapy-81309fb4a1c0">in this article.</a>`,
        spentAmount:
            "This reflects the amount that your insurance has recognized in your medical expenses and may not reflect how much you've <i>actually</i> paid for care. <br /><br/> Discrepancies may be related to claim processing times, which can take up to 90 days, as well as insurers recognizing a lower amount (i.e.-commonly referred to as an allowed amount).",
    },
    calculator: {
        firstName: "Make sure your first name matches your insurance card",
        lastName: "Make sure your last name matches your insurance card",
        insuranceProvider:
            "Please select the closest match to the name of the insurer on your card.",
    },
    embedCalculator: {
        practiceNPIs:
            "If you do not have a group practice (or Type II NPI) please use your Individual NPI.",
        practiceTaxId:
            "We strongly recommend obtaining an EIN for security purposes.",
    },
}
