export enum AvailableSessionRatesState {
    input = "input",
    inputHiding = "inputHiding",
    result = "result",
    resultHiding = "resultHiding",
}

export const SESSION_RATES_CONFIG = {
    averageNirvanaReimbursement: 43 * 100, // in cents

    defaultCancellationRate: 0.15, // in %, so 0.15 is 15%
    nirvanaCancellationRate: 0.05, // 5%

    rateHighlightRangeLimitValue: 50 * 100, // +-50$ in cents

    // Thresholds used for resulting calculations
    incomeTargets: [
        0, // breakeven
        50 * 1000 * 100,
        70 * 1000 * 100,
        90 * 1000 * 100,
        100 * 1000 * 100,
        125 * 1000 * 100,
        150 * 1000 * 100,
    ], // in cents per year

    availableOperationalExpenses: [
        {
            key: "officeLease",
            checkboxLabel: "Do you have an office lease?",
            hasMoneyInput: true,
            moneyInputLabel: "How much do you pay?",
            defaultMoneyValue: 4000,
        },
        {
            key: "clinicians",
            checkboxLabel: "Do you have clinical staff?",

            hasAmountInput: true,
            amountInputLabel: "How many clinical staff do you have?",
            defaultAmountValue: 1,

            hasMoneyInput: true,
            moneyInputLabel: "How much do you pay them on average?",
            defaultMoneyValue: 50 * 1000,
            isYearlyMoney: true,
        },
        {
            key: "adminEmployee",
            checkboxLabel: "Do you have an admin to take in appointments?",

            hasMoneyInput: true,
            moneyInputLabel: "How much do you pay them on average?",
            defaultMoneyValue: 50 * 1000,
            isYearlyMoney: true,
        },
        {
            key: "additionalExpenses",
            checkboxLabel: "Do you have any additional operational expenses?",

            hasMoneyInput: true,
            defaultMoneyValue: 0,
        },
    ] as ISessionRateExpenseOption[],

    availableSoftwareExpenses: [
        {
            key: "simplePractice",
            checkboxLabel: "SimplePractice",

            hasMoneyInput: true,
            defaultMoneyValue: 129,
        },
        {
            key: "quickBooks",
            checkboxLabel: "Quickbooks",

            hasMoneyInput: true,
            defaultMoneyValue: 49,
        },
        {
            key: "otherEhr",
            checkboxLabel: "Other EHR",

            hasMoneyInput: true,
        },
        {
            key: "telehealthSoftware",
            checkboxLabel: "Telehealth software",

            hasMoneyInput: true,
            defaultMoneyValue: 20,
        },
        {
            key: "marketing",
            checkboxLabel: "Marketing/Advertising",

            hasMoneyInput: true,
            defaultMoneyValue: 149,
        },
        {
            key: "website",
            checkboxLabel: "Website (Wix/Squarespace)",

            hasMoneyInput: true,
            defaultMoneyValue: 49,
        },
        {
            key: "additionalExpenses",
            checkboxLabel: "Do you have any additional software expenses?",

            hasMoneyInput: true,
        },
    ] as ISessionRateExpenseOption[],
}
