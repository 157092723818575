/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import moment from "moment"
import {
    NIRVANA_COLORS,
    SpinnerElement,
    useScrollFix,
    PageHelmetElement,
    PrimaryText,
    UtilHelper,
    ButtonElement,
} from "nirvana-react-elements"

import { ROUTES_CONFIG } from "../../config/routes.config"
import { embedCalculatorActionCreators } from "../../actions/embedCalculator.actions"
import { embedCalculatorSelector } from "../../selectors/embedCalculator.selector"
import { CalculatorCheckoutHelper } from "../../helpers/calculatorCheckout.helper"
import { GENERAL_CONFIG } from "../../config/general.config"
import { runtimeSelector } from "../../selectors/runtime.selector"
import { InviteTherapistPopupComponent } from "../popups/inviteTherapistPopup.component"
import { EmbedCalculatorEditTherapistsPopupComponent } from "../popups/embedCalculatorEditTherapistsPopup.component"
import { PracticeSetupPromoComponent } from "./practiceSetupPromo.component"
import { AvailableTaxIdType } from "../../config/calculatorCheckout.config"

import editIcon from "../../assets/images/icons/edit-dark.svg"

export const EmbedCalculatorManagementComponent: React.FunctionComponent =
    () => {
        useScrollFix()

        const dispatch = useDispatch()
        const navigate = useNavigate()

        const { managementKey } = useParams()

        const runtimeState = useSelector(runtimeSelector)
        const embedCalculatorState = useSelector(embedCalculatorSelector)

        const fullEmbedLink = useMemo<string>(
            () =>
                `${UtilHelper.getCurrentAppDomain()}/${
                    embedCalculatorState.checkedEmbedKey?.embedKey
                }`,
            [embedCalculatorState.checkedEmbedKey?.embedKey],
        )

        const checkoutSuccessUrl = useMemo<string>(
            () =>
                `${UtilHelper.getCurrentAppDomain()}${ROUTES_CONFIG.calculatorCheckoutSuccessUrl.replace(
                    ":embedKey",
                    embedCalculatorState.checkedEmbedKey?.embedKey || "",
                )}`,
            [embedCalculatorState.checkedEmbedKey?.embedKey],
        )

        const clientEnrollmentUrl = useMemo<string>(
            () =>
                `${UtilHelper.getCurrentAppDomain()}${ROUTES_CONFIG.embedCalculatorEnrollmentUrl.replace(
                    ":embedKey",
                    embedCalculatorState.checkedEmbedKey?.embedKey || "",
                )}`,
            [embedCalculatorState.checkedEmbedKey?.embedKey],
        )

        const [managementKeyChecked, setManagementKeyChecked] =
            useState<boolean>(false)

        const [isTherapistInviteActive, setIsTherapistInviteActive] =
            useState<boolean>(false)

        const [isEditTherapistsActive, setIsEditTherapistsActive] =
            useState<boolean>(false)

        const isSubscriptionGracePeriod = useMemo<boolean>(() => {
            return (
                !!embedCalculatorState.checkedEmbedKey?.planValidUntil &&
                !!embedCalculatorState.checkedEmbedKey.isValidSubscription &&
                embedCalculatorState.checkedEmbedKey.planValidUntil <
                    new Date().getTime()
            )
        }, [embedCalculatorState.checkedEmbedKey])

        // Set in state our embed key
        useEffect(() => {
            if (!managementKey) {
                UtilHelper.redirectTo(
                    ROUTES_CONFIG.embedCalculatorManagementLoginUrl,
                    navigate,
                )

                return
            }

            dispatch(
                embedCalculatorActionCreators.checkManagementKey(
                    managementKey,
                    () => setManagementKeyChecked(true),
                    navigate,
                ),
            )
        }, [managementKey])

        const onLoginStripeCustomerPortal = () => {
            if (!embedCalculatorState.managementKey) {
                return
            }

            dispatch(
                embedCalculatorActionCreators.loginStripeCustomerPortal(
                    embedCalculatorState.managementKey,
                    navigate,
                ),
            )
        }

        return (
            <div className="w-full pb-75px">
                <div className="primary-container">
                    <PageHelmetElement
                        title="Customer Portal"
                        defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
                    />

                    <div className="flex items-start">
                        <div className="flex-1 mr-20px">
                            <PrimaryText typography="h1">
                                Calculator Customer Portal
                            </PrimaryText>

                            <PrimaryText className="mt-8px">
                                Manage your subscription easily.
                            </PrimaryText>
                        </div>
                    </div>

                    {managementKeyChecked &&
                    embedCalculatorState.checkedEmbedKey ? (
                        <>
                            <div
                                className="
                                    mt-32px min-h-130px p-16px border border-solid border-brand-warmShadow rounded-8px
                                "
                            >
                                {/*DETAILS*/}
                                <div className="flex items-start md:block">
                                    <div className="flex-1 mr-50px md:mr-0px">
                                        <PrimaryText typography="h4">
                                            Contact Details
                                        </PrimaryText>

                                        <div className="mt-16px flex items-start sm:block">
                                            <PrimaryText className="mr-30px flex-shrink-0 w-155px sm:w-auto sm:mr-0px">
                                                Email Address
                                            </PrimaryText>

                                            <PrimaryText
                                                typography="textSemibold"
                                                className="sm:mt-8px"
                                            >
                                                {
                                                    embedCalculatorState
                                                        .checkedEmbedKey.email
                                                }
                                            </PrimaryText>
                                        </div>
                                    </div>

                                    <div className="flex-1 md:mt-48px">
                                        <div className="flex items-center">
                                            <PrimaryText
                                                typography="h4"
                                                className="mr-16px"
                                            >
                                                Subscription
                                            </PrimaryText>

                                            <ButtonElement
                                                label="Manage"
                                                type="default"
                                                htmlType="button"
                                                size="middle"
                                                isLoading={
                                                    !!runtimeState.isLoading
                                                        .length
                                                }
                                                icon={editIcon}
                                                onClick={
                                                    onLoginStripeCustomerPortal
                                                }
                                            />
                                        </div>

                                        <div className="flex items-start mt-16px sm:block">
                                            <PrimaryText className="mr-30px flex-shrink-0 w-155px sm:w-auto sm:mr-0px">
                                                Status
                                            </PrimaryText>

                                            <div className="flex items-center sm:mt-8px">
                                                {embedCalculatorState
                                                    .checkedEmbedKey
                                                    .isValidSubscription ? (
                                                    <>
                                                        <div className="w-8px h-8px rounded-full mr-8px bg-brand-vibrantPurple" />

                                                        <PrimaryText typography="textSemibold">
                                                            Active
                                                        </PrimaryText>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="w-8px h-8px rounded-full mr-8px bg-brand-error" />

                                                        <PrimaryText typography="textSemibold">
                                                            Inactive
                                                        </PrimaryText>
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        <div className="flex items-start mt-16px sm:block">
                                            <PrimaryText className="mr-30px flex-shrink-0 w-155px sm:w-auto sm:mr-0px">
                                                Price
                                            </PrimaryText>

                                            <PrimaryText
                                                typography="textSemibold"
                                                className="sm:mt-8px"
                                            >
                                                {UtilHelper.getFormattedMoney(
                                                    CalculatorCheckoutHelper.getSubscriptionGraduatedPricing(
                                                        embedCalculatorState
                                                            .checkedEmbedKey
                                                            .healthProviderRates
                                                            .length,
                                                    ),
                                                )}
                                                /month
                                            </PrimaryText>
                                        </div>

                                        {embedCalculatorState.checkedEmbedKey
                                            .planValidUntil ? (
                                            <div className="flex items-start mt-16px sm:block">
                                                <PrimaryText className="mr-30px flex-shrink-0 w-155px sm:w-auto sm:mr-0px">
                                                    Next Invoice
                                                </PrimaryText>

                                                <PrimaryText
                                                    typography="textSemibold"
                                                    className="sm:mt-8px"
                                                >
                                                    {moment(
                                                        embedCalculatorState
                                                            .checkedEmbedKey
                                                            .planValidUntil,
                                                    ).format(
                                                        GENERAL_CONFIG.defaultMomentDateFormat,
                                                    )}{" "}
                                                    {isSubscriptionGracePeriod ? (
                                                        <PrimaryText
                                                            typography="textSemibold"
                                                            color={
                                                                NIRVANA_COLORS
                                                                    .brand.error
                                                            }
                                                            className="inline-block"
                                                        >
                                                            (Payment Failed)
                                                        </PrimaryText>
                                                    ) : null}
                                                </PrimaryText>
                                            </div>
                                        ) : null}

                                        {isSubscriptionGracePeriod && (
                                            <PrimaryText className="mt-16px rounded-8px bg-brand-lilacLight p-16px">
                                                Your Nirvana subscription did
                                                not renew. Please check your{" "}
                                                <span
                                                    className="underline cursor-pointer text-semibold"
                                                    onClick={
                                                        onLoginStripeCustomerPortal
                                                    }
                                                >
                                                    credit card on file
                                                </span>{" "}
                                                or contact us at{" "}
                                                <a
                                                    href={`mailto:${GENERAL_CONFIG.supportEmail}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {
                                                        GENERAL_CONFIG.supportEmail
                                                    }
                                                </a>{" "}
                                                to avoid any disruption. We are
                                                happy to assist you and want to
                                                make sure that you are able to
                                                continue.{" "}
                                                {embedCalculatorState
                                                    .checkedEmbedKey
                                                    .gracePeriodUntil ? (
                                                    <>
                                                        {" "}
                                                        Your subscription will
                                                        be disabled on{" "}
                                                        <span className="text-semibold">
                                                            {moment(
                                                                embedCalculatorState
                                                                    .checkedEmbedKey
                                                                    .gracePeriodUntil,
                                                            ).format(
                                                                GENERAL_CONFIG.defaultMomentDateFormat,
                                                            )}
                                                            .
                                                        </span>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </PrimaryText>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/*OPTIONAL PRACTICE INFORMATION*/}
                            {embedCalculatorState.checkedEmbedKey.practice && (
                                <div
                                    className="
                                        mt-32px min-h-130px p-16px border border-solid border-brand-warmShadow rounded-8px
                                    "
                                >
                                    <PrimaryText typography="h4">
                                        Practice Details
                                    </PrimaryText>

                                    {/*INSTRUCTIONS HOW TO CHANGE INFO (for now not available from here)*/}
                                    <PrimaryText
                                        typography="caption"
                                        className="mt-4px"
                                    >
                                        Reach out to{" "}
                                        <a
                                            href={`mailto:${GENERAL_CONFIG.supportEmail}`}
                                        >
                                            {GENERAL_CONFIG.supportEmail}
                                        </a>{" "}
                                        to change your practice info
                                    </PrimaryText>

                                    <div className="mt-16px flex items-start sm:block">
                                        <PrimaryText className="mr-30px flex-shrink-0 w-155px sm:w-auto sm:mr-0px">
                                            Practice Legal Name
                                        </PrimaryText>

                                        <PrimaryText
                                            typography="textSemibold"
                                            className="sm:mt-8px"
                                        >
                                            {
                                                embedCalculatorState
                                                    .checkedEmbedKey.practice
                                                    .name
                                            }
                                        </PrimaryText>
                                    </div>

                                    <div className="mt-16px flex items-start sm:block">
                                        <PrimaryText className="mr-30px flex-shrink-0 w-155px sm:w-auto sm:mr-0px">
                                            Group NPI
                                        </PrimaryText>

                                        <PrimaryText
                                            typography="textSemibold"
                                            className="sm:mt-8px"
                                        >
                                            {
                                                embedCalculatorState
                                                    .checkedEmbedKey.practice
                                                    .groupNPI
                                            }
                                        </PrimaryText>
                                    </div>

                                    <div className="mt-16px flex items-start sm:block">
                                        <PrimaryText className="mr-30px flex-shrink-0 w-155px sm:w-auto sm:mr-0px">
                                            Tax ID (
                                            {embedCalculatorState
                                                .checkedEmbedKey.practice
                                                .taxIdType ===
                                            AvailableTaxIdType.SSN
                                                ? "SSN"
                                                : "EIN"}
                                            )
                                        </PrimaryText>

                                        <PrimaryText
                                            typography="textSemibold"
                                            className="sm:mt-8px"
                                        >
                                            {
                                                embedCalculatorState
                                                    .checkedEmbedKey.practice
                                                    .taxId
                                            }
                                        </PrimaryText>
                                    </div>

                                    <div className="mt-16px flex items-start sm:block">
                                        <PrimaryText className="mr-30px flex-shrink-0 w-155px sm:w-auto sm:mr-0px">
                                            Practice Address
                                        </PrimaryText>

                                        <PrimaryText
                                            typography="textSemibold"
                                            className="sm:mt-8px"
                                        >
                                            {UtilHelper.getFormattedAddress(
                                                embedCalculatorState
                                                    .checkedEmbedKey.practice
                                                    ?.address || null,
                                            )}
                                        </PrimaryText>
                                    </div>

                                    <div
                                        className={`
                                            mt-24px w-full rounded-8px p-16px
                                            ${
                                                embedCalculatorState
                                                    .checkedEmbedKey.practice
                                                    .isSuperbillParsingStructureSupported
                                                    ? "bg-brand-warmLight"
                                                    : "bg-brand-lilacLight"
                                            }
                                        `}
                                    >
                                        <PrimaryText>
                                            {!embedCalculatorState
                                                .checkedEmbedKey.practice
                                                .isSuperbillParsingStructureSet ? (
                                                <>
                                                    Your practice is not fully
                                                    onboarded. Please send
                                                    example of your superbill to{" "}
                                                    <a
                                                        href={`mailto:${GENERAL_CONFIG.supportEmail}`}
                                                    >
                                                        {
                                                            GENERAL_CONFIG.supportEmail
                                                        }
                                                    </a>{" "}
                                                    to finalize onboarding
                                                </>
                                            ) : embedCalculatorState
                                                  .checkedEmbedKey.practice
                                                  .isSuperbillParsingStructureSupported ? (
                                                <>
                                                    Share{" "}
                                                    <a
                                                        href={
                                                            clientEnrollmentUrl
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        this link
                                                    </a>{" "}
                                                    with your clients to enroll
                                                    them into automatic claims
                                                    filing
                                                </>
                                            ) : (
                                                <>
                                                    Unfortunately we do not
                                                    support your EHR at this
                                                    time. <br /> You are on the
                                                    waiting list and we'll
                                                    notify you once your EHR is
                                                    supported by our system.
                                                    Please feel free to{" "}
                                                    <a
                                                        href={`mailto:${GENERAL_CONFIG.supportEmail}`}
                                                    >
                                                        contact Nirvana
                                                    </a>{" "}
                                                    for any questions
                                                </>
                                            )}
                                        </PrimaryText>
                                    </div>
                                </div>
                            )}

                            {/*CTA for creating practice for ORCA CUSTOMER*/}
                            {/*WILL appear only if allowed and no practice yet created*/}
                            <PracticeSetupPromoComponent />

                            <div
                                className="
                                    mt-32px min-h-130px p-16px border border-solid border-brand-warmShadow rounded-8px
                                "
                            >
                                <div className="flex items-center mb-24px">
                                    <PrimaryText
                                        typography="h4"
                                        className="mr-16px"
                                    >
                                        Therapists
                                    </PrimaryText>

                                    <ButtonElement
                                        label="Manage"
                                        type="default"
                                        htmlType="button"
                                        size="middle"
                                        icon={editIcon}
                                        onClick={() =>
                                            setIsEditTherapistsActive(true)
                                        }
                                    />
                                </div>

                                {embedCalculatorState.checkedEmbedKey.healthProviderRates.map(
                                    (
                                        item: IHealthProviderRate,
                                        index: number,
                                    ) => (
                                        <div
                                            key={index}
                                            className="
                                                flex py-16px mt-8px
                                                md:block md:px-0px md:mt-16px
                                            "
                                        >
                                            <PrimaryText
                                                typography="textSemibold"
                                                className="w-300px mr-8px md:w-full"
                                            >
                                                {item.healthProvider
                                                    .preferredName ||
                                                    item.healthProvider.name}
                                            </PrimaryText>

                                            <div className="flex-1 flex items-center flex-wrap">
                                                {item.rates.map(
                                                    (rate, rateIndex) => (
                                                        <div
                                                            key={rateIndex}
                                                            className="p-16px mr-8px my-8px rounded-8px bg-brand-lilacLight"
                                                        >
                                                            <PrimaryText>
                                                                <span className="text-semibold">
                                                                    {
                                                                        rate
                                                                            .cptCode
                                                                            .displayValue
                                                                    }{" "}
                                                                    (
                                                                    {
                                                                        rate
                                                                            .cptCode
                                                                            .value
                                                                    }
                                                                    )
                                                                </span>
                                                            </PrimaryText>

                                                            <PrimaryText className="mt-4px">
                                                                <span>
                                                                    Session
                                                                    Rate:
                                                                </span>{" "}
                                                                <span className="text-semibold">
                                                                    {UtilHelper.getFormattedMoney(
                                                                        rate.sessionRate,
                                                                        true,
                                                                    )}
                                                                </span>
                                                            </PrimaryText>
                                                        </div>
                                                    ),
                                                )}
                                            </div>
                                        </div>
                                    ),
                                )}
                            </div>

                            <div className="mt-32px w-full bg-brand-whitePurple rounded-8px p-16px">
                                <PrimaryText className="overflow-ellipsis overflow-hidden">
                                    Get your unique HTML code you’ll need to
                                    embed our Calculator in your website by
                                    clicking{" "}
                                    <a
                                        href={checkoutSuccessUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        this link
                                    </a>
                                </PrimaryText>
                            </div>

                            <div className="mt-24px w-full bg-brand-warmLight rounded-8px p-16px">
                                <PrimaryText className="overflow-ellipsis overflow-hidden">
                                    See how your personalized Calculator will
                                    look like by clicking{" "}
                                    <a
                                        href={fullEmbedLink}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        this link
                                    </a>
                                </PrimaryText>
                            </div>

                            <InviteTherapistPopupComponent
                                isActive={isTherapistInviteActive}
                                onDone={() => setIsTherapistInviteActive(false)}
                                closeOnOutsideClick
                                increasedZIndex
                            />

                            {isEditTherapistsActive ? (
                                <EmbedCalculatorEditTherapistsPopupComponent
                                    isActive={true}
                                    onDone={() =>
                                        setIsEditTherapistsActive(false)
                                    }
                                    onInviteTherapist={() =>
                                        setIsTherapistInviteActive(true)
                                    }
                                />
                            ) : null}
                        </>
                    ) : (
                        <SpinnerElement containerClassName="mt-75px mx-auto text-center" />
                    )}
                </div>
            </div>
        )
    }
