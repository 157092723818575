/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"

import { GENERAL_CONFIG } from "../../config/general.config"

import logo from "../../assets/images/logo.svg"

export const IntakeDemoHeaderComponent: React.FunctionComponent = () => {
    return (
        <div className="relative flex items-center justify-between px-24px py-24px mb-24px">
            {/*BACKGROUND*/}
            <div className="w-screen h-screen fixed -z-10 left-0 top-0 bg-brand-offWhite" />

            {/*LOGO*/}
            <a
                href={GENERAL_CONFIG.mainWebsiteUrl}
                rel="noopener noreferrer"
                target="_blank"
                className="mr-38px"
            >
                <img
                    src={logo}
                    width={134}
                    alt="Nirvana Mental Health"
                    title="Nirvana Mental Health"
                />
            </a>
        </div>
    )
}
