/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useScrollFix } from "nirvana-react-elements"

import { ROUTES_CONFIG } from "../../config/routes.config"
import { ToastrHelper } from "../../helpers/toastr.helper"
import { GENERAL_CONFIG } from "../../config/general.config"
import { AnalyticsHelper } from "../../helpers/analytics.helper"
import { AnalyticsEvent } from "../../config/analytics.config"

export const CalculatorCheckoutFailedComponent: React.FunctionComponent =
    () => {
        useScrollFix()

        const navigate = useNavigate()

        // Show error message and redirect to main page
        // Also record event in analytics
        useEffect(() => {
            AnalyticsHelper.event(AnalyticsEvent.checkoutFailed)

            ToastrHelper.error(
                `Sorry, but we couldn't confirm your payment. Please try again or contact <a href='mailto:${GENERAL_CONFIG.supportEmail}' target='_blank' rel='noopener noreferrer'>${GENERAL_CONFIG.supportEmail}</a> for assistance`,
                GENERAL_CONFIG.extendedToastrTimeout,
            )

            navigate(
                ROUTES_CONFIG.calculatorUrl +
                    `?${GENERAL_CONFIG.urlSearchParamsKeys.purchase}=1`,
            )
        }, [])

        return null
    }
