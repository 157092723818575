/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react"
import {
    ButtonElement,
    InputElement,
    PrimaryText,
    VALIDATION_CONFIG,
} from "nirvana-react-elements"
import { useForm } from "react-hook-form"

export const IntakeDemoFailureComponent: React.FunctionComponent = () => {
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm()

    const [followUpPhoneNumber, setFollowUpPhoneNumber] = useState<string>()

    const onPhoneNumberSubmit = (data: any) => {
        setFollowUpPhoneNumber(data.phoneNumber)
    }

    return (
        <div className="relative">
            <PrimaryText typography="h3">
                Unfortunately, we <br /> couldn’t verify your <br /> plan.
            </PrimaryText>

            {followUpPhoneNumber ? (
                <>
                    <PrimaryText className="mt-24px">Thanks.</PrimaryText>

                    <PrimaryText className="mt-24px">
                        Someone from our team will text you at{" "}
                        <span className="text-bold">{followUpPhoneNumber}</span>{" "}
                        in the next 24 hours.
                    </PrimaryText>
                </>
            ) : (
                <>
                    <PrimaryText className="mt-24px">
                        A request has been automatically sent to our team. We
                        can send you a text to follow up and confirm your
                        eligibility.
                    </PrimaryText>

                    <form onSubmit={handleSubmit(onPhoneNumberSubmit)}>
                        <InputElement
                            className="mt-44px"
                            label="Your phone number"
                            name="phoneNumber"
                            mask="000-000-0000"
                            placeholder="123-123-1234"
                            maskOptions={{
                                lazy: true,
                            }}
                            reactHookControl={control}
                            reactHookValidations={{
                                required: VALIDATION_CONFIG.required,
                                pattern: VALIDATION_CONFIG.usPhoneNumber,
                            }}
                            reactHookErrors={errors}
                        />

                        <div className="fixed z-10 left-0 bottom-0 p-24px w-full bg-brand-offWhite">
                            <ButtonElement
                                label="Send me a text follow-up"
                                type="primary"
                                size="large"
                                htmlType="submit"
                                buttonClassName="w-full"
                            />
                        </div>
                    </form>
                </>
            )}
        </div>
    )
}
