import { CalculatorActions } from "../actions/calculator.actions"
import { CalculatorStateClass } from "../classes/calculatorState.class"

export const calculatorReducer = (
    state = new CalculatorStateClass(),
    action: ISagaAction,
): ICalculatorState => {
    switch (action.type) {
        case CalculatorActions.CALCULATION_DATA_SET:
            return {
                ...state,
                data: action.payload,
            }

        case CalculatorActions.CALCULATOR_PROCESSED_SET:
            return {
                ...state,
                isProcessed: !!action.payload,
            }

        case CalculatorActions.CALCULATION_DATA_RESET:
            return {
                ...state,
                data: null,
            }

        case CalculatorActions.CALCULATION_INPUT_DATA_SET:
            return {
                ...state,
                inputData: action.payload.inputData,
            }

        case CalculatorActions.CALCULATOR_MANUAL_ELIGIBILITY_DATA_SET:
            return {
                ...state,
                manualEligibilityData: action.payload,
            }

        default:
            return state
    }
}
