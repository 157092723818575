import React from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import {
    IPopupProps,
    PopupWrapperElement,
    PrimaryText,
    VALIDATION_CONFIG,
    ButtonElement,
    InputElement,
} from "nirvana-react-elements"

import { runtimeSelector } from "../../selectors/runtime.selector"
import { calculatorActionCreators } from "../../actions/calculator.actions"

import closeIcon from "../../assets/images/icons/close-dark.svg"
import mailIcon from "../../assets/images/icons/mail-dark.svg"
import sendWhiteIcon from "../../assets/images/icons/send-white.svg"

export const InviteTherapistPopupComponent: React.FunctionComponent<
    IPopupProps
> = props => {
    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm()

    const dispatch = useDispatch()
    const runtimeState = useSelector(runtimeSelector)

    const inviteTherapist = (data: { email: string } | any) => {
        dispatch(
            calculatorActionCreators.inviteTherapist(data.email, () => {
                props.onDone()
            }),
        )
    }

    return (
        <PopupWrapperElement
            isActive={props.isActive}
            className={props.className}
            onDone={props.onDone}
            closeOnOutsideClick={props.closeOnOutsideClick}
        >
            <div
                className="
                    absolute right-30px top-30px cursor-pointer
                    md:right-15px md:top-15px
                "
                onClick={props.onDone}
            >
                <img src={closeIcon} alt="Close" title="Close" />
            </div>

            <div className="relative mt-24px">
                <PrimaryText typography="h5">
                    Unfortunately, Nirvana does not yet work with this therapist
                </PrimaryText>

                <PrimaryText typography="text" className="mt-16px">
                    Nirvana partners with therapists to help you navigate the
                    insurance claims process and get reimbursed for your care.
                    Enter the therapist's email address below and we will
                    contact them to find out if they can work with you.
                </PrimaryText>

                <form
                    onSubmit={handleSubmit(inviteTherapist)}
                    noValidate={true}
                >
                    <InputElement
                        className="mt-48px"
                        name="email"
                        label="Therapist’s email"
                        reactHookControl={control}
                        reactHookErrors={errors}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                            pattern: VALIDATION_CONFIG.email,
                            maxLength: VALIDATION_CONFIG.maxLength,
                        }}
                        inputSuffix={
                            <img
                                src={mailIcon}
                                alt="Email address"
                                title="Email address"
                                className="ml-8px"
                            />
                        }
                    />

                    <div className="flex mt-32px">
                        <div className="flex-1" />

                        <ButtonElement
                            label="Contact therapist"
                            type="primary"
                            htmlType="submit"
                            size="large"
                            icon={sendWhiteIcon}
                            isLoading={!!runtimeState.isLoading.length}
                        />
                    </div>
                </form>
            </div>
        </PopupWrapperElement>
    )
}
