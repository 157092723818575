import { IntakeDemoFlow } from "../config/intakeDemo.config"

export enum IntakeDemoActions {
    INTAKE_DEMO_AUTH_RESET = "INTAKE_DEMO_AUTH_RESET",
    INTAKE_DEMO_AUTH_START = "INTAKE_DEMO_AUTH_START",
    INTAKE_DEMO_AUTH_SUCCESS = "INTAKE_DEMO_AUTH_SUCCESS",
    INTAKE_DEMO_SET_CURRENT_FLOW = "INTAKE_DEMO_SET_CURRENT_FLOW",
    INTAKE_DEMO_GET_COVERAGE = "INTAKE_DEMO_GET_COVERAGE",
    INTAKE_DEMO_SET_COVERAGE = "INTAKE_DEMO_SET_COVERAGE",
}

export const intakeDemoActionCreators = {
    authReset: (): ISagaAction => {
        return {
            type: IntakeDemoActions.INTAKE_DEMO_AUTH_RESET,
        }
    },

    authStart: (authToken: string): ISagaAction => {
        return {
            type: IntakeDemoActions.INTAKE_DEMO_AUTH_START,
            payload: {
                authToken,
            },
        }
    },

    authSuccess: (authToken: string): ISagaAction => {
        return {
            type: IntakeDemoActions.INTAKE_DEMO_AUTH_SUCCESS,
            payload: {
                authToken,
            },
        }
    },

    setCurrentFlow: (currentFlow: IntakeDemoFlow): ISagaAction => {
        return {
            type: IntakeDemoActions.INTAKE_DEMO_SET_CURRENT_FLOW,
            payload: {
                currentFlow,
            },
        }
    },

    getCoverage: (
        initialData: IGetCoverageInitialData,
        payerData?: IGetCoveragePayerData,
    ): ISagaAction => {
        return {
            type: IntakeDemoActions.INTAKE_DEMO_GET_COVERAGE,
            payload: {
                initialData,
                payerData,
            },
        }
    },

    setCoverage: (result: ICalculationsResult): ISagaAction => {
        return {
            type: IntakeDemoActions.INTAKE_DEMO_SET_COVERAGE,
            payload: {
                result,
            },
        }
    },
}
