import React from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import {
    IPopupProps,
    PopupWrapperElement,
    PrimaryText,
    VALIDATION_CONFIG,
    UtilHelper,
    ButtonElement,
    InputElement,
} from "nirvana-react-elements"

import { runtimeSelector } from "../../selectors/runtime.selector"
import { calculatorActionCreators } from "../../actions/calculator.actions"
import { runtimeActionCreators } from "../../actions/runtime.actions"
import { PdfHelper } from "../../helpers/pdf.helper"
import { GENERAL_CONFIG } from "../../config/general.config"

import closeIcon from "../../assets/images/icons/close-dark.svg"
import mailIcon from "../../assets/images/icons/mail-dark.svg"
import sendWhiteIcon from "../../assets/images/icons/send-white.svg"

export const EmailPdfResultsPopupComponent: React.FunctionComponent<
    IPopupProps
> = props => {
    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm()

    const dispatch = useDispatch()
    const runtimeState = useSelector(runtimeSelector)

    const onEmailPdf = async (data: { email: string } | any) => {
        const stopLoading = () => {
            dispatch(runtimeActionCreators.setIsPDFGenerating(false))
        }

        try {
            dispatch(runtimeActionCreators.setIsPDFGenerating(true))

            // So state updated, components rerendered and animations are done
            await UtilHelper.sleep(3)

            const generatedPdf = await PdfHelper.getPagePdf()

            dispatch(
                calculatorActionCreators.emailResults(
                    data.email,
                    PdfHelper.jsPdfToFileToUpload(
                        generatedPdf,
                        "nirvana-session-rates-result",
                    ),
                    () => {
                        stopLoading()

                        props.onDone()
                    },
                    stopLoading,
                ),
            )
        } catch (e) {
            stopLoading()
        }
    }

    return (
        <PopupWrapperElement
            isActive={props.isActive}
            className={`
                ${GENERAL_CONFIG.pdfGenerationIgnoreClassName}
                ${props.className}
            `}
            onDone={props.onDone}
            closeOnOutsideClick={props.closeOnOutsideClick}
        >
            <div
                className="
                    absolute right-30px top-30px cursor-pointer
                    md:right-15px md:top-15px
                "
                onClick={props.onDone}
            >
                <img src={closeIcon} alt="Close" title="Close" />
            </div>

            <div
                className="
                    relative mt-20px
                    md:mt-10px
                "
            >
                <PrimaryText typography="h3">Email your results</PrimaryText>

                <PrimaryText typography="text" className="mt-16px">
                    Please provide your email and we will send you results with
                    your calculations shortly.
                </PrimaryText>

                <form onSubmit={handleSubmit(onEmailPdf)} noValidate={true}>
                    <InputElement
                        className="mt-40px"
                        name="email"
                        label="Your email address"
                        reactHookControl={control}
                        reactHookErrors={errors}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                            pattern: VALIDATION_CONFIG.email,
                            maxLength: VALIDATION_CONFIG.maxLength,
                        }}
                        inputSuffix={
                            <img
                                src={mailIcon}
                                alt="Email address"
                                title="Email address"
                                className="ml-8px"
                            />
                        }
                    />

                    <div className="flex mt-32px">
                        <div className="flex-1" />

                        <ButtonElement
                            label="Send results"
                            type="primary"
                            htmlType="submit"
                            size="large"
                            icon={sendWhiteIcon}
                            isLoading={
                                !!runtimeState.isLoading.length ||
                                runtimeState.isGeneratingPdf
                            }
                        />
                    </div>
                </form>
            </div>
        </PopupWrapperElement>
    )
}
