import { ISelectRenderedOption } from "nirvana-react-elements"

import stethoscopeIcon from "../assets/images/intakeDemo/stethoscope.svg"
import heartIcon from "../assets/images/intakeDemo/heart.svg"
import questionIcon from "../assets/images/intakeDemo/question.svg"

export enum IntakeDemoFlow {
    initialData = "initialData",
    payerData = "payerData",
    success = "success",
    failure = "failure",
}

export const INTAKE_DEMO_CONFIG = {
    availableReasons: [
        {
            displayValue: "Primary Care",
            value: "primaryCare",
            prefixIcon: stethoscopeIcon,
        },
        {
            displayValue: "Therapy",
            value: "therapy",
            prefixIcon: heartIcon,
        },
        {
            displayValue: "I’m not sure",
            value: "notSure",
            prefixIcon: questionIcon,
        },
    ] as ISelectRenderedOption[],
}
