/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import {
    PrimaryText,
    VALIDATION_CONFIG,
    UtilHelper,
    ButtonElement,
    InputElement,
} from "nirvana-react-elements"

import { SESSION_RATES_CONFIG } from "../../config/sessionRates.config"
import { LookupService } from "../../services/lookup.service"
import { ToastrHelper } from "../../helpers/toastr.helper"
import { GENERAL_CONFIG } from "../../config/general.config"

import refreshIcon from "../../assets/images/icons/refresh-white.svg"
import searchIcon from "../../assets/images/icons/search-white.svg"

export const SessionRatesResultComponent: React.FunctionComponent<{
    className?: string
    inputData?: ISessionRatesInputData
    onReset: () => void
}> = props => {
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
    } = useForm()

    const [zipSessionRate, setZipSessionRate] = useState<number>()

    const zipSessionRateRange = useMemo<{
        min: number
        max: number
    } | null>(() => {
        if (!zipSessionRate) {
            return null
        }

        return {
            min: Math.max(
                0,
                zipSessionRate -
                    SESSION_RATES_CONFIG.rateHighlightRangeLimitValue,
            ),
            max:
                zipSessionRate +
                SESSION_RATES_CONFIG.rateHighlightRangeLimitValue,
        }
    }, [zipSessionRate])

    // On page load get zip session rate based on zip that we'll identify by IP
    useEffect(() => {
        searchZipSessionRate()
    }, [])

    // Returns in cents
    const getCalculatedSessionRate = (
        incomeTarget: number,
        withNirvana = false,
    ): {
        value: number
        highlighted?: boolean
    } => {
        if (!props.inputData) {
            return {
                value: 0,
            }
        }

        // Based on https://docs.google.com/spreadsheets/d/14Pyv4MGRmAb1hVv79zRsrIXgp5Y2L6D08pD5JlOIw2Y/edit#gid=0
        // But formula was fixed based on equation:
        // targetPerYear / 12 = sessionsPerWeek * 4 * cliniciansAmount * (1 - cancellationRate) * X + monthlyExpenses

        const result = !withNirvana
            ? Math.abs(
                  Math.floor(incomeTarget / 12) -
                      props.inputData.monthlyExpenses,
              ) /
              (props.inputData.sessionsPerWeek *
                  4 *
                  props.inputData.cliniciansAmount *
                  (1 - SESSION_RATES_CONFIG.defaultCancellationRate))
            : Math.abs(
                  Math.floor(incomeTarget / 12) -
                      props.inputData.monthlyExpenses,
              ) /
                  (props.inputData.sessionsPerWeek *
                      4 *
                      props.inputData.cliniciansAmount *
                      (1 - SESSION_RATES_CONFIG.nirvanaCancellationRate)) -
              SESSION_RATES_CONFIG.averageNirvanaReimbursement

        // Make sure it doesn't go below 0, min 1$
        const value = Math.max(Math.round(Math.round(result) / 100) * 100, 100)

        return {
            value,
            highlighted:
                !!zipSessionRateRange &&
                value >= zipSessionRateRange.min &&
                value <= zipSessionRateRange.max,
        }
    }

    const searchZipSessionRate = async (data?: { zip?: string }) => {
        const zipSessionRate = await LookupService.lookupSessionRateByZip(
            data?.zip,
        )

        if (!zipSessionRate?.averageSessionRate) {
            // IF they have explicitly submitted data, we'd like to show them error about missing zip in this case in our db
            if (data?.zip) {
                ToastrHelper.error(
                    "Sorry, but we couldn't identify average session rate in your area",
                )
            }

            return
        }

        setZipSessionRate(zipSessionRate.averageSessionRate)
        setValue("zip", zipSessionRate.zip)
    }

    return (
        <div
            className={`
                bg-brand-white rounded-12px
                ${props.className}
            `}
        >
            <div
                className="
                    mt-38px pt-22px pb-30px px-24px bg-brand-offWhite
                    min-h-130px rounded-8px
                    md:shadow-none md:rounded-none md:pt-30px md:mt-40px
                "
            >
                {props.inputData ? (
                    <>
                        <div className="flex items-start md:block">
                            <PrimaryText
                                className="
                                    flex-1 mr-20px
                                    md:mr-0px
                                "
                                typography="h3"
                            >
                                Your Results
                            </PrimaryText>

                            <div className="flex relative mt-12px md:mt-20px md:block">
                                <div className="mr-20px md:mr-0px">
                                    <InputElement
                                        isLabelStatic={true}
                                        name="zip"
                                        labelClassName="w-full"
                                        placeholder="01234"
                                        reactHookControl={control}
                                        reactHookValidations={{
                                            pattern: VALIDATION_CONFIG.zipShort,
                                        }}
                                        reactHookErrors={errors}
                                    />

                                    <PrimaryText
                                        className="mt-4px absolute left-0 bottom--36px"
                                        typography="caption"
                                    >
                                        Enter your zip code to see average
                                        sessions rates around where you practice
                                    </PrimaryText>
                                </div>

                                <div data-html2canvas-ignore="true">
                                    <ButtonElement
                                        label="Search"
                                        type="primary"
                                        size="large"
                                        htmlType="button"
                                        buttonClassName="md:w-full md:mt-20px"
                                        icon={searchIcon}
                                        onClick={handleSubmit(
                                            searchZipSessionRate,
                                        )}
                                    />
                                </div>
                            </div>
                        </div>

                        {/*HEADINGS*/}
                        <div
                            className="
                                mt-56px flex items-center py-16px border-b-1px border-solid border-brand-grey5
                                sticky top-0 bg-brand-grey2 z-10
                            "
                        >
                            <PrimaryText
                                typography="textBold"
                                className="flex-1 pl-20px mr-1px"
                            >
                                Income Target
                            </PrimaryText>

                            <PrimaryText
                                typography="textBold"
                                className="flex-1 pl-20px mr-1px"
                            >
                                Session Rate without Nirvana
                            </PrimaryText>

                            <PrimaryText
                                typography="textBold"
                                className="flex-1 pl-20px"
                            >
                                Session Rate with Nirvana
                            </PrimaryText>
                        </div>

                        {/*RESULTS*/}
                        {SESSION_RATES_CONFIG.incomeTargets.map(
                            (target, index) => {
                                const withoutNirvanaRate =
                                    getCalculatedSessionRate(target, false)

                                const withNirvanaRate =
                                    getCalculatedSessionRate(target, true)

                                return (
                                    <div
                                        key={index}
                                        className="
                                            flex items-center
                                            border-b border-solid border-brand-grey5
                                            hover:shadow-3
                                        "
                                    >
                                        <PrimaryText className="flex-1 py-18px pl-20px mr-1px">
                                            {!target
                                                ? "Breakeven"
                                                : UtilHelper.getFormattedMoney(
                                                      target,
                                                      true,
                                                  )}
                                        </PrimaryText>

                                        <PrimaryText
                                            className={`
                                                flex-1 py-18px pl-20px mr-1px
                                                ${
                                                    withoutNirvanaRate.highlighted
                                                        ? "bg-brand-primary"
                                                        : ""
                                                }
                                            `}
                                        >
                                            {UtilHelper.getFormattedMoney(
                                                withoutNirvanaRate.value,
                                                true,
                                            )}
                                        </PrimaryText>

                                        <PrimaryText
                                            className={`
                                                flex-1 py-18px pl-20px
                                                ${
                                                    withNirvanaRate.highlighted
                                                        ? "bg-brand-primary"
                                                        : ""
                                                }
                                            `}
                                        >
                                            {UtilHelper.getFormattedMoney(
                                                withNirvanaRate.value,
                                                true,
                                            )}
                                        </PrimaryText>
                                    </div>
                                )
                            },
                        )}
                    </>
                ) : null}
            </div>

            <footer
                className="
                    flex items-start mt-36px
                    md:block md:mt-40px md:px-30px
                "
            >
                <div className="flex-1 mr-25px md:mr-0px">
                    <PrimaryText>
                        {zipSessionRate ? (
                            <>
                                Average cost in your ZIP code:{" "}
                                <span className="text-bold">
                                    {UtilHelper.getFormattedMoney(
                                        zipSessionRate,
                                        true,
                                    )}
                                </span>
                                <br />
                            </>
                        ) : null}
                        Average reimbursement through Nirvana:{" "}
                        <span className="text-bold">
                            {UtilHelper.getFormattedMoney(
                                SESSION_RATES_CONFIG.averageNirvanaReimbursement,
                                true,
                            )}
                        </span>
                        <br />
                        <br />
                        Nirvana helps clients access care at a lower rate using
                        their out-of-network benefits. Interested in learning
                        more?{" "}
                        <a
                            href={GENERAL_CONFIG.mainWebsiteUrl}
                            rel="noopener noreferrer"
                            target="_blank"
                            className="underline md:block"
                        >
                            Click here
                        </a>
                    </PrimaryText>
                </div>

                <div data-html2canvas-ignore="true">
                    <ButtonElement
                        label="Reset"
                        type="primary"
                        size="large"
                        icon={refreshIcon}
                        htmlType="button"
                        buttonClassName="md:w-full md:mt-20px"
                        onClick={props.onReset}
                    />
                </div>
            </footer>
        </div>
    )
}
