/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { PrimaryText } from "nirvana-react-elements"

import { calculatorSelector } from "../../selectors/calculator.selector"
import {
    CalculatorResultType,
    GENERAL_CONFIG,
} from "../../config/general.config"

import recordWarningImg from "../../assets/images/icons/record-warning-dark.svg"

export const CalculatorErrorCardElement: React.FunctionComponent<{
    className?: string
}> = props => {
    const calculatorState = useSelector(calculatorSelector)

    const errorBreakdown: JSX.Element | null = useMemo(() => {
        if (!calculatorState.data?.resultType) {
            return null
        }

        const contactUsBlock: JSX.Element = (
            <>
                <br />
                <br />
                If you are still unable to get results, please{" "}
                <a
                    href={`mailto:${
                        GENERAL_CONFIG.supportEmail
                    }?subject=Error trying to use Nirvana calculator${
                        calculatorState.data.responseStatus
                            ? ` (Error Code ${calculatorState.data.responseStatus})`
                            : ""
                    }&body=%0D%0A%0D%0A%0D%0A%0D%0ARequest ID: ${
                        calculatorState.data.requestId || "Unknown"
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-bold"
                >
                    contact us.
                </a>
            </>
        )

        let title: string | undefined
        let description: JSX.Element | undefined

        switch (calculatorState.data.resultType) {
            case CalculatorResultType.activePlanCoverageUnknown:
                description = (
                    <>
                        The plan is Active, however Nirvana is unable to
                        determine if the plan covers OON mental health benefits.
                    </>
                )

                break

            case CalculatorResultType.unknownPlanStatus:
                description = (
                    <>
                        Nirvana is unable to determine if the plan is active or
                        covers OON mental health benefits at this time.
                    </>
                )

                break

            case CalculatorResultType.generalError:
                title = "Something went wrong while verifying your information."

                description = (
                    <>
                        Unfortunately, the cause of this error might be out of
                        our control. As a result, we could not verify your
                        eligibility for mental health benefits at this time.
                        {contactUsBlock}
                    </>
                )

                break

            case CalculatorResultType.memberNotFound:
                title =
                    "Please re-check that the information you entered is correct."

                description = (
                    <>
                        Unfortunately, we were unable to find your insurance
                        plan with the information provided.
                        {contactUsBlock}
                    </>
                )

                break

            case CalculatorResultType.connectionIssue:
                title =
                    "Unfortunately, the insurance plan is not responding at this time."

                description = (
                    <>
                        Please wait and retry Calculate again in a few minutes.
                        {contactUsBlock}
                    </>
                )

                break

            case CalculatorResultType.unknownDemographics:
                title = "Payer failure"

                description = (
                    <>
                        Unable to determine demographics. Provided DOB matches
                        more than one person.
                        {contactUsBlock}
                    </>
                )

                break

            case CalculatorResultType.payerNotSupported:
                title = "Payer not supported"

                description = (
                    <>
                        Nirvana does not support coverage requests for this
                        payer.
                        {contactUsBlock}
                    </>
                )

                break

            case CalculatorResultType.providerNotRecognized:
                title = "Provider not recognized"

                description = (
                    <>
                        The payer does not recognize the provider NPI - this may
                        be a credentialing issue.
                        {contactUsBlock}
                    </>
                )

                break

            case CalculatorResultType.errorRetrievingCoverage:
                title = "Error retrieving coverage"

                description = (
                    <>
                        Nirvana is unable to retrieve coverage information for
                        this plan. Please confirm that the payer and member
                        information are correct and resubmit.
                        {contactUsBlock}
                    </>
                )

                break

            case CalculatorResultType.memberIdPayerIdInvalid:
                title = "Member ID with a different payer"

                description = (
                    <>
                        Nirvana previously located this member ID with a
                        different payer. Please retry with a different payer.
                        {contactUsBlock}
                    </>
                )

                break

            case CalculatorResultType.planTypeNotSupported:
                title = "Plan type not supported"

                description = (
                    <>
                        Nirvana does not yet support cost-estimates on this
                        plan.
                        {contactUsBlock}
                    </>
                )

                break

            case CalculatorResultType.invalidInput:
                title = "Invalid data format"

                description = (
                    <>
                        An invalid data format was provided for a request
                        parameter or the parameter was omitted.
                        {contactUsBlock}
                    </>
                )

                break

            case CalculatorResultType.tooManyRequests:
                title = "Too Many Request"

                description = (
                    <>
                        Nirvana is experiencing a high volume of requests.
                        Please try again later.
                        {contactUsBlock}
                    </>
                )

                break

            case CalculatorResultType.payerFailure:
                title = "Payer failure"

                description = (
                    <>
                        The payer is currently experiencing downtime. Please try
                        again later and contact Nirvana if the error persists.
                        {contactUsBlock}
                    </>
                )

                break

            case CalculatorResultType.unexpectedError:
            default:
                title = "Oops! Something went wrong."

                description = (
                    <>
                        Please recheck your information and try again later.
                        {contactUsBlock}
                    </>
                )
        }

        return title || description ? (
            <div
                className="
                    w-580px pt-24px pb-32px px-28px rounded-8px bg-brand-lilacLight
                    border border-solid border-brand-lilac
                    md:w-full
                "
            >
                <div className="flex items-start">
                    <div className="mr-22px sm:hidden">
                        <img
                            src={recordWarningImg}
                            alt="inconclusive"
                            className="w-32px"
                        />
                    </div>

                    <div className="flex-1 relative">
                        {title && (
                            <PrimaryText typography="h4">{title}</PrimaryText>
                        )}

                        {description && (
                            <PrimaryText
                                className={title ? "mt-8px" : undefined}
                                typography="text"
                            >
                                {description}
                            </PrimaryText>
                        )}

                        {calculatorState.data?.requestId && (
                            <PrimaryText
                                className="absolute right-0px bottom--28px"
                                size={10}
                                lineHeight={13}
                            >
                                Request ID: {calculatorState.data?.requestId}
                            </PrimaryText>
                        )}
                    </div>
                </div>
            </div>
        ) : null
    }, [calculatorState.data?.resultType])

    return calculatorState.isProcessed && errorBreakdown ? (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            {errorBreakdown}
        </div>
    ) : null
}
