import { put, all, fork, takeLatest } from "redux-saga/effects"
import { call } from "typed-redux-saga"
import { UtilHelper } from "nirvana-react-elements"

import {
    embedCalculatorActionCreators,
    EmbedCalculatorActions,
} from "../actions/embedCalculator.actions"
import { runtimeActionCreators } from "../actions/runtime.actions"
import { EmbedCalculatorService } from "../services/embedCalculator.service"

////////////////////////////////////////////////////////////////////////////////////////// CHECK EMBED CALCULATOR KEY
function* checkEmbedCalculatorKeySaga() {
    yield takeLatest(
        EmbedCalculatorActions.EMBED_CALCULATOR_CHECK_KEY,
        checkEmbedCalculatorKeySagaHandler,
    )
}

function* checkEmbedCalculatorKeySagaHandler<
    T extends ISagaAction & {
        embedKey: string
    },
>(action: T) {
    yield put(
        runtimeActionCreators.startLoading(
            "checkEmbedCalculatorKeySagaHandler",
        ),
    )

    try {
        const result = yield* call(
            EmbedCalculatorService.checkEmbedKey,
            action.payload.embedKey,
        )

        if (result) {
            yield put(embedCalculatorActionCreators.setCheckedEmbedKey(result))

            action.onSuccess && action.onSuccess()
        } else {
            action.onError && action.onError()
        }
    } catch (e) {
    } finally {
        yield put(
            runtimeActionCreators.stopLoading(
                "checkEmbedCalculatorKeySagaHandler",
            ),
        )
    }
}
////////////////////////////////////////////////////////////////////////////////////////// CHECK EMBED CALCULATOR KEY

////////////////////////////////////////////////////////////////////////////////////////// LOGIN TO MANAGEMENT PORTAL
function* loginManagementPortalSaga() {
    yield takeLatest(
        EmbedCalculatorActions.EMBED_CALCULATOR_LOGIN_MANAGEMENT_PORTAL,
        loginManagementPortalSagaHandler,
    )
}

function* loginManagementPortalSagaHandler<
    T extends ISagaAction & {
        email: string
    },
>(action: T) {
    yield put(
        runtimeActionCreators.startLoading("loginManagementPortalSagaHandler"),
    )

    try {
        const result = yield* call(
            EmbedCalculatorService.loginManagementPortal,
            action.payload.email,
        )

        if (result) {
            action.onSuccess && action.onSuccess()
        } else {
            action.onError && action.onError()
        }
    } catch (e) {
    } finally {
        yield put(
            runtimeActionCreators.stopLoading(
                "loginManagementPortalSagaHandler",
            ),
        )
    }
}
////////////////////////////////////////////////////////////////////////////////////////// LOGIN TO MANAGEMENT PORTAL

////////////////////////////////////////////////////////////////////////////////////////// CHECK CALCULATOR MANAGEMENT KEY
function* checkCalculatorManagementKeySaga() {
    yield takeLatest(
        EmbedCalculatorActions.EMBED_CALCULATOR_CHECK_MANAGEMENT_KEY,
        checkCalculatorManagementKeySagaHandler,
    )
}

function* checkCalculatorManagementKeySagaHandler<
    T extends ISagaAction & {
        managementKey: string
    },
>(action: T) {
    yield put(
        runtimeActionCreators.startLoading(
            "checkCalculatorManagementKeySagaHandler",
        ),
    )

    try {
        const result = yield* call(
            EmbedCalculatorService.checkManagementKey,
            action.payload.managementKey,
            action.navigate,
        )

        if (result) {
            yield put(
                embedCalculatorActionCreators.setManagementKey(
                    action.payload.managementKey,
                ),
            )

            yield put(embedCalculatorActionCreators.setCheckedEmbedKey(result))

            action.onSuccess && action.onSuccess()
        } else {
            yield put(embedCalculatorActionCreators.setManagementKey(null))

            action.onError && action.onError()
        }
    } catch (e) {
        yield put(embedCalculatorActionCreators.setManagementKey(null))
    } finally {
        yield put(
            runtimeActionCreators.stopLoading(
                "checkCalculatorManagementKeySagaHandler",
            ),
        )
    }
}
////////////////////////////////////////////////////////////////////////////////////////// CHECK CALCULATOR MANAGEMENT KEY

////////////////////////////////////////////////////////////////////////////////////////// LOGIN TO STRIPE CUSTOMER PORTAL
function* createStripeCustomerPortalSessionSaga() {
    yield takeLatest(
        EmbedCalculatorActions.EMBED_CALCULATOR_NEW_STRIPE_CUSTOMER_PORTAL_SESSION,
        createStripeCustomerPortalSessionSagaHandler,
    )
}

function* createStripeCustomerPortalSessionSagaHandler<
    T extends ISagaAction & {
        managementKey: string
    },
>(action: T) {
    yield put(
        runtimeActionCreators.startLoading(
            "createStripeCustomerPortalSessionSagaHandler",
        ),
    )

    try {
        const result = yield* call(
            EmbedCalculatorService.createStripeCustomerPortalSession,
            action.payload.managementKey,
            action.navigate,
        )

        if (result) {
            UtilHelper.redirectTo(result.redirectUrl)

            action.onSuccess && action.onSuccess()
        } else {
            action.onError && action.onError()
        }
    } catch (e) {
    } finally {
        yield put(
            runtimeActionCreators.stopLoading(
                "createStripeCustomerPortalSessionSagaHandler",
            ),
        )
    }
}
////////////////////////////////////////////////////////////////////////////////////////// LOGIN TO STRIPE CUSTOMER PORTAL

////////////////////////////////////////////////////////////////////////////////////////// EDIT HEALTH PROVIDERS
function* editHealthProvidersSaga() {
    yield takeLatest(
        EmbedCalculatorActions.EMBED_CALCULATOR_EDIT_HEALTH_PROVIDERS,
        editHealthProvidersSagaHandler,
    )
}

function* editHealthProvidersSagaHandler<
    T extends ISagaAction & {
        managementKey: string
        therapistsData: ICalculatorCheckoutSingleTherapistData[]
    },
>(action: T) {
    yield put(
        runtimeActionCreators.startLoading("editHealthProvidersSagaHandler"),
    )

    try {
        const result = yield* call(
            EmbedCalculatorService.editHealthProviders,
            action.payload.managementKey,
            action.payload.therapistsData,
            action.navigate,
        )

        if (result) {
            yield put(embedCalculatorActionCreators.setCheckedEmbedKey(result))

            action.onSuccess && action.onSuccess()
        } else {
            action.onError && action.onError()
        }
    } catch (e) {
    } finally {
        yield put(
            runtimeActionCreators.stopLoading("editHealthProvidersSagaHandler"),
        )
    }
}
////////////////////////////////////////////////////////////////////////////////////////// EDIT HEALTH PROVIDERS

////////////////////////////////////////////////////////////////////////////////////////// CREATE PRACTICE
function* createEmbedCalculatorPracticeSaga() {
    yield takeLatest(
        EmbedCalculatorActions.EMBED_CALCULATOR_CREATE_PRACTICE,
        createEmbedCalculatorPracticeSagaHandler,
    )
}

function* createEmbedCalculatorPracticeSagaHandler<
    T extends ISagaAction & {
        managementKey: string
        practiceInfo: ICalculatorPracticeCreationDetailsData
    },
>(action: T) {
    yield put(
        runtimeActionCreators.startLoading(
            "createEmbedCalculatorPracticeSagaHandler",
        ),
    )

    try {
        const result = yield* call(
            EmbedCalculatorService.createPractice,
            action.payload.managementKey,
            action.payload.practiceInfo,
            action.navigate,
        )

        if (result) {
            yield put(embedCalculatorActionCreators.setCheckedEmbedKey(result))

            action.onSuccess && action.onSuccess()
        } else {
            action.onError && action.onError()
        }
    } catch (e) {
    } finally {
        yield put(
            runtimeActionCreators.stopLoading(
                "createEmbedCalculatorPracticeSagaHandler",
            ),
        )
    }
}
////////////////////////////////////////////////////////////////////////////////////////// CREATE PRACTICE

////////////////////////////////////////////////////////////////////////////////////////// ENROLL CLIENT INTO EMBED CALC PRACTICE
function* enrollClientIntoPracticeSaga() {
    yield takeLatest(
        EmbedCalculatorActions.EMBED_CALCULATOR_ENROLL_CLIENT,
        enrollClientIntoPracticeSagaHandler,
    )
}

function* enrollClientIntoPracticeSagaHandler<
    T extends ISagaAction & {
        embedKey: string
        data: IEmbedCalculatorClientEnrollmentData
    },
>(action: T) {
    yield put(
        runtimeActionCreators.startLoading(
            "enrollClientIntoPracticeSagaHandler",
        ),
    )

    try {
        const result = yield* call(
            EmbedCalculatorService.enrollClientToPractice,
            action.payload.embedKey,
            action.payload.data,
        )

        if (result) {
            action.onSuccess && action.onSuccess()
        } else {
            action.onError && action.onError()
        }
    } catch (e) {
    } finally {
        yield put(
            runtimeActionCreators.stopLoading(
                "enrollClientIntoPracticeSagaHandler",
            ),
        )
    }
}
////////////////////////////////////////////////////////////////////////////////////////// ENROLL CLIENT INTO EMBED CALC PRACTICE

export default function* rootSaga() {
    yield all([
        fork(checkEmbedCalculatorKeySaga),
        fork(loginManagementPortalSaga),
        fork(checkCalculatorManagementKeySaga),
        fork(createStripeCustomerPortalSessionSaga),
        fork(editHealthProvidersSaga),
        fork(createEmbedCalculatorPracticeSaga),
        fork(enrollClientIntoPracticeSaga),
    ])
}
