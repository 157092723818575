import React, { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import {
    NIRVANA_COLORS,
    useIsMobile,
    useScrollFix,
    PageHelmetElement,
    PrimaryText,
    NIRVANA_THEME,
    FaqElement,
} from "nirvana-react-elements"

import { calculatorSelector } from "../../../selectors/calculator.selector"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { AnalyticsHelper } from "../../../helpers/analytics.helper"
import { AnalyticsEvent } from "../../../config/analytics.config"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { FAQ_CONFIG } from "../../../config/faq.config"

import ineligibleImage from "../../../assets/images/icons/record-warning-dark.svg"

export const CalculatorIneligibleComponent: React.FunctionComponent = () => {
    useScrollFix()

    const navigate = useNavigate()

    const isMobile = useIsMobile()
    const isTablet = useIsMobile(NIRVANA_THEME.mediaQueries.md)

    const { embedKey } = useParams()

    const calculatorState = useSelector(calculatorSelector)

    useEffect(() => {
        if (!calculatorState.data) {
            navigate(
                embedKey
                    ? ROUTES_CONFIG.embedCalculatorUrl.replace(
                          ":embedKey",
                          embedKey,
                      )
                    : ROUTES_CONFIG.calculatorUrl,
            )
        } else {
            AnalyticsHelper.event(AnalyticsEvent.calculatorResultNotEligible)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="w-full mt-10px">
            <div className="primary-container pb-100px">
                <PageHelmetElement
                    title="Not eligible"
                    defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
                />

                <div
                    className="
                        mt-32px max-w-710px pt-24px pb-32px px-28px rounded-8px bg-brand-white
                        border border-solid border-brand-warmShadow
                        md:w-full
                    "
                >
                    <div className="flex items-center sm:block">
                        <div className="mr-16px sm:mr-0px">
                            <img
                                src={ineligibleImage}
                                alt="inconclusive"
                                className="sm:mx-auto"
                            />
                        </div>

                        <div className="flex-1 relative sm:mt-20px">
                            <PrimaryText centered={isMobile} typography="h4">
                                No mental health benefits found
                            </PrimaryText>

                            <PrimaryText
                                className="
                                    mt-8px
                                    sm:mt-12px
                                "
                                typography="text"
                                centered={isMobile}
                            >
                                Unfortunately, your plan does not cover mental
                                health benefits at this time.
                            </PrimaryText>

                            {calculatorState.data?.requestId && (
                                <PrimaryText
                                    className="absolute right-0px bottom--30px sm:inset-x-auto sm:w-full sm:text-center"
                                    size={10}
                                    lineHeight={13}
                                >
                                    Request ID:{" "}
                                    {calculatorState.data?.requestId}
                                </PrimaryText>
                            )}
                        </div>
                    </div>
                </div>

                <FaqElement
                    className="mt-48px"
                    isColumnLayout={!isTablet}
                    itemsWrapperClassName="mt-36px"
                    singleItemClassName="mb-36px"
                    questionTypography="textSemibold"
                    answerTypography="text"
                    answerColor={NIRVANA_COLORS.brand.primary}
                    items={FAQ_CONFIG.calculatorNoCoverage}
                />
            </div>
        </div>
    )
}
