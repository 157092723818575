import {
    BrowserStorageType,
    BrowserStorageHelper,
} from "nirvana-react-elements"

import { GENERAL_CONFIG } from "../config/general.config"

export class EmbedCalculatorStateClass implements IEmbedCalculatorState {
    // Once page reload we'd like to set it as initial state
    embedKey =
        BrowserStorageHelper.get(
            GENERAL_CONFIG.browserStorageKeys.embedKey,
            undefined,
            BrowserStorageType.sessionStorage,
        )?.toString() || undefined

    managementKey = null

    checkedEmbedKey = null
}
