import React, { useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import {
    PrimaryText,
    VALIDATION_CONFIG,
    UtilHelper,
    ButtonElement,
    InputElement,
    CollapseElement,
} from "nirvana-react-elements"

import { SESSION_RATES_CONFIG } from "../../config/sessionRates.config"
import { ExpensesConfigurationComponent } from "./expensesConfiguration.component"
import { GENERAL_CONFIG } from "../../config/general.config"

import calculatorIcon from "../../assets/images/icons/calculator.svg"
import privacyImage from "../../assets/images/icons/privacy.svg"

export const SessionRatesDataCollectionComponent: React.FunctionComponent<{
    className?: string
    onDataCollected: (data: ISessionRatesInputData) => void
}> = props => {
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm()

    const [operationalExpenses, setOperationalExpenses] = useState<
        IExpensesData[]
    >([])
    const [softwareExpenses, setSoftwareExpenses] = useState<IExpensesData[]>(
        [],
    )

    // in cents
    const monthlyExpenses = useMemo<number>(() => {
        const combinedExpenses = [...operationalExpenses, ...softwareExpenses]

        const combinedConfiguration = [
            ...SESSION_RATES_CONFIG.availableOperationalExpenses,
            ...SESSION_RATES_CONFIG.availableSoftwareExpenses,
        ]

        return combinedExpenses.reduce((memo, item) => {
            const neededConfig = combinedConfiguration.find(
                config => config.key === item.key,
            )

            if (!neededConfig || !item.checked || !item.money) {
                return memo
            }

            const amountMultiplier = item.amount || 1

            // Make sure 0 is minimum
            const amount = Math.max(item.money, 0)

            // multiply by amount and if yearly divide by 12
            const finalAmount = Math.round(
                (amount * amountMultiplier) /
                    (neededConfig.isYearlyMoney ? 12 : 1),
            )

            // Convert to cents
            return memo + finalAmount * 100
        }, 0)
    }, [operationalExpenses, softwareExpenses])

    const performCalculation = (data: { sessionsPerWeek: string } | any) => {
        const cliniciansExpense = operationalExpenses.find(
            item => item.key === "clinicians",
        )

        props.onDataCollected({
            sessionsPerWeek: parseInt(data.sessionsPerWeek) || 0,
            cliniciansAmount: cliniciansExpense?.amount || 1,
            monthlyExpenses,
        })
    }

    return (
        <form
            onSubmit={handleSubmit(performCalculation)}
            className={`
                bg-brand-white rounded-12px
                ${props.className}
            `}
        >
            <div
                className="
                    mt-45px pt-22px pb-30px px-24px bg-brand-offWhite
                    min-h-130px rounded-8px
                    md:pt-30px md:mt-40px
                "
            >
                <div className="flex items-start mt-30px md:block">
                    <InputElement
                        className="flex-1 md:mt-20px"
                        label="What is your ideal case load?"
                        isLabelStatic={true}
                        type="number"
                        name="sessionsPerWeek"
                        reactHookControl={control}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                            min: VALIDATION_CONFIG.minAmount,
                        }}
                        reactHookErrors={errors}
                        shouldUnregister={true}
                        inputSuffix="sessions a week per clinician"
                    />

                    <div className="flex-1" />

                    <div className="flex-1" />
                </div>

                <PrimaryText className="flex-1 mt-48px" typography="h3">
                    Operational Expenses
                </PrimaryText>

                <ExpensesConfigurationComponent
                    className="mt-32px"
                    options={SESSION_RATES_CONFIG.availableOperationalExpenses}
                    onChange={data => setOperationalExpenses(data)}
                />

                <PrimaryText typography="h3" className="mt-48px">
                    Which software do you use today?
                </PrimaryText>

                <ExpensesConfigurationComponent
                    className="mt-24px"
                    options={SESSION_RATES_CONFIG.availableSoftwareExpenses}
                    onChange={data => setSoftwareExpenses(data)}
                />
            </div>

            <footer
                className="
                    flex items-start mt-36px
                    md:block
                "
            >
                <div className="flex-1">
                    <CollapseElement
                        className="max-w-570px md:max-w-none"
                        titles={[
                            {
                                icon: privacyImage,
                                text: "Our Privacy Promise",
                            },
                        ]}
                        contents={[
                            <>
                                Nirvana Health takes your data security and
                                privacy seriously. We only use your information
                                to communicate directly with your insurance
                                company (checking benefits, filing claims,
                                etc.). We are fully HIPAA compliant and your
                                data will never be shared without your consent
                                for anything other than what is necessary to
                                process your claims. For more, please read our
                                full{" "}
                                <a
                                    href={`${GENERAL_CONFIG.mainWebsiteUrl}/legal/privacy-policy`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-brand-primary!"
                                >
                                    Privacy Policy
                                </a>
                                .
                            </>,
                        ]}
                    />
                </div>

                <ButtonElement
                    className="md:mt-35px"
                    label="Calculate"
                    type="primary"
                    size="large"
                    icon={calculatorIcon}
                    htmlType="submit"
                    buttonClassName="md:w-full"
                    disabled={!monthlyExpenses}
                />
            </footer>

            {/*TOTAL MONTHLY FIXED AT BOTTOM*/}
            {monthlyExpenses ? (
                <div
                    className="
                        fixed bottom-0 left-0 z-10 bg-brand-offWhite w-screen
                        py-15px border-t-2px border-solid border-brand-warmShadow
                        md:px-10px
                    "
                >
                    <div className="primary-container">
                        <PrimaryText
                            typography="h5"
                            className="text-right md:text-center"
                        >
                            Total Monthly Cost:{" "}
                            {UtilHelper.getFormattedMoney(monthlyExpenses)}
                        </PrimaryText>
                    </div>
                </div>
            ) : null}
        </form>
    )
}
