/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import {
    CheckoutPopupWrapperElement,
    INPUT_MASK_VALIDATION_FUNCTIONS,
    PageHelmetElement,
    PrimaryText,
    SpinnerElement,
    FaqElement,
} from "nirvana-react-elements"
import { useDispatch, useSelector } from "react-redux"

import { GENERAL_CONFIG } from "../../config/general.config"
import { runtimeActionCreators } from "../../actions/runtime.actions"
import { embedCalculatorActionCreators } from "../../actions/embedCalculator.actions"
import { embedCalculatorSelector } from "../../selectors/embedCalculator.selector"
import { NotFoundComponent } from "../notFound.component"
import { EmbedCalculatorEnrollmentDoneComponent } from "./embedCalculatorEnrollmentDone.component"
import { FAQ_CONFIG } from "../../config/faq.config"
import { EmbedCalculatorEnrollmentFormComponent } from "./embedCalculatorEnrollmentForm.component"

export const EmbedCalculatorEnrollmentComponent: React.FunctionComponent =
    () => {
        const { embedKey } = useParams()

        const dispatch = useDispatch()

        const embedCalculatorState = useSelector(embedCalculatorSelector)

        const [isBadEmbedKey, setIsBadEmbedKey] = useState<boolean>(false)
        const [isEnrollmentDone, setIsEnrollmentDone] = useState<boolean>(false)

        // Set in state so all parts knows that this is embed calculator
        useEffect(() => {
            dispatch(runtimeActionCreators.setIsEmbed(true))
        }, [])

        // Set in state our embed key
        useEffect(() => {
            if (!embedKey) {
                return
            }

            dispatch(embedCalculatorActionCreators.setEmbedKey(embedKey))
        }, [embedKey])

        // Watch for embed key in state
        // Then send request to check validity of it and receive embed key related data
        useEffect(() => {
            if (!embedCalculatorState.embedKey) {
                return
            }

            // Send this with timeout so for example if navigating to new url
            // And old key is still in session it won't send request with old key (until state is updated with new key)
            // Send request to server and check this key and get related to it data
            const timeoutId = setTimeout(() => {
                embedCalculatorState.embedKey &&
                    dispatch(
                        embedCalculatorActionCreators.checkEmbedKey(
                            embedCalculatorState.embedKey,
                            undefined,
                            () => {
                                setIsBadEmbedKey(true)
                            },
                        ),
                    )
            }, 1000)

            return () => {
                try {
                    clearTimeout(timeoutId)
                } catch (e) {}
            }
        }, [embedCalculatorState.embedKey])

        const onClientEnrollmentSubmit = (
            data: IEmbedCalculatorClientEnrollmentData,
        ) => {
            if (!embedCalculatorState.checkedEmbedKey?.embedKey) {
                return
            }

            // Convert dob date after masked input
            data.dob = INPUT_MASK_VALIDATION_FUNCTIONS.date.parse(data.dob)

            dispatch(
                embedCalculatorActionCreators.enrollClient(
                    embedCalculatorState.checkedEmbedKey.embedKey,
                    data,
                    () => setIsEnrollmentDone(true),
                ),
            )
        }

        return (
            <div>
                <PageHelmetElement
                    title="Enroll in Claims Filing"
                    defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
                />

                <CheckoutPopupWrapperElement
                    isActive
                    withLogo
                    leftContent={
                        <div>
                            <PrimaryText typography="h4">
                                Enroll in Superbill Submission for Reimbursement
                            </PrimaryText>

                            <PrimaryText className="mt-16px">
                                By signing up, Nirvana can help you get
                                reimbursed for therapy sessions. Nirvana will
                                handle the complexities of working with your
                                health plan to reimburse you for some or all of
                                your expenses.
                            </PrimaryText>

                            <PrimaryText className="mt-16px">
                                Simply set up your account and insurance
                                information. When your therapist gives you a
                                superbill (an itemized receipt) for therapy, you
                                can upload that superbill to your account on
                                Nirvana.
                            </PrimaryText>

                            <PrimaryText className="mt-16px">
                                We will handle sending a claim to the health
                                plan (your insurance company). We will notify
                                you if the health plan accepts or rejects the
                                claim, and any reasons for rejection.
                            </PrimaryText>

                            <PrimaryText className="mt-16px">
                                We are offering this service as a Beta test to
                                selected practices.
                            </PrimaryText>

                            <FaqElement
                                className="mt-72px"
                                title="FAQS"
                                titleTypography="h5"
                                expandable
                                items={
                                    FAQ_CONFIG.embedCalculatorClientEnrollment
                                }
                                questionTypography="textSemibold"
                                questionClassName="pb-16px"
                                answerTypography="text"
                                answerClassName="pb-16px"
                                singleItemClassName="pt-16px border-b-1px border-solid border-brand-warmLight"
                            />
                        </div>
                    }
                    rightContent={
                        <div>
                            {isBadEmbedKey ||
                            (embedCalculatorState.checkedEmbedKey &&
                                !embedCalculatorState.checkedEmbedKey
                                    .clientEnrollmentAllowed) ? (
                                <NotFoundComponent
                                    onGoHomeBtnClick={() =>
                                        embedCalculatorState.embedKey &&
                                        dispatch(
                                            embedCalculatorActionCreators.setEmbedKey(
                                                undefined,
                                            ),
                                        )
                                    }
                                />
                            ) : (
                                <>
                                    {embedCalculatorState.checkedEmbedKey ? (
                                        <div className="w-full">
                                            {isEnrollmentDone ? (
                                                <EmbedCalculatorEnrollmentDoneComponent />
                                            ) : (
                                                <EmbedCalculatorEnrollmentFormComponent
                                                    onDone={
                                                        onClientEnrollmentSubmit
                                                    }
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <SpinnerElement containerClassName="mt-50px text-center" />
                                    )}
                                </>
                            )}
                        </div>
                    }
                />
            </div>
        )
    }
