import { createStore, applyMiddleware } from "redux"
import createSagaMiddleware from "redux-saga"
import thunk from "redux-thunk"

import { rootReducer } from "./reducers/root.reducer"
import { rootSagas } from "./sagas/root.sagas"

const sagaMiddleware = createSagaMiddleware()

const storeConfigurator = (initialState: any) => {
    return createStore(
        rootReducer,
        initialState,
        applyMiddleware(thunk, sagaMiddleware),
    )
}

export const appStore = storeConfigurator({})

sagaMiddleware.run(rootSagas)
