import { all } from "redux-saga/effects"

// SAGAS
import calculatorSagas from "./calculator.sagas"
import calculatorCheckoutSagas from "./calculatorCheckout.sagas"
import embedCalculatorSagas from "./embedCalculator.sagas"
import intakeDemoSagas from "./intakeDemo.sagas"

export function* rootSagas() {
    yield all([
        calculatorSagas(),
        calculatorCheckoutSagas(),
        embedCalculatorSagas(),
        intakeDemoSagas(),
    ])
}
