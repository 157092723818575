import { RuntimeStateClass } from "../classes/runtimeState.class"
import { RuntimeActions } from "../actions/runtime.actions"

export const runtimeReducer = (
    state: IRuntimeState = new RuntimeStateClass(),
    action: ISagaAction,
): IRuntimeState => {
    let isLoading: string[]

    switch (action.type) {
        case RuntimeActions.APP_LOADING_START:
            isLoading = [...state.isLoading, action.payload]

            return {
                ...state,
                isLoading,
            }

        case RuntimeActions.APP_LOADING_STOP:
            isLoading = state.isLoading.filter(item => item !== action.payload)

            return {
                ...state,
                isLoading,
            }

        case RuntimeActions.APP_SET_IS_EMBED:
            return {
                ...state,

                isEmbed: action.payload,
            }

        case RuntimeActions.APP_SET_IS_PDF_GENERATING:
            return {
                ...state,

                isGeneratingPdf: action.payload,
            }

        default:
            return state
    }
}
