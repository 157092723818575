/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import {
    useScrollFix,
    PageHelmetElement,
    PrimaryText,
    CodeCopyBlockElement,
    UtilHelper,
    useFaqs,
    ISupportAuthFAQItem,
    AvailableFAQType,
    IFaqItem,
    FaqElement,
} from "nirvana-react-elements"

import { embedCalculatorActionCreators } from "../../actions/embedCalculator.actions"
import { AnalyticsHelper } from "../../helpers/analytics.helper"
import { AnalyticsEvent } from "../../config/analytics.config"
import { GENERAL_CONFIG } from "../../config/general.config"
import { calculatorCheckoutActionCreators } from "../../actions/calculatorCheckout.actions"
import { CALCULATOR_CHECKOUT_CONFIG } from "../../config/calculatorCheckout.config"
import { PracticeSetupPromoComponent } from "../embedCalculator/practiceSetupPromo.component"

export const CalculatorCheckoutSuccessComponent: React.FunctionComponent =
    () => {
        useScrollFix()

        const dispatch = useDispatch()
        const { embedKey } = useParams()
        const [searchParams] = useSearchParams()

        // Checkout session id that was successful
        const [checkoutSessionId] = useState<string | null>(
            searchParams.get(
                GENERAL_CONFIG.urlSearchParamsKeys.checkoutSessionId,
            ),
        )

        const { faqs } = useFaqs<ISupportAuthFAQItem>(
            AvailableFAQType.calculatorSelfServeSuccess,
        )

        const faqItems = useMemo<IFaqItem[]>(
            () =>
                faqs.map(item => ({
                    question: item.title,
                    answer: item.description,
                    isAnswerContentfulMarkdown: true,
                })),
            [faqs],
        )

        const fullEmbedLink = useMemo<string>(
            () => `${UtilHelper.getCurrentAppDomain()}/${embedKey}`,
            [embedKey],
        )

        const iframeCode = useMemo<string>(() => {
            return (
                '<div style="display: flex; justify-content: center;">\n' +
                "\t<iframe\n" +
                `\t\tsrc="${fullEmbedLink}"\n` +
                '\t\tname="reimbursementCalculator"\n' +
                '\t\tstyle="border: none"\n' +
                '\t\theight="1500"\n' +
                '\t\twidth="1000"\n' +
                "\t\tallowfullscreen\n" +
                "\t></iframe>\n" +
                "</div>"
            )
        }, [fullEmbedLink])

        // Record success event in analytics
        useEffect(() => {
            AnalyticsHelper.event(AnalyticsEvent.checkoutSuccess)
        }, [])

        // Set in state our embed key
        useEffect(() => {
            if (!embedKey) {
                return
            }

            dispatch(embedCalculatorActionCreators.setEmbedKey(embedKey))
        }, [embedKey])

        // Once checkout session ID is received, get details of this checkout session
        // At the end we want to receive data that was created in db (calculator_embeds and etc) based on checkout session id
        useEffect(() => {
            if (!checkoutSessionId) {
                return
            }

            let timeoutId

            const timeoutCleaner = () => {
                if (!timeoutId) {
                    return
                }

                try {
                    clearTimeout(timeoutId)
                } catch (e) {}
            }

            const managementKeyGetter = (currentTriesCount = 0) => {
                dispatch(
                    calculatorCheckoutActionCreators.getSuccessfulSessionManagementKey(
                        checkoutSessionId,
                        data => {
                            if (
                                data.currentTriesCount >=
                                CALCULATOR_CHECKOUT_CONFIG.successCheckoutSessionMaxChecksTries
                            ) {
                                return
                            }

                            timeoutCleaner()

                            timeoutId = setTimeout(() => {
                                managementKeyGetter(data.currentTriesCount + 1)
                            }, 10 * 1000)
                        },
                        currentTriesCount,
                    ),
                )
            }

            timeoutId = setTimeout(managementKeyGetter, 5 * 1000)

            return timeoutCleaner
        }, [checkoutSessionId])

        return (
            <div className="w-full pb-100px">
                <div
                    className="
                        w-720px mx-auto bg-brand-offWhite rounded-8px p-24px
                        border border-solid border-brand-warmShadow
                        md:w-full md:rounded-none
                    "
                >
                    <PageHelmetElement
                        title="Checkout Success"
                        defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
                    />

                    <PrimaryText typography="h1">Success!</PrimaryText>

                    <PrimaryText className="mt-24px">
                        We’ve received your payment. So, what happens now?{" "}
                        <br />
                        <br />
                        Here is the unique HTML code you’ll need to embed our
                        Calculator in your website. The code already includes
                        your therapist and session rate information. We have
                        also emailed this code to you so you can access it
                        anytime.
                    </PrimaryText>

                    <CodeCopyBlockElement
                        className="mt-40px"
                        language="javascript"
                        code={iframeCode}
                    />

                    <PracticeSetupPromoComponent />

                    <div
                        className="
                            mt-24px w-full bg-brand-lilacLight rounded-8px
                            px-16px py-12px
                        "
                    >
                        <PrimaryText className="overflow-ellipsis overflow-hidden">
                            See how your personalized Calculator will look like{" "}
                            <a
                                href={fullEmbedLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {fullEmbedLink}
                            </a>
                        </PrimaryText>
                    </div>

                    <FaqElement
                        className="mt-40px"
                        items={faqItems}
                        questionTypography="textSemibold"
                        answerClassName="mt-5px"
                    />

                    <PrimaryText typography="h4" className="mt-40px">
                        Thank you for your purchase!
                    </PrimaryText>
                </div>
            </div>
        )
    }
