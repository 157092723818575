/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    CheckoutPopupWrapperElement,
    PrimaryText,
    FaqElement,
} from "nirvana-react-elements"
import { useNavigate } from "react-router-dom"

import { EmbedCalculatorPracticeInformationStep } from "../embedCalculator/steps/embedCalculatorPracticeInformation.step"
import { embedCalculatorSelector } from "../../selectors/embedCalculator.selector"
import { embedCalculatorActionCreators } from "../../actions/embedCalculator.actions"
import { FAQ_CONFIG } from "../../config/faq.config"

export const EmbedCalculatorSetupPracticePopupComponent: React.FunctionComponent<{
    isActive: boolean
    className?: string
    onDone: () => void
}> = props => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const embedCalculatorState = useSelector(embedCalculatorSelector)

    // Once popup active and practice creation is not allowed -> close it
    useEffect(() => {
        if (!embedCalculatorState.checkedEmbedKey) {
            return
        }

        if (!embedCalculatorState.checkedEmbedKey.practiceCreationAllowed) {
            props.onDone()
        }
    }, [embedCalculatorState.checkedEmbedKey?.practiceCreationAllowed])

    const onCancel = () => {
        props.onDone()
    }

    const onSavePracticeInformation = (
        data: ICalculatorPracticeCreationDetailsData,
    ) => {
        if (!embedCalculatorState.managementKey) {
            return
        }

        dispatch(
            embedCalculatorActionCreators.createPractice(
                embedCalculatorState.managementKey,
                data,
                navigate,
            ),
        )
    }

    return (
        <CheckoutPopupWrapperElement
            withLogo
            isActive={props.isActive}
            className={props.className}
            onCancel={onCancel}
            leftContent={
                <div>
                    <PrimaryText typography="h4">
                        Your clients can file claims through Nirvana
                    </PrimaryText>

                    <PrimaryText className="mt-16px">
                        Simply set up your practice information and your clients
                        will be able to start filing out-of-network claims
                        directly through us.
                    </PrimaryText>

                    <PrimaryText className="mt-16px">
                        When you provide your clients with a superbill, they
                        will be able to upload it to Nirvana. Nirvana will
                        process the superbill and submit the claim to the
                        appropriate health plan. We will notify the client of
                        the claim’s reimbursement status or any denials or
                        rejections.
                    </PrimaryText>

                    <PrimaryText className="mt-16px">
                        We are offering this service as a Beta test to selected
                        practices.
                    </PrimaryText>

                    <FaqElement
                        className="mt-72px"
                        title="FAQS"
                        titleTypography="h5"
                        expandable
                        items={FAQ_CONFIG.embedCalculatorPracticeCreation}
                        questionTypography="textSemibold"
                        questionClassName="pb-16px"
                        answerTypography="text"
                        answerClassName="pb-16px"
                        singleItemClassName="pt-16px border-b-1px border-solid border-brand-warmLight"
                    />
                </div>
            }
            rightContent={
                // LEFT FOR REFERENCE. UNCOMMENT TO VERTICALLY CENTER FORM
                // <div className="flex-1 flex flex-col items-center justify-center md:block">
                <EmbedCalculatorPracticeInformationStep
                    onStepSubmit={onSavePracticeInformation}
                />
                // </div>
            }
        />
    )
}
