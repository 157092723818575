import {
    BrowserStorageHelper,
    BrowserStorageType,
} from "nirvana-react-elements"

import { IntakeDemoStateClass } from "../classes/intakeDemoState.class"
import { IntakeDemoActions } from "../actions/intakeDemo.actions"
import { GENERAL_CONFIG } from "../config/general.config"
import { IntakeDemoFlow } from "../config/intakeDemo.config"

export const intakeDemoReducer = (
    state: IIntakeDemoState = new IntakeDemoStateClass(),
    action: ISagaAction,
): IIntakeDemoState => {
    switch (action.type) {
        case IntakeDemoActions.INTAKE_DEMO_AUTH_SUCCESS:
            BrowserStorageHelper.set(
                GENERAL_CONFIG.browserStorageKeys.tokenKey,
                action.payload.authToken,
                BrowserStorageType.sessionStorage,
            )

            return {
                ...state,

                isAuthenticated: true,
            }

        case IntakeDemoActions.INTAKE_DEMO_AUTH_RESET:
            BrowserStorageHelper.remove(
                GENERAL_CONFIG.browserStorageKeys.tokenKey,
                BrowserStorageType.sessionStorage,
            )

            return {
                ...state,

                isAuthenticated: false,
            }

        case IntakeDemoActions.INTAKE_DEMO_SET_CURRENT_FLOW:
            return {
                ...state,

                currentFlow: action.payload.currentFlow,
            }

        case IntakeDemoActions.INTAKE_DEMO_SET_COVERAGE:
            return {
                ...state,

                result: action.payload.result,
                currentFlow: IntakeDemoFlow.success,
            }

        default:
            return state
    }
}
