import { CALCULATOR_CHECKOUT_CONFIG } from "../config/calculatorCheckout.config"

export class CalculatorCheckoutHelper {
    /**
     * Get graduated pricing of subscription
     * Based on amount of therapists and price rules
     * Returns in cents
     *
     * https://stripe.com/docs/products-prices/pricing-models#graduated-pricing
     */
    static getSubscriptionGraduatedPricing(therapistsCount: number): number {
        let finalPrice = 0

        for (const pricingRule of CALCULATOR_CHECKOUT_CONFIG.graduatedPricingRules) {
            if (therapistsCount <= 0) {
                break
            }

            // Take part of total count that is within current rule
            const matchingQuantity = Math.max(
                0,
                Math.min(
                    therapistsCount,
                    pricingRule.countRangeMax - pricingRule.countRangeMin,
                ),
            )

            finalPrice += matchingQuantity * pricingRule.unitPrice

            therapistsCount -= matchingQuantity
        }

        return finalPrice
    }

    /**
     * Map checkout input data prepared by front to the format front is expecting in URL as predefined data
     * Don't add to url default values for cpt and sessionRate
     */
    static generateUrlPredefinedCheckoutTherapistsData(
        therapistsData: Partial<ICalculatorCheckoutSingleTherapistData>[],
    ): IEmbedCalculatorCheckoutUrlTherapistPredefinedData[] {
        return therapistsData.map(item => ({
            n: item.healthProvider?.npi,
            pn: item.healthProviderPreferredName,
            r: (item.rates || []).map(rate => ({
                sr:
                    rate.sessionRate !==
                    CALCULATOR_CHECKOUT_CONFIG.defaultHealthProviderRate.toString()
                        ? rate.sessionRate
                        : undefined,
                cc:
                    rate.cptCode !== CALCULATOR_CHECKOUT_CONFIG.defaultCptCode
                        ? rate.cptCode
                        : undefined,
            })),
        }))
    }
}
