/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import {
    AvailableLookupConsumer,
    useCptCodes,
    useScrollFix,
} from "nirvana-react-elements"

import { CalculatorDataCollectionComponent } from "../calculator/calculatorDataCollection.component"
import { runtimeActionCreators } from "../../actions/runtime.actions"
import { embedCalculatorActionCreators } from "../../actions/embedCalculator.actions"
import { embedCalculatorSelector } from "../../selectors/embedCalculator.selector"

export const EmbedCalculatorComponent: React.FunctionComponent = () => {
    useScrollFix()

    const dispatch = useDispatch()

    const { embedKey } = useParams()

    const embedCalculatorState = useSelector(embedCalculatorSelector)

    const { availableCptCodesStyled } = useCptCodes(
        AvailableLookupConsumer.embedCalculator,
    )

    // Set in state so all parts knows that this is embed calculator
    useEffect(() => {
        dispatch(runtimeActionCreators.setIsEmbed(true))
    }, [])

    // Set in state our embed key
    useEffect(() => {
        if (!embedKey) {
            return
        }

        dispatch(embedCalculatorActionCreators.setEmbedKey(embedKey))
    }, [embedKey])

    // Wait until embed key is set in state
    return embedCalculatorState.embedKey && availableCptCodesStyled.length ? (
        <CalculatorDataCollectionComponent cptCodes={availableCptCodesStyled} />
    ) : null
}
