import { NavigateFunction } from "react-router-dom"

export enum EmbedCalculatorActions {
    EMBED_CALCULATOR_SET_KEY = "EMBED_CALCULATOR_SET_KEY",
    EMBED_CALCULATOR_CHECK_KEY = "EMBED_CALCULATOR_CHECK_KEY",
    EMBED_CALCULATOR_LOGIN_MANAGEMENT_PORTAL = "EMBED_CALCULATOR_LOGIN_MANAGEMENT_PORTAL",
    EMBED_CALCULATOR_CHECK_MANAGEMENT_KEY = "EMBED_CALCULATOR_CHECK_MANAGEMENT_KEY",
    EMBED_CALCULATOR_SET_MANAGEMENT_KEY = "EMBED_CALCULATOR_SET_MANAGEMENT_KEY",
    EMBED_CALCULATOR_SET_CHECKED_KEY = "EMBED_CALCULATOR_SET_CHECKED_KEY",
    EMBED_CALCULATOR_NEW_STRIPE_CUSTOMER_PORTAL_SESSION = "EMBED_CALCULATOR_NEW_STRIPE_CUSTOMER_PORTAL_SESSION",
    EMBED_CALCULATOR_EDIT_HEALTH_PROVIDERS = "EMBED_CALCULATOR_EDIT_HEALTH_PROVIDERS",
    EMBED_CALCULATOR_CREATE_PRACTICE = "EMBED_CALCULATOR_CREATE_PRACTICE",
    EMBED_CALCULATOR_ENROLL_CLIENT = "EMBED_CALCULATOR_ENROLL_CLIENT",
}

export const embedCalculatorActionCreators = {
    setEmbedKey: (payload?: string): ISagaAction => {
        return {
            type: EmbedCalculatorActions.EMBED_CALCULATOR_SET_KEY,
            payload,
        }
    },

    checkEmbedKey: (
        embedKey: string,
        onSuccess?: () => void,
        onError?: () => void,
    ): ISagaAction => {
        return {
            type: EmbedCalculatorActions.EMBED_CALCULATOR_CHECK_KEY,
            payload: {
                embedKey,
            },
            onSuccess,
            onError,
        }
    },

    loginManagementPortal: (
        email: string,
        onSuccess: () => void,
    ): ISagaAction => {
        return {
            type: EmbedCalculatorActions.EMBED_CALCULATOR_LOGIN_MANAGEMENT_PORTAL,
            payload: {
                email,
            },
            onSuccess,
        }
    },

    checkManagementKey: (
        managementKey: string,
        onSuccess?: () => void,
        navigate?: NavigateFunction,
    ): ISagaAction => {
        return {
            type: EmbedCalculatorActions.EMBED_CALCULATOR_CHECK_MANAGEMENT_KEY,
            payload: {
                managementKey,
            },
            onSuccess,
            navigate,
        }
    },

    setManagementKey: (payload: string | null): ISagaAction => {
        return {
            type: EmbedCalculatorActions.EMBED_CALCULATOR_SET_MANAGEMENT_KEY,
            payload,
        }
    },

    setCheckedEmbedKey: (payload: ICalculatorEmbedKey | null): ISagaAction => {
        return {
            type: EmbedCalculatorActions.EMBED_CALCULATOR_SET_CHECKED_KEY,
            payload,
        }
    },

    loginStripeCustomerPortal: (
        managementKey: string,
        navigate?: NavigateFunction,
    ): ISagaAction => {
        return {
            type: EmbedCalculatorActions.EMBED_CALCULATOR_NEW_STRIPE_CUSTOMER_PORTAL_SESSION,
            payload: {
                managementKey,
            },
            navigate,
        }
    },

    editHealthProviders: (
        managementKey: string,
        therapistsData: ICalculatorCheckoutSingleTherapistData[],
        onSuccess: () => void,
        navigate?: NavigateFunction,
    ): ISagaAction => {
        return {
            type: EmbedCalculatorActions.EMBED_CALCULATOR_EDIT_HEALTH_PROVIDERS,
            payload: {
                managementKey,
                therapistsData,
            },
            onSuccess,
            navigate,
        }
    },

    createPractice: (
        managementKey: string,
        practiceInfo: ICalculatorPracticeCreationDetailsData,
        navigate?: NavigateFunction,
    ): ISagaAction => {
        return {
            type: EmbedCalculatorActions.EMBED_CALCULATOR_CREATE_PRACTICE,
            payload: {
                managementKey,
                practiceInfo,
            },
            navigate,
        }
    },

    enrollClient: (
        embedKey: string,
        data: IEmbedCalculatorClientEnrollmentData,
        onSuccess?: () => void,
    ): ISagaAction => {
        return {
            type: EmbedCalculatorActions.EMBED_CALCULATOR_ENROLL_CLIENT,
            payload: {
                embedKey,
                data,
            },
            onSuccess,
        }
    },
}
