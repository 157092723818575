export const ROUTES_CONFIG = {
    // DEFAULT CALCULATOR
    calculatorUrl: "/",
    calculatorSuccessUrl: "/success",
    calculatorIneligibleUrl: "/ineligible",
    calculatorInconclusiveUrl: "/inconclusive",

    // PAYERS LIST
    supportedPayersListUrl: "/supported-insurers",

    // SESSION RATES
    sessionRatesUrl: "/session-rates",

    // EMBED SELF-SERVE CALCULATOR
    embedCalculatorUrl: "/:embedKey",
    embedCalculatorEnrollmentUrl: "/:embedKey/enroll",
    // We could keep same redirects and same final urls for components where we redirect after result got with embed key
    // But having urls based on embed key would allow us to track which embedKey has how many views for example
    // But essentially these components are the same
    embedCalculatorSuccessUrl: "/:embedKey/success",
    embedCalculatorIneligibleUrl: "/:embedKey/ineligible",
    embedCalculatorInconclusiveUrl: "/:embedKey/inconclusive",

    // EMBED SELF-SERVE CALCULATOR CHECKOUT
    calculatorCheckoutFailedUrl: "/checkout/:embedKey/failed",
    calculatorCheckoutSuccessUrl: "/checkout/:embedKey/success",

    // EMBED SELF-SERVE CALCULATOR MANAGEMENT
    embedCalculatorManagementDefaultUrl: "/management",
    embedCalculatorManagementLoginUrl: "/management/login",
    embedCalculatorManagementUrl: "/management/:managementKey",

    // INTAKE DEMO
    intakeDemo: "/intake-demo",
}

export const API_ROUTES = {
    // CALCULATOR
    calculateUrl: "/calculator",
    manualEligibilityUrl: "/calculator/manual-eligibility",
    inviteTherapistUrl: "/calculator/invite-therapist",
    emailResultsUrl: "/calculator/email-results",
    // CALCULATOR CHECKOUT
    embedCalculatorCheckoutUrl: "/calculator/checkout",
    embedCalculatorCheckoutGetManagementKeyUrl:
        "/calculator/checkout/management-key",
    // SELF-SERVE CALCULATOR
    embedCalculatorCheckKey: "/calculator/embed/:embedKey",
    embedCalculatorEnrollClient: "/calculator/embed/:embedKey/enroll",
    // SELF-SERVE CALCULATOR MANAGEMENT
    embedCalculatorManagementLogin: "/calculator/embed/auth/login",
    embedCalculatorGetManagementData: "/calculator/embed/management",
    embedCalculatorManagementCreatePractice:
        "/calculator/embed/management/practice",
    embedCalculatorCreateStripeCustomerPortalSession:
        "/calculator/embed/management/stripe-session",
    embedCalculatorEditHealthProviders:
        "/calculator/embed/management/health-providers",
    // INTAKE DEMO
    intakeDemoAuthorize: "/intake-demo/authenticate",
    intakeDemoGetCoverage: "/intake-demo/coverage",
}

export const FULLY_HEADLESS_ROUTES = [
    ROUTES_CONFIG.embedCalculatorEnrollmentUrl,
    ROUTES_CONFIG.intakeDemo,
]
