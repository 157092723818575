/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import {
    ButtonElement,
    InputElement,
    VALIDATION_CONFIG,
} from "nirvana-react-elements"

import { intakeDemoActionCreators } from "../../actions/intakeDemo.actions"

export const IntakeDemoAuthComponent: React.FunctionComponent = () => {
    const dispatch = useDispatch()

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm()

    const onSubmit = (data: any) => {
        const inputData = data as IIntakeDemoAuthorizationData

        dispatch(intakeDemoActionCreators.authStart(inputData.authToken))
    }

    return (
        <div className="relative">
            <form className="mt-52px" onSubmit={handleSubmit(onSubmit)}>
                <InputElement
                    label="Auth Token"
                    type="password"
                    name="authToken"
                    reactHookControl={control}
                    reactHookValidations={{
                        required: VALIDATION_CONFIG.required,
                        maxLength: VALIDATION_CONFIG.maxLength,
                    }}
                    reactHookErrors={errors}
                />

                <div className="mt-32px">
                    <ButtonElement
                        label="Authenticate"
                        type="primary"
                        size="large"
                        htmlType="submit"
                        buttonClassName="w-full"
                    />
                </div>
            </form>
        </div>
    )
}
