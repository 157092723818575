/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import { PrimaryText, ButtonElement } from "nirvana-react-elements"

import { GENERAL_CONFIG } from "../../config/general.config"

import sendEmailSuccessImg from "../../assets/images/shapes/email-sent.svg"

export const EmbedCalculatorEnrollmentDoneComponent: React.FunctionComponent =
    () => {
        return (
            <div>
                <div>
                    <img
                        src={sendEmailSuccessImg}
                        className="margin-x-center"
                        alt="Enrollment success"
                        title="Enrollment success"
                    />
                </div>

                <PrimaryText centered typography="h1" className="mt-32px">
                    One more step...
                </PrimaryText>

                <PrimaryText typography="text" className="mt-32px" centered>
                    Congratulations! Your account has been created and was
                    enrolled for claims filing. <br />
                    Please <span className="text-bold">
                        check your email
                    </span>{" "}
                    for a verification link prior to logging in and you're all
                    set!
                </PrimaryText>

                <div className="mt-32px text-center">
                    <a href={GENERAL_CONFIG.clientPortalUrl}>
                        <ButtonElement
                            label="Go to Client Portal"
                            type="primary"
                            htmlType="button"
                            size="large"
                        />
                    </a>
                </div>
            </div>
        )
    }
