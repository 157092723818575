import React from "react"
import { TooltipElement, PrimaryText, UtilHelper } from "nirvana-react-elements"

export const CoverageBreakdownCardComponent: React.FunctionComponent<
    ICoverageBreakdownCardComponentProps
> = props => {
    return (
        <>
            <div
                className={`
                    ${props.className}
                    p-20px rounded-8px border-solid
                    ${
                        props.disabled
                            ? "border border-brand-warmShadow bg-brand-black-transparent-010"
                            : props.highlighted
                            ? "bg-brand-offWhite border-t-3px border-brand-lilac"
                            : "bg-brand-offWhite"
                    }
                `}
            >
                <div className="flex items-center">
                    <div
                        className="
                            flex-1
                            md:flex md:items-center
                        "
                    >
                        <PrimaryText typography="textSemibold">
                            {props.title}:
                        </PrimaryText>

                        <PrimaryText typography="h6" className="md:ml-5px">
                            {props.subtitle}
                        </PrimaryText>
                    </div>

                    {props.tooltip ? (
                        <TooltipElement
                            text={props.tooltip}
                            className="ml-10px"
                        />
                    ) : null}
                </div>

                <div className="mt-20px">
                    <PrimaryText typography="h4Medium">
                        {typeof props.reimbursementPerSessionMin === "number"
                            ? UtilHelper.getFormattedMoney(
                                  props.reimbursementPerSessionMin,
                                  true,
                              )
                            : props.reimbursementPerSessionMin}
                        {props.reimbursementPerSessionMax &&
                        props.reimbursementPerSessionMax !==
                            props.reimbursementPerSessionMin
                            ? `-${
                                  typeof props.reimbursementPerSessionMax ===
                                  "number"
                                      ? UtilHelper.getFormattedMoney(
                                            props.reimbursementPerSessionMax,
                                            true,
                                        )
                                      : props.reimbursementPerSessionMax
                              }`
                            : ""}
                    </PrimaryText>

                    <PrimaryText typography="h6">
                        Reimbursement per session
                    </PrimaryText>
                </div>

                <div
                    className={`
                        ${props.disabled ? "mt-30px" : "mt-20px"}
                        md:flex md:items-center
                    `}
                >
                    <PrimaryText
                        typography="textSemibold"
                        className={props.disabled ? "opacity-70" : ""}
                    >
                        {props.bottomTitle}
                    </PrimaryText>

                    {!props.disabled && (
                        <PrimaryText
                            typography="caption"
                            className="mt-4px md:mt-0px md:ml-5px"
                        >
                            {props.sessionsCount
                                ? `(About ${props.sessionsCount} sessions)`
                                : " "}
                        </PrimaryText>
                    )}
                </div>
            </div>
        </>
    )
}
