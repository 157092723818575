import { NavigateFunction } from "react-router-dom"
import { UtilHelper } from "nirvana-react-elements"

import { HttpHelper } from "../helpers/http.helper"
import { API_ROUTES } from "../config/routes.config"
import { CalculatorCheckoutService } from "./calculatorCheckout.service"

export class EmbedCalculatorService {
    /**
     * Check embed calculator key and get it's related data
     */
    static async checkEmbedKey(
        embedKey: string,
    ): Promise<ICalculatorEmbedKey | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.embedCalculatorCheckKey.replace(":embedKey", embedKey),
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Trigger creation and sending management key to customer by email, aka short-lived login link
     */
    static async loginManagementPortal(email: string): Promise<any> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.embedCalculatorManagementLogin,
            {
                email,
            },
            "POST",
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Check embed calculator management key and get it's related data
     */
    static async checkManagementKey(
        managementKey: string,
        navigate?: NavigateFunction,
    ): Promise<ICalculatorEmbedKey | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.embedCalculatorGetManagementData,
            undefined,
            undefined,
            undefined,
            navigate,
            managementKey,
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Trigger creation of Stripe's customer portal session and then redirect to that url
     */
    static async createStripeCustomerPortalSession(
        managementKey: string,
        navigate?: NavigateFunction,
    ): Promise<{ redirectUrl: string } | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.embedCalculatorCreateStripeCustomerPortalSession,
            undefined,
            "POST",
            undefined,
            navigate,
            managementKey,
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Update health providers data using management key
     * This adds/edits/removes relations of healthProvider/rate/cpt that are assigned to embed key
     */
    static async editHealthProviders(
        managementKey: string,
        therapistsData: ICalculatorCheckoutSingleTherapistData[],
        navigate?: NavigateFunction,
    ): Promise<ICalculatorEmbedKey | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.embedCalculatorEditHealthProviders,
            {
                therapistsData:
                    CalculatorCheckoutService.mapCheckoutTherapistsData(
                        therapistsData,
                    ),
            },
            "PUT",
            undefined,
            navigate,
            managementKey,
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Create embed calculator practice
     */
    static async createPractice(
        managementKey: string,
        practiceInfo: ICalculatorPracticeCreationDetailsData,
        navigate?: NavigateFunction,
    ): Promise<ICalculatorEmbedKey | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.embedCalculatorManagementCreatePractice,
            practiceInfo,
            "POST",
            undefined,
            navigate,
            managementKey,
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Enroll client for claims filing in practice that is assigned to embed key
     */
    static async enrollClientToPractice(
        embedKey: string,
        data: IEmbedCalculatorClientEnrollmentData,
    ): Promise<{ isEnrolled: boolean } | undefined> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.embedCalculatorEnrollClient.replace(
                ":embedKey",
                embedKey,
            ),
            EmbedCalculatorService.processClientEnrollmentData(data),
            "POST",
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    ///////// PRIVATE

    /**
     * Process data into format required by API
     */
    private static processClientEnrollmentData(
        data: IEmbedCalculatorClientEnrollmentData,
    ): IEmbedCalculatorEnrollClientRequestData {
        const { dob, payer, ...validProperties } = data

        return {
            ...validProperties,

            dob: UtilHelper.dateToMysqlFormat(dob),
            payerId: payer.id,
        }
    }
}
