/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import {
    Link,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
    useIsMobile,
    UtilHelper,
    ButtonElement,
    NIRVANA_THEME,
} from "nirvana-react-elements"

import { PdfHelper } from "../helpers/pdf.helper"
import { ROUTES_CONFIG } from "../config/routes.config"
import { runtimeActionCreators } from "../actions/runtime.actions"
import { runtimeSelector } from "../selectors/runtime.selector"
import { EmailPdfResultsPopupComponent } from "./popups/emailPdfResultsPopup.component"
import { embedCalculatorSelector } from "../selectors/embedCalculator.selector"
import { GENERAL_CONFIG } from "../config/general.config"

import logo from "../assets/images/logo.svg"
import emailIcon from "../assets/images/icons/mail-white.svg"
import printIcon from "../assets/images/icons/print.svg"
import refreshIcon from "../assets/images/icons/refresh.svg"
import editIcon from "../assets/images/icons/edit-white.svg"

export const HeaderComponent: React.FunctionComponent = () => {
    const location = useLocation()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const isMobile = useIsMobile()
    const isTablet = useIsMobile(NIRVANA_THEME.mediaQueries.md)

    const runtimeState = useSelector(runtimeSelector)
    const embedCalculatorState = useSelector(embedCalculatorSelector)

    const [isEmailingResults, setIsEmailingResults] = useState<boolean>(false)

    // For calculator embeds make space more condensed so it looks better on customer's websites
    const isCondensedSpacing = useMemo<boolean>(() => {
        return runtimeState.isEmbed && (isMobile || isTablet)
    }, [isMobile, isTablet, runtimeState.isEmbed])

    // Remove logo and extra space, so it looks better with embeds where whitelabel is needed
    const [isWhiteLabeled] = useState<boolean>(
        !!searchParams.get(GENERAL_CONFIG.urlSearchParamsKeys.whiteLabeled),
    )

    const onPrintPdf = async () => {
        try {
            dispatch(runtimeActionCreators.setIsPDFGenerating(true))

            // So state updated, components rerendered and animations are done
            await UtilHelper.sleep(3)

            await PdfHelper.savePageAsPDF()
        } catch (e) {
        } finally {
            dispatch(runtimeActionCreators.setIsPDFGenerating(false))
        }
    }

    const pathToRightElement = useMemo<JSX.Element | null>(() => {
        // Basically this will be retriggered when we submitted popup for sending email
        // So we'd like popup to be there while it's generating
        if (!runtimeState.isGeneratingPdf) {
            setIsEmailingResults(false)
        }

        const locationPathname = embedCalculatorState.embedKey
            ? location.pathname.replace(
                  embedCalculatorState.embedKey,
                  ":embedKey",
              )
            : location.pathname

        switch (locationPathname) {
            case ROUTES_CONFIG.calculatorInconclusiveUrl:
            case ROUTES_CONFIG.calculatorIneligibleUrl:
            case ROUTES_CONFIG.embedCalculatorInconclusiveUrl:
            case ROUTES_CONFIG.embedCalculatorIneligibleUrl:
                const mainCalculatorPageRoute = embedCalculatorState.embedKey
                    ? ROUTES_CONFIG.embedCalculatorUrl.replace(
                          ":embedKey",
                          embedCalculatorState.embedKey,
                      )
                    : ROUTES_CONFIG.calculatorUrl

                return isMobile ? (
                    <ButtonElement
                        onClick={() => navigate(mainCalculatorPageRoute)}
                        label="Retry"
                        type="text"
                        size="middle"
                        icon={refreshIcon}
                        htmlType="button"
                    />
                ) : (
                    <div>
                        Made a mistake?{" "}
                        <Link
                            to={mainCalculatorPageRoute}
                            className="text-semibold"
                        >
                            Review your info and try again
                        </Link>
                    </div>
                )

            case ROUTES_CONFIG.calculatorSuccessUrl:
            case ROUTES_CONFIG.embedCalculatorSuccessUrl:
                return (
                    <div
                        data-html2canvas-ignore="true"
                        className="flex items-center"
                    >
                        <ButtonElement
                            className="mr-14px md:mr-0px"
                            onClick={() => setIsEmailingResults(true)}
                            label="Email"
                            type="primary"
                            size="middle"
                            icon={emailIcon}
                            htmlType="button"
                        />

                        <ButtonElement
                            className="md:hidden"
                            onClick={onPrintPdf}
                            isLoading={runtimeState.isGeneratingPdf}
                            label="Print"
                            type="primary"
                            size="middle"
                            icon={printIcon}
                            htmlType="button"
                        />
                    </div>
                )

            case ROUTES_CONFIG.calculatorCheckoutSuccessUrl:
                return (
                    <ButtonElement
                        onClick={() =>
                            navigate(
                                embedCalculatorState.managementKey
                                    ? ROUTES_CONFIG.embedCalculatorManagementUrl.replace(
                                          ":managementKey",
                                          embedCalculatorState.managementKey,
                                      )
                                    : ROUTES_CONFIG.embedCalculatorManagementLoginUrl,
                            )
                        }
                        label="Manage subscription"
                        type="primary"
                        size="middle"
                        icon={!isMobile ? editIcon : undefined}
                        htmlType="button"
                    />
                )

            default:
                return null
        }
    }, [
        location.pathname,
        isMobile,
        runtimeState.isGeneratingPdf,
        embedCalculatorState.embedKey,
        embedCalculatorState.managementKey,
    ])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location.pathname])

    return (
        <>
            <div
                className={`
                    flex items-center justify-between pr-50px pb-32px pl-120px
                    md:pr-30px md:pl-30px
                    ${isCondensedSpacing ? "md:pb-24px" : "md:pb-32px"}
                    ${!isWhiteLabeled ? "pt-40px" : ""}
                `}
            >
                {isWhiteLabeled ? (
                    <div className="flex-1" />
                ) : (
                    <a
                        href={GENERAL_CONFIG.mainWebsiteUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="mr-60px"
                    >
                        <img
                            src={logo}
                            alt="Nirvana Mental Health"
                            title="Nirvana Mental Health"
                        />
                    </a>
                )}

                {pathToRightElement}
            </div>

            <EmailPdfResultsPopupComponent
                isActive={isEmailingResults}
                onDone={() => setIsEmailingResults(false)}
            />
        </>
    )
}
