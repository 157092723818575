import moment from "moment"

export const DEMO_CONFIG = {
    clients: {
        successResult: {
            firstName: "Alfred",
            lastName: "Arnoldson",
            // Need to use moment here, not new Date()
            // Since moment is parsing input without offset in local time, without adjusting (https://momentjs.com/docs/#/parsing/)
            // Otherwise date is related to timezone and might be off by 1 day depending on user location and current time
            dob: moment("2000-01-01").toDate(),
            payer: {
                id: 261,
                insuranceName: "Aetna",
                payerId: "60054",
            } as IPayer,
            healthProvider: {
                id: 116499,
                firstName: "Foxy",
                lastName: "Mcpherson",
                middleName: "G",
                name: "Foxy G Mcpherson",
                npi: "1234567893",
            } as IHealthProvider,
            memberId: "ALFREDTEST",
            isDemoData: true,
        } as ICalculatorInputData,
    },
}
