import { all, fork, put, takeLatest } from "redux-saga/effects"
import { call } from "typed-redux-saga"
import { UtilHelper } from "nirvana-react-elements"

import {
    intakeDemoActionCreators,
    IntakeDemoActions,
} from "../actions/intakeDemo.actions"
import { runtimeActionCreators } from "../actions/runtime.actions"
import { IntakeDemoService } from "../services/intakeDemo.service"
import { ToastrHelper } from "../helpers/toastr.helper"
import { IntakeDemoFlow } from "../config/intakeDemo.config"
import { AvailablePlanStatus } from "../config/general.config"

////////////////////////////////////////////////////////////////////////////////////////// INTAKE DEMO AUTH
function* intakeDemoStartAuthSaga() {
    yield takeLatest(
        IntakeDemoActions.INTAKE_DEMO_AUTH_START,
        intakeDemoStartAuthSagaHandler,
    )
}

function* intakeDemoStartAuthSagaHandler<
    T extends ISagaAction & {
        authToken: string
    },
>(action: T) {
    yield put(
        runtimeActionCreators.startLoading("intakeDemoStartAuthSagaHandler"),
    )

    try {
        const result = yield* call(
            IntakeDemoService.authorize,
            action.payload.authToken,
        )

        if (result) {
            yield put(
                intakeDemoActionCreators.authSuccess(action.payload.authToken),
            )

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(action.payload)

            ToastrHelper.error("Unfortunately auth failed.")

            yield put(intakeDemoActionCreators.authReset())
        }
    } catch (e) {
    } finally {
        yield put(
            runtimeActionCreators.stopLoading("intakeDemoStartAuthSagaHandler"),
        )
    }
}
////////////////////////////////////////////////////////////////////////////////////////// INTAKE DEMO AUTH

////////////////////////////////////////////////////////////////////////////////////////// INTAKE DEMO GET COVERAGE
function* intakeDemoGetCoverageSaga() {
    yield takeLatest(
        IntakeDemoActions.INTAKE_DEMO_GET_COVERAGE,
        intakeDemoGetCoverageSagaHandler,
    )
}

function* intakeDemoGetCoverageSagaHandler<
    T extends ISagaAction & {
        initialData: IGetCoverageInitialData
        payerData?: IGetCoveragePayerData
    },
>(action: T) {
    yield put(
        runtimeActionCreators.startLoading("intakeDemoGetCoverageSagaHandler"),
    )

    try {
        // Sleep for X seconds here so breathing spinner stays longer during this process
        yield call(UtilHelper.sleep, 3)

        const result = yield* call(
            IntakeDemoService.getCoverage,
            action.payload.initialData,
            action.payload.payerData,
        )

        if (result?.planStatus === AvailablePlanStatus.active) {
            yield put(intakeDemoActionCreators.setCoverage(result))

            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(action.payload)

            yield put(
                intakeDemoActionCreators.setCurrentFlow(
                    action.payload.payerData
                        ? IntakeDemoFlow.failure
                        : IntakeDemoFlow.payerData,
                ),
            )
        }
    } catch (e) {
    } finally {
        yield put(
            runtimeActionCreators.stopLoading(
                "intakeDemoGetCoverageSagaHandler",
            ),
        )
    }
}
////////////////////////////////////////////////////////////////////////////////////////// INTAKE DEMO GET COVERAGE

export default function* rootSaga() {
    yield all([fork(intakeDemoStartAuthSaga), fork(intakeDemoGetCoverageSaga)])
}
