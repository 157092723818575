/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"
import {
    IStepperStepProps,
    PrimaryText,
    InputElement,
    VALIDATION_CONFIG,
    ButtonElement,
    INPUT_MASKS_CONFIG,
    NirvanaBreathingLoaderElement,
} from "nirvana-react-elements"
import { useForm } from "react-hook-form"
import { useSelector } from "react-redux"

import { runtimeSelector } from "../../../selectors/runtime.selector"
import { RuntimeHelper } from "../../../helpers/runtime.helper"

import shieldIcon from "../../../assets/images/intakeDemo/shield.svg"
import chevronRightIcon from "../../../assets/images/intakeDemo/chevron-right.svg"

export const ClientDataStep: React.FunctionComponent<
    IStepperStepProps
> = props => {
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm()

    const runtimeState = useSelector(runtimeSelector)

    const isGetCoverageLoading = useMemo<boolean>(
        () => RuntimeHelper.isIntakeDemoGetCoverageLoading(),
        [runtimeState.isLoading],
    )

    const onSubmit = (data: any) => {
        props.onStepSubmit(data as IGetCoverageClientStepData)
    }

    return (
        <div className="relative pb-200px">
            <PrimaryText typography="h3">
                Let us help you find <br /> the care you need.
            </PrimaryText>

            <PrimaryText typography="h5" className="mt-24px">
                Enter your information
            </PrimaryText>

            <NirvanaBreathingLoaderElement isActive={isGetCoverageLoading} />

            <form onSubmit={handleSubmit(onSubmit)}>
                <InputElement
                    className="mt-44px"
                    label="First Name"
                    name="firstName"
                    reactHookControl={control}
                    reactHookValidations={{
                        required: VALIDATION_CONFIG.required,
                        maxLength: VALIDATION_CONFIG.maxLength,
                    }}
                    reactHookErrors={errors}
                />

                <InputElement
                    className="mt-44px"
                    label="Last Name"
                    name="lastName"
                    reactHookControl={control}
                    reactHookValidations={{
                        required: VALIDATION_CONFIG.required,
                        maxLength: VALIDATION_CONFIG.maxLength,
                    }}
                    reactHookErrors={errors}
                />

                <InputElement
                    className="mt-44px"
                    label="Date of Birth"
                    name="dob"
                    reactHookControl={control}
                    reactHookValidations={{
                        required: VALIDATION_CONFIG.required,
                        validate: VALIDATION_CONFIG.date,
                    }}
                    maskOptions={INPUT_MASKS_CONFIG.dob}
                    placeholder="mm/dd/yyyy"
                    mask={Date}
                    reactHookErrors={errors}
                />

                <div className="mt-44px flex items-start">
                    <InputElement
                        className="flex-1"
                        name="zip"
                        label="ZIP Code"
                        reactHookControl={control}
                        reactHookErrors={errors}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                            pattern: VALIDATION_CONFIG.zip,
                        }}
                    />

                    <div className="flex-1" />
                    <div className="flex-1" />
                </div>

                <div className="fixed z-30 left-0 bottom-0 p-24px bg-brand-offWhite w-full">
                    <div
                        onClick={() => {
                            alert(
                                "Explain that data is encrypted on all sides and not shared with anyone",
                            )
                        }}
                        className="
                            flex items-center justify-between p-16px rounded-8px bg-brand-white cursor-pointer
                            border border-solid border-brand-warmLight
                        "
                    >
                        <img src={shieldIcon} alt="Protected" />

                        <PrimaryText typography="h6">
                            How we keep your data safe
                        </PrimaryText>

                        <img src={chevronRightIcon} alt="Right" />
                    </div>

                    <div className="mt-24px">
                        <ButtonElement
                            label="Search"
                            type="primary"
                            size="large"
                            htmlType="submit"
                            buttonClassName="w-full"
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}
