import { combineReducers } from "redux"
import { reducer as toastrReducer } from "react-redux-toastr"

import { runtimeReducer } from "./runtime.reducer"
import { calculatorReducer } from "./calculator.reducer"
import { embedCalculatorReducer } from "./embedCalculator.reducer"
import { intakeDemoReducer } from "./intakeDemo.reducer"

export const rootReducer = combineReducers({
    toastr: toastrReducer,

    runtime: runtimeReducer,
    calculator: calculatorReducer,
    embedCalculator: embedCalculatorReducer,
    intakeDemo: intakeDemoReducer,
})

export type RootState = ReturnType<typeof rootReducer>
