import React from "react"
import { IFaqItem } from "nirvana-react-elements"

import { GENERAL_CONFIG } from "./general.config"

export const FAQ_CONFIG = {
    // TODO move this config to contentful at some point
    // For now just keep it here for simplicity
    calculatorGeneralError: [
        {
            question: "What can I do next?",
            answer: (
                <>
                    We unfortunately could not get details for your policy from
                    the health plan at this time. If you believe that you have
                    coverage, check the back of your insurance ID card for the
                    Member Services phone number or website. Call your insurance
                    plan or check the Member section on their website to check
                    your benefits. Be sure to ask about coverage for an
                    out-of-network mental health professional if you are
                    intending to see this provider.
                </>
            ),
        },
        {
            question:
                "What is the difference between in-network and out-of-network coverage?",
            answer: (
                <>
                    In-network mental health providers have a contract with your
                    insurance plan. Out-of-network providers are therapists and
                    professionals who do not have a contract with your insurance
                    plan. Some insurance plans give you the choice to see an
                    out-of-network provider. However, you may have to pay more
                    and get less reimbursed, depending on the plan.
                </>
            ),
        },
    ] as IFaqItem[],

    // TODO move this config to contentful at some point
    // For now just keep it here for simplicity
    calculatorNoCoverage: [
        {
            question: "Why does it indicate no mental health benefits found?",
            answer: (
                <>
                    We specifically check for active out-of-network Mental
                    Health benefits with your health plan. Some possibilities
                    may include that your policy is not currently active, you
                    may have a different current plan, your plan may not provide
                    out-of-network mental health benefits, or there may be other
                    coverage limitations. Be sure to ask about coverage for an
                    out-of-network mental health professional if you are
                    intending to see this provider.
                </>
            ),
        },
        {
            question:
                "I was seeing another therapist previously and had coverage",
            answer: (
                <>
                    The other therapist may have been in-network with your
                    insurance plan, or there may have been some other change of
                    your policy with your insurer.
                </>
            ),
        },
        {
            question:
                "What is the difference between in-network and out-of-network coverage?",
            answer: (
                <>
                    In-network mental health providers have a contract with your
                    insurance plan. Out-of-network providers are therapists and
                    professionals who do not have a contract with your insurance
                    plan. Some insurance plans give you the choice to see an
                    out-of-network provider. However, you may have to pay more
                    and get less reimbursed, depending on the plan.
                </>
            ),
        },
        {
            question: "Why do some people choose an out-of-network provider?",
            answer: (
                <>
                    An out-of-network provider may happen to be a good fit as a
                    therapist, may have lower rates, and may have more
                    flexibility.
                </>
            ),
        },
        {
            question: "What can I do next?",
            answer: (
                <>
                    If you believe that you have out-of-network mental health
                    coverage, check the back of your insurance ID card for the
                    Member Services phone number or website. Call your insurance
                    plan or check the Member section on their website. Be sure
                    to ask about coverage for an out-of-network mental health
                    professional if you are intending to see this provider.
                </>
            ),
        },
    ] as IFaqItem[],

    // TODO move this config to contentful after MVP is validated
    // For MVP just keep it here for simplicity
    embedCalculatorPracticeCreation: [
        {
            question: "How much does this cost?",
            answer: (
                <>
                    During this Beta test, there is no cost to therapists or
                    clients. We are evaluating the product and how to best
                    deliver superbill filing. We anticipate in the future a
                    nominal fee for the client, with a potential option for
                    practices that want to sponsor clients. We would notify
                    clients at least 2 months in advance before including
                    charges and would allow any client to opt-out.
                </>
            ),
        },
        {
            question: "What are the therapists’ responsibilities?",
            answer: (
                <>
                    The requirements are to opt-in, enter your practice
                    information and W-9. We will provide you with HTML code for
                    your widget and a unique link to access the tool on its own,
                    just like the Reimbursement Calculator; you can embed the
                    widget in your website or send the link directly to a
                    client. Nirvana will handle the client’s superbill
                    submission to the health plans and follow-up.
                    <br />
                    <br />
                    We ask that you are available for contact if we have
                    questions regarding the format of your superbill, and if we
                    are unable to correct a claim rejection ourselves, such as a
                    health plan requesting missing information or information
                    requiring your validation.
                    <br />
                    <br />
                    We also ask that we may contact you periodically for
                    feedback on this Beta program. Your feedback helps ensure
                    that our product and process is effective.
                </>
            ),
        },
        {
            question: "What are the clients’ responsibilities?",
            answer: (
                <>
                    Once a client opts in and sets up an account, they are able
                    to directly upload each superbill to Nirvana. The client is
                    responsible for submitting superbills in a timely manner
                    (within 90 days of a session). We can only process
                    superbills that the client uploads.
                    <br />
                    <br />
                    Nirvana will track the submission and processing of the
                    superbill and keep the client informed of their
                    reimbursement status or any denials.
                </>
            ),
        },
        {
            question: "Can clients opt-out?",
            answer: (
                <>
                    Yes! A client can opt-out anytime by contacting{" "}
                    <a
                        href={`mailto:${GENERAL_CONFIG.supportEmail}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {GENERAL_CONFIG.supportEmail}
                    </a>
                    . A client can also stop uploading new superbills.
                </>
            ),
        },
    ] as IFaqItem[],

    // TODO move this config to contentful after MVP is validated
    // For MVP just keep it here for simplicity
    embedCalculatorClientEnrollment: [
        {
            question: "How much does this cost?",
            answer: (
                <>
                    During this Beta test, there is no cost. We are evaluating
                    the product and how to best deliver superbill filing. We
                    anticipate in the future a nominal fee. We will notify you
                    at least 2 months in advance before including charges and
                    would allow you to continue or to opt-out.
                </>
            ),
        },
        {
            question: "What are therapist responsibilities?",
            answer: (
                <>
                    Your therapist will still need to send you a superbill
                    (receipt) that lists therapy session information.
                </>
            ),
        },
        {
            question: "What are your responsibilities?",
            answer: (
                <>
                    Once you set up your account, you will be able to upload
                    each superbill to Nirvana. We will handle working with your
                    health plan and update you with status changes regarding the
                    reimbursement. You will need to upload your superbill within
                    90 days of your session in order to maximize your chances of
                    reimbursement.
                </>
            ),
        },
        {
            question: "Can Nirvana guarantee reimbursement?",
            answer: (
                <>
                    No - unfortunately, your health plan is the ultimate
                    determiner of reimbursement. Reimbursement can be some, all,
                    or none of the amount you paid your therapist. Reimbursement
                    depends on many factors such as your coverage, your
                    insurance policy, your medical spending this year, your
                    therapist’s fees, and the location of your therapist.
                    Nirvana will submit the session to the health plan and
                    provide updates on its status, but we cannot guarantee
                    reimbursement.
                </>
            ),
        },
        {
            question: "Who will reimburse me?",
            answer: (
                <>
                    Reimbursement will come from the health plan directly to you
                    via check. Kindly note, you may receive multiple
                    reimbursements in one check.
                </>
            ),
        },
        {
            question: "How long will it take to get reimbursed?",
            answer: (
                <>
                    It can take anywhere from 3 - 90 days to process a claim,
                    but most insurance companies take about one week. After the
                    claim is processed, it may take another week or so to have
                    the reimbursement check in your mailbox. We recommend
                    uploading your superbill the same week as your session so
                    you can get your reimbursement as soon as possible!
                </>
            ),
        },
        {
            question: "Can I opt-out?",
            answer: (
                <>
                    Yes. You can opt-out anytime by contacting{" "}
                    <a
                        href={`mailto:${GENERAL_CONFIG.supportEmail}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {GENERAL_CONFIG.supportEmail}
                    </a>
                    . You can also stop uploading new superbills.
                </>
            ),
        },
    ] as IFaqItem[],
}
