/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ButtonElement, PrimaryText, UtilHelper } from "nirvana-react-elements"

import { intakeDemoSelector } from "../../selectors/intakeDemo.selector"
import { intakeDemoActionCreators } from "../../actions/intakeDemo.actions"
import { IntakeDemoFlow } from "../../config/intakeDemo.config"
import { AvailablePlanStatus } from "../../config/general.config"

import emailIcon from "../../assets/images/icons/mail-white.svg"
import printIcon from "../../assets/images/icons/print.svg"
import coinsIcon from "../../assets/images/intakeDemo/coins-dark.svg"
import shieldIcon from "../../assets/images/intakeDemo/shield-dark.svg"
import idCardIcon from "../../assets/images/intakeDemo/id-card-dark.svg"
import chevronRightIcon from "../../assets/images/intakeDemo/chevron-right.svg"
import questionIcon from "../../assets/images/intakeDemo/question.svg"

export const IntakeDemoSuccessComponent: React.FunctionComponent = () => {
    const dispatch = useDispatch()

    const intakeDemoState = useSelector(intakeDemoSelector)

    // On mount check if successful result is available
    // If not -> go to first step
    useEffect(() => {
        if (
            intakeDemoState?.result?.planStatus !== AvailablePlanStatus.active
        ) {
            dispatch(
                intakeDemoActionCreators.setCurrentFlow(
                    IntakeDemoFlow.initialData,
                ),
            )
        }
    }, [])

    return (
        <div className="relative">
            <PrimaryText typography="h3">Congrats! You’re covered.</PrimaryText>

            {/*COVERAGE DETAILS*/}
            {intakeDemoState.result && (
                <div className="mt-24px">
                    <PrimaryText typography="h5">
                        Your coverage details:
                    </PrimaryText>

                    {/*MEMBER OBLIGATION*/}
                    {intakeDemoState.result.memberObligation !== null && (
                        <div className="flex items-center mt-28px">
                            <img
                                className="mr-16px"
                                src={coinsIcon}
                                alt="Member obligation"
                            />

                            <PrimaryText typography="subtitle">
                                Your{" "}
                                <span className="ext-semibold text-brand-vibrantPurple">
                                    payment amount
                                </span>{" "}
                                is{" "}
                                {UtilHelper.getFormattedMoney(
                                    intakeDemoState.result.memberObligation,
                                    true,
                                )}
                            </PrimaryText>
                        </div>
                    )}

                    {/*PAYER*/}
                    {intakeDemoState.result.payer !== null && (
                        <div className="flex items-center mt-28px">
                            <img
                                className="mr-16px"
                                src={shieldIcon}
                                alt="Payer"
                            />

                            <PrimaryText typography="subtitle">
                                Your{" "}
                                <span className="ext-semibold text-brand-vibrantPurple">
                                    payer
                                </span>{" "}
                                is {intakeDemoState.result.payer.insuranceName}{" "}
                                ({intakeDemoState.result.payer.payerId})
                            </PrimaryText>
                        </div>
                    )}

                    {/*GROUP NAME*/}
                    {intakeDemoState.result.groupName !== null && (
                        <div className="flex items-center mt-28px">
                            <img
                                className="mr-16px"
                                src={idCardIcon}
                                alt="Group Name"
                            />

                            <PrimaryText typography="subtitle">
                                Your{" "}
                                <span className="ext-semibold text-brand-vibrantPurple">
                                    plan sponsor
                                </span>{" "}
                                is {intakeDemoState.result.groupName}
                            </PrimaryText>
                        </div>
                    )}
                </div>
            )}

            {/*BOTTOM DISCLAIMER WITH ACTION*/}
            <div className="fixed bottom-0 left-0 p-24px w-full bg-brand-offWhite">
                <PrimaryText typography="caption">
                    A quote of benefits does not guarantee payment. Payment of
                    benefits are subject to all terms, conditions, limitations
                    and exclusions of member’s contract at the time of service.
                </PrimaryText>

                <div
                    className="
                        mt-24px flex items-center cursor-pointer p-16px rounded-8px bg-brand-white
                        border border-solid border-brand-warmLight
                    "
                    onClick={() => alert("Show Frequently Asked Questions")}
                >
                    <img src={questionIcon} alt="FAQ" className="mr-8px" />

                    <PrimaryText className="flex-1" typography="h6">
                        FAQ
                    </PrimaryText>

                    <img src={chevronRightIcon} alt="FAQ" />
                </div>

                <ButtonElement
                    className="mt-24px"
                    label="Next: Book appointment"
                    type="primary"
                    size="large"
                    htmlType="button"
                    buttonClassName="w-full"
                    onClick={() =>
                        alert("Provide a form to book appointment for client")
                    }
                />
            </div>

            {/*TOP EMAIL / PRINT BUTTONS*/}
            <div
                data-html2canvas-ignore="true"
                className="fixed top-20px right-24px z-10 flex items-center"
            >
                <ButtonElement
                    className="mr-8px"
                    onClick={() =>
                        alert("Collect email of client and email results")
                    }
                    label="Email"
                    type="primary"
                    size="middle"
                    icon={emailIcon}
                    htmlType="button"
                />

                <ButtonElement
                    onClick={() =>
                        alert("Generate PDF and save it to client's device")
                    }
                    label="Print"
                    type="primary"
                    size="middle"
                    icon={printIcon}
                    htmlType="button"
                />
            </div>
        </div>
    )
}
