import {
    BrowserStorageHelper,
    BrowserStorageType,
    UtilHelper,
} from "nirvana-react-elements"

import { HttpHelper } from "../helpers/http.helper"
import { API_ROUTES } from "../config/routes.config"
import { GENERAL_CONFIG } from "../config/general.config"

export class IntakeDemoService {
    /**
     * Check that provided auth token is valid
     */
    static async authorize(authToken: string): Promise<any> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.intakeDemoAuthorize,
            undefined,
            "POST",
            {
                [GENERAL_CONFIG.headers.authorization]: authToken,
            },
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Get coverage data based on collected data
     */
    static async getCoverage(
        initialData: IGetCoverageInitialData,
        payerData?: IGetCoveragePayerData,
    ): Promise<ICalculationsResult | undefined> {
        const { firstName, lastName, dob } = initialData.clientData

        const result = await HttpHelper.sendRequest(
            API_ROUTES.intakeDemoGetCoverage,
            {
                firstName,
                lastName,
                dob: UtilHelper.dateToMysqlFormat(dob),

                ...(payerData
                    ? {
                          payerId: payerData.insuranceData.payer.id,
                          memberId: payerData.insuranceData.memberId,
                      }
                    : {}),
            },
            "GET",
            {
                [GENERAL_CONFIG.headers.authorization]:
                    BrowserStorageHelper.get(
                        GENERAL_CONFIG.browserStorageKeys.tokenKey,
                        undefined,
                        BrowserStorageType.sessionStorage,
                    ),
            },
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }
}
