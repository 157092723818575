import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import {
    useScrollFix,
    PageHelmetElement,
    PrimaryText,
    UtilHelper,
    ButtonElement,
} from "nirvana-react-elements"

import { AvailableSessionRatesState } from "../../config/sessionRates.config"
import { SessionRatesDataCollectionComponent } from "./sessionRatesDataCollection.component"
import { SessionRatesResultComponent } from "./sessionRatesResult.component"
import { runtimeSelector } from "../../selectors/runtime.selector"
import { EmailPdfResultsPopupComponent } from "../popups/emailPdfResultsPopup.component"
import { GENERAL_CONFIG } from "../../config/general.config"

import emailIcon from "../../assets/images/icons/mail-white.svg"

export const SessionRatesComponent: React.FunctionComponent = () => {
    useScrollFix()

    const runtimeState = useSelector(runtimeSelector)

    const [initialized, setInitialized] = useState<boolean>(false)
    const [isEmailingResults, setIsEmailingResults] = useState<boolean>(false)

    const [currentVisualState, setCurrentVisualState] =
        useState<AvailableSessionRatesState>(AvailableSessionRatesState.input)

    const [inputData, setInputData] = useState<ISessionRatesInputData>()

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setInitialized(true)
        }, 1000)

        return () => {
            clearTimeout(timeoutId)
        }
    }, [])

    const reset = () => {
        UtilHelper.scrollToPageTop()

        setCurrentVisualState(AvailableSessionRatesState.resultHiding)

        setTimeout(() => {
            setCurrentVisualState(AvailableSessionRatesState.input)
        }, 1.5 * 1000)
    }

    const onDataCollected = (data: ISessionRatesInputData) => {
        UtilHelper.scrollToPageTop()

        setCurrentVisualState(AvailableSessionRatesState.inputHiding)

        setInputData(data)

        setTimeout(() => {
            setCurrentVisualState(AvailableSessionRatesState.result)
        }, 1.5 * 1000)
    }

    return (
        <div className="w-full">
            <div className="primary-container">
                <PageHelmetElement
                    title="Nirvana Health"
                    defaultPageTitle="Session Rates Estimator"
                />

                {/*EMAIL PDF BTN*/}
                {currentVisualState === AvailableSessionRatesState.result ? (
                    <div
                        data-html2canvas-ignore="true"
                        className="flex items-center absolute top-40px right-50px md:right-30px"
                    >
                        <ButtonElement
                            label="Email"
                            type="primary"
                            size="middle"
                            icon={emailIcon}
                            htmlType="button"
                            isLoading={runtimeState.isGeneratingPdf}
                            onClick={() => setIsEmailingResults(true)}
                        />
                    </div>
                ) : null}

                <div className="md:px-30px">
                    <PrimaryText typography="h1">
                        Session Rates Estimator
                    </PrimaryText>

                    <div className="mt-16px">
                        <PrimaryText typography="textBold">
                            Get an estimate of how much you should charge for
                            your private practice
                        </PrimaryText>

                        <PrimaryText typography="text" className="md:mt-16px">
                            Knowing what to charge fairly is hard especially
                            while you are building your private practice.
                            <br />
                            We built the Session Rates Estimator to help you
                            understand what you need to charge
                            <br />
                            to build a sustainable business and what others are
                            charging in your area.
                        </PrimaryText>

                        <PrimaryText typography="text" className="md:mt-16px">
                            Interested in learning more about Nirvana?{" "}
                            <a
                                href={GENERAL_CONFIG.mainWebsiteUrl}
                                rel="noopener noreferrer"
                                target="_blank"
                                className="underline"
                            >
                                Click here
                            </a>
                            .
                        </PrimaryText>
                    </div>
                </div>

                {initialized ? (
                    <div
                        className={`
                            pb-100px bg-brand-white rounded-8px
                            ${
                                !runtimeState.isGeneratingPdf
                                    ? currentVisualState ===
                                          AvailableSessionRatesState.input ||
                                      currentVisualState ===
                                          AvailableSessionRatesState.result
                                        ? "animate-popIn"
                                        : "animate-spinOut"
                                    : ""
                            }
                        `}
                    >
                        <SessionRatesResultComponent
                            className={
                                !runtimeState.isGeneratingPdf
                                    ? currentVisualState ===
                                          AvailableSessionRatesState.result ||
                                      currentVisualState ===
                                          AvailableSessionRatesState.resultHiding
                                        ? "block"
                                        : "hidden"
                                    : ""
                            }
                            onReset={reset}
                            inputData={inputData}
                        />

                        <SessionRatesDataCollectionComponent
                            onDataCollected={onDataCollected}
                            className={
                                !runtimeState.isGeneratingPdf
                                    ? currentVisualState ===
                                          AvailableSessionRatesState.input ||
                                      currentVisualState ===
                                          AvailableSessionRatesState.inputHiding
                                        ? "block"
                                        : "hidden"
                                    : "hidden"
                            }
                        />
                    </div>
                ) : null}
            </div>

            <EmailPdfResultsPopupComponent
                isActive={isEmailingResults}
                onDone={() => setIsEmailingResults(false)}
            />
        </div>
    )
}
