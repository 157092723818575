import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import {
    useScrollFix,
    PageHelmetElement,
    NIRVANA_COLORS,
    useIsMobile,
    NIRVANA_THEME,
    FaqElement,
} from "nirvana-react-elements"

import { calculatorSelector } from "../../../selectors/calculator.selector"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { AnalyticsHelper } from "../../../helpers/analytics.helper"
import { AnalyticsEvent } from "../../../config/analytics.config"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { CalculatorErrorCardElement } from "../../elements/calculatorErrorCard.element"
import { FAQ_CONFIG } from "../../../config/faq.config"

export const CalculatorInconclusiveComponent: React.FunctionComponent = () => {
    useScrollFix()

    const isTablet = useIsMobile(NIRVANA_THEME.mediaQueries.md)

    const navigate = useNavigate()

    const { embedKey } = useParams()

    const calculatorState = useSelector(calculatorSelector)

    useEffect(() => {
        if (!calculatorState.data) {
            navigate(
                embedKey
                    ? ROUTES_CONFIG.embedCalculatorUrl.replace(
                          ":embedKey",
                          embedKey,
                      )
                    : ROUTES_CONFIG.calculatorUrl,
            )
        } else {
            AnalyticsHelper.event(AnalyticsEvent.calculatorResultError)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="w-full mt-10px">
            <div className="primary-container pb-40px">
                <PageHelmetElement
                    title="Error"
                    defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
                />

                <div className="md:mx-16px">
                    <CalculatorErrorCardElement />

                    <FaqElement
                        className="mt-48px"
                        isColumnLayout={!isTablet}
                        itemsWrapperClassName="mt-36px"
                        singleItemClassName="mb-36px"
                        questionTypography="textSemibold"
                        answerTypography="text"
                        answerColor={NIRVANA_COLORS.brand.primary}
                        items={FAQ_CONFIG.calculatorGeneralError}
                    />
                </div>
            </div>
        </div>
    )
}
