/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import {
    PrimaryText,
    InputElement,
    VALIDATION_CONFIG,
    RadioElement,
    GoogleHelper,
    IGoogleAddressPlaceResult,
    IGoogleAddressLookupSuggestion,
    GoogleAddressLookupElement,
    SelectElement,
    USA_STATES_CONFIG_SELECT,
    ButtonElement,
} from "nirvana-react-elements"

import { runtimeSelector } from "../../../selectors/runtime.selector"
import {
    AvailableTaxIdType,
    CALCULATOR_CHECKOUT_CONFIG,
} from "../../../config/calculatorCheckout.config"
import { TOOLTIPS_CONFIG } from "../../../config/tooltips.config"

export const EmbedCalculatorPracticeInformationStep: React.FunctionComponent<{
    className?: string
    onStepBack?: () => void
    onStepSubmit: (practiceData: ICalculatorPracticeCreationDetailsData) => void
}> = props => {
    const {
        handleSubmit,
        formState: { errors },
        control,
        register,
        setValue,
        watch,
    } = useForm()

    const dataWatcher = watch()
    const practiceEhrWatcher = watch("practiceEhr")

    const runtimeState = useSelector(runtimeSelector)

    const [isNotSupportedEhr, setIsNotSupportedEhr] = useState<boolean>(false)

    //////////////////////////////////////////////////////////////////// GOOGLE ADDRESS HANDLERS
    const onGoogleAddressSelected = (result?: IGoogleAddressPlaceResult) => {
        if (!result) {
            return
        }

        const street = GoogleHelper.getStreetFromAutocompleteDetails(result)

        if (street) {
            setValue("street", street, {
                shouldValidate: true,
            })
        }

        const city = GoogleHelper.getCityFromAutocompleteDetails(result)

        if (city) {
            setValue("city", city, {
                shouldValidate: true,
            })
        }

        const state = GoogleHelper.getStateFromAutocompleteDetails(result)

        if (state) {
            setValue("state", state, {
                shouldValidate: true,
            })
        }

        const zip = GoogleHelper.getZipFromAutocompleteDetails(result)

        if (zip) {
            setValue("zip", zip, {
                shouldValidate: true,
            })
        }
    }

    const onGoogleAddressSearchChanged = (search: string) => {
        setValue("street", search)
    }

    const getGoogleAddressDisplayValue = (
        item: IGoogleAddressLookupSuggestion,
    ) => item.structured_formatting.main_text

    //////////////////////////////////////////////////////////////////// GOOGLE ADDRESS HANDLERS

    // Passing empty ehrType will trigger "not supported ehr" state
    const onEhrTypeSelected = (ehrType = "") => {
        setIsNotSupportedEhr(!ehrType)

        setValue("practiceEhr", ehrType, { shouldValidate: !!ehrType })
    }

    const onCheckout = data => {
        props.onStepSubmit(data)
    }

    return (
        <div className={props.className}>
            <PrimaryText typography="h4">Practice Information</PrimaryText>

            <form onSubmit={handleSubmit(onCheckout)} noValidate={true}>
                <InputElement
                    className="mt-36px"
                    name="practiceName"
                    label="What is the legal name of your practice?"
                    reactHookControl={control}
                    reactHookErrors={errors}
                    reactHookValidations={{
                        required: VALIDATION_CONFIG.required,
                        minLength: VALIDATION_CONFIG.minLength,
                        maxLength: VALIDATION_CONFIG.maxLength,
                    }}
                />

                <div className="flex items-start mt-36px md:block">
                    <InputElement
                        className="flex-1 mr-16px md:mr-0px"
                        name="groupNPI"
                        type="number"
                        label="What is your practice's NPI?"
                        reactHookControl={control}
                        reactHookErrors={errors}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                            pattern: VALIDATION_CONFIG.npi,
                        }}
                        tooltip={TOOLTIPS_CONFIG.embedCalculator.practiceNPIs}
                    />

                    <InputElement
                        className="flex-1 md:mt-36px"
                        name="taxID"
                        label="What is your practice's EIN?"
                        tooltip={TOOLTIPS_CONFIG.embedCalculator.practiceTaxId}
                        reactHookControl={control}
                        reactHookErrors={errors}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                            pattern: VALIDATION_CONFIG.ein,
                        }}
                    />

                    {/*RIGHT NOW IT's JUST EIN ALWAYS FOR SIMPLICITY*/}
                    <RadioElement
                        className="hidden"
                        name="taxIdType"
                        items={CALCULATOR_CHECKOUT_CONFIG.availableTaxIdTypes}
                        defaultValue={AvailableTaxIdType.EIN}
                        reactHookFormRegister={register}
                        reactHookFormSet={setValue}
                        reactHookErrors={errors}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                        }}
                    />
                </div>

                <GoogleAddressLookupElement
                    className="mt-36px"
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
                    name="street"
                    label="Your Practice Address"
                    placeholder="555 Database Rd."
                    onSelected={onGoogleAddressSelected}
                    onSearchChanged={onGoogleAddressSearchChanged}
                    externalValue={dataWatcher.street}
                    reactHookFormRegister={register}
                    reactHookFormErrors={errors}
                    getGoogleAddressDisplayValue={getGoogleAddressDisplayValue}
                    validations={{
                        required: VALIDATION_CONFIG.required,
                        minLength: VALIDATION_CONFIG.minLength,
                        maxLength: VALIDATION_CONFIG.maxLength,
                    }}
                />

                <div className="flex items-start mt-36px md:block">
                    <InputElement
                        className="flex-1 mr-16px md:mr-0px"
                        name="city"
                        label="City"
                        placeholder="Santa Fe"
                        reactHookControl={control}
                        reactHookErrors={errors}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                            minLength: VALIDATION_CONFIG.minLength,
                            maxLength: VALIDATION_CONFIG.maxLength,
                        }}
                    />

                    <div className="flex-1 flex items-start md:mt-36px">
                        <SelectElement
                            name="state"
                            className="mr-25px flex-1"
                            label="State"
                            itemRenderer={item => item}
                            externalValue={dataWatcher.state}
                            reactHookFormRegister={register}
                            reactHookFormSet={setValue}
                            reactHookFormErrors={errors}
                            options={USA_STATES_CONFIG_SELECT}
                            validations={{
                                required: VALIDATION_CONFIG.required,
                            }}
                        />

                        <InputElement
                            className="flex-1"
                            name="zip"
                            label="ZIP Code"
                            placeholder="87505"
                            reactHookControl={control}
                            reactHookErrors={errors}
                            reactHookValidations={{
                                required: VALIDATION_CONFIG.required,
                                pattern: VALIDATION_CONFIG.zip,
                            }}
                        />
                    </div>
                </div>

                <div className="mt-48px">
                    <PrimaryText typography="h4">Select your EHR</PrimaryText>

                    <div className="flex items-center flex-wrap sm:block">
                        {CALCULATOR_CHECKOUT_CONFIG.availableEhrSystems.map(
                            (
                                item: IPdfParsingAvailableEhrSystem,
                                index: number,
                            ) => (
                                <div
                                    key={index}
                                    className={`
                                        flex-basis-50 mt-24px
                                        ${!(index % 2) ? "pr-16px" : "pl-16px"}
                                        sm:px-0px
                                    `}
                                >
                                    <div
                                        className={`
                                            relative bg-brand-white h-120px rounded-8px
                                            ${
                                                item.type === practiceEhrWatcher
                                                    ? "border-2px border-solid border-brand-warmShadow"
                                                    : "cursor-pointer"
                                            }
                                            sm:h-50px
                                        `}
                                        onClick={() =>
                                            onEhrTypeSelected(item.type)
                                        }
                                    >
                                        <img
                                            src={item.img}
                                            alt={item.type}
                                            className="absolute screen-center"
                                        />
                                    </div>
                                </div>
                            ),
                        )}

                        {/* Not supported EHR*/}
                        <div
                            className={`
                                flex-basis-50 mt-24px
                                ${
                                    !(
                                        CALCULATOR_CHECKOUT_CONFIG
                                            .availableEhrSystems.length % 2
                                    )
                                        ? "pr-16px"
                                        : "pl-16px"
                                }
                                sm:px-0px
                            `}
                        >
                            <div
                                className={`
                                    relative bg-brand-white h-120px rounded-8px
                                    ${
                                        isNotSupportedEhr
                                            ? "border-2px border-solid border-brand-warmShadow"
                                            : "cursor-pointer"
                                    }
                                    sm:h-50px
                                `}
                                onClick={() => onEhrTypeSelected()}
                            >
                                <div
                                    className={`
                                        flex items-center justify-center flex-col h-120px
                                        sm:h-60px
                                    `}
                                >
                                    <PrimaryText typography="textBold">
                                        Don't see your EHR?
                                    </PrimaryText>

                                    <PrimaryText>
                                        Let us know what you work with!
                                    </PrimaryText>
                                </div>
                            </div>
                        </div>
                    </div>

                    <InputElement
                        className={`
                            mt-24px
                            ${!isNotSupportedEhr ? "hidden" : ""}
                        `}
                        name="practiceEhr"
                        placeholder="Please type your EHR name"
                        reactHookControl={control}
                        reactHookErrors={errors}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                            minLength: VALIDATION_CONFIG.minLength,
                            maxLength: VALIDATION_CONFIG.maxLength,
                        }}
                    />
                </div>

                <div className="mt-24px flex items-center">
                    {props.onStepBack ? (
                        <div
                            className="flex-1 mr-16px text-brand-primary cursor-pointer"
                            onClick={props.onStepBack}
                        >
                            ‹ Back
                        </div>
                    ) : (
                        <div className="flex-1" />
                    )}

                    <ButtonElement
                        label="Save"
                        buttonClassName="w-100px"
                        size="large"
                        type="primary"
                        htmlType="submit"
                        disabled={!practiceEhrWatcher}
                        isLoading={!!runtimeState.isLoading.length}
                    />
                </div>
            </form>
        </div>
    )
}
