import {
    BrowserStorageHelper,
    BrowserStorageType,
} from "nirvana-react-elements"

import { GENERAL_CONFIG } from "../config/general.config"
import { IntakeDemoFlow } from "../config/intakeDemo.config"

export class IntakeDemoStateClass implements IIntakeDemoState {
    isAuthenticated = !!BrowserStorageHelper.get(
        GENERAL_CONFIG.browserStorageKeys.tokenKey,
        undefined,
        BrowserStorageType.sessionStorage,
    )
    currentFlow = IntakeDemoFlow.initialData
    result = null
}
