import React, { useEffect, useState } from "react"
import { CheckboxElement, InputElement } from "nirvana-react-elements"

export const ExpensesConfigurationComponent: React.FunctionComponent<
    IExpensesConfigurationComponentProps
> = props => {
    const [expensesData, setExpensesData] = useState<IExpensesData[]>([])

    // Monitor for data change and notify parent
    useEffect(() => {
        props.onChange(expensesData)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expensesData])

    const checkExpenseItemSelected = (
        expenseItem: ISessionRateExpenseOption,
    ): IExpensesData | undefined =>
        expensesData.find(item => item.key === expenseItem.key)

    const onCheckBoxChange = (
        expenseItem: ISessionRateExpenseOption,
        newValue: boolean,
    ) => {
        if (!newValue) {
            // Remove from our array of data

            setExpensesData(current =>
                current.filter(item => item.key !== expenseItem.key),
            )
        } else {
            // Add to our array of data

            const codeAlreadyExists = checkExpenseItemSelected(expenseItem)

            if (!codeAlreadyExists) {
                setExpensesData(current => [
                    ...current,
                    {
                        key: expenseItem.key,
                        checked: true,
                        amount: expenseItem.defaultAmountValue || undefined,
                        money: expenseItem.defaultMoneyValue || undefined,
                    },
                ])
            }
        }
    }

    const onInputChange = (
        expenseItem: ISessionRateExpenseOption,
        property: "amount" | "money",
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const neededCode = checkExpenseItemSelected(expenseItem)

        if (!neededCode) {
            return
        }

        neededCode[property] = parseInt(event.target.value) || 0

        setExpensesData(current => [
            ...current.filter(item => item.key !== expenseItem.key),

            neededCode,
        ])
    }

    return (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            {props.options.map((item, index: number) => (
                <div
                    key={index}
                    className={`
                        flex items-center md:block
                        ${index > 0 ? "mt-15px md:mt-40px" : ""}
                    `}
                >
                    <div className="flex-1 mr-25px md:mr-0px">
                        <CheckboxElement
                            name={item.key}
                            onChange={onCheckBoxChange.bind({}, item)}
                            label={item.checkboxLabel}
                        />
                    </div>

                    <div
                        className={`
                            flex-1 flex items-center min-h-75px md:mt-15px
                            md:min-h-0
                        `}
                    >
                        <div
                            className={`
                                flex-1
                                ${!item.hasAmountInput ? "md:hidden" : ""}
                            `}
                        >
                            {item.hasAmountInput &&
                            checkExpenseItemSelected(item) ? (
                                <InputElement
                                    name={`${item.key}-amount`}
                                    type="number"
                                    label={item.amountInputLabel || " "}
                                    onChange={event => {
                                        onInputChange(item, "amount", event)
                                    }}
                                    defaultValue={item.defaultAmountValue}
                                    isLabelStatic={true}
                                />
                            ) : null}
                        </div>

                        {item.hasMoneyInput &&
                        checkExpenseItemSelected(item) ? (
                            <div
                                className={`
                                    flex-1 ml-25px
                                    ${!item.hasAmountInput ? "md:ml-0px" : ""}
                                `}
                            >
                                <InputElement
                                    name={`${item.key}-money`}
                                    type="number"
                                    inputPrefix="$"
                                    inputSuffix={
                                        item.isYearlyMoney
                                            ? "a year"
                                            : "a month"
                                    }
                                    label={item.moneyInputLabel || " "}
                                    onChange={event => {
                                        onInputChange(item, "money", event)
                                    }}
                                    defaultValue={
                                        checkExpenseItemSelected(item)?.money ||
                                        item.defaultMoneyValue
                                    }
                                    isLabelStatic={true}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            ))}
        </div>
    )
}
